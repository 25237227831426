import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResProp {
    data: {
        "id": number,
        "description": string,
        "token": string,
        "payerId": string,
        "payerEmail": string,
        "refreshToken": string,
        "expiresIn": string,
        "userId": string,
        "activeId": boolean,
        "rateLimitReachedAt": string,
        "tokenRefreshed": boolean,
        "rateLimitReached": boolean
    },
    status:number
}

export function* postConnectPayPalRequest(action: any) {
    try {
        const res: ResProp = yield Api.postConnectPayPal(action.payload);
        yield put(actions.postConnectPayPalSuccess(res.data));
    } catch (e) {
        yield put(actions.postConnectPayPalError());
    }
}

export function* getConnectedPayPalListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getConnectedPayPalList(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.getConnectedPayPalListError());
        } else{
            yield put(actions.getConnectedPayPalListSuccess(res.data));

        }
           } catch (e) {
        yield put(actions.getConnectedPayPalListError());
    }
}

export function* deletePayPalRequest(action: any) {
    try {
        const res: ResProp = yield Api.deletePayPal(action.payload);
        yield put(actions.deletePayPalSuccess(res.data));
    } catch (e) {
        yield put(actions.deletePayPalError());
    }
}
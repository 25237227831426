import { svgIcons } from "../../../elements";

export const mockOtherIntegrationData = {
    isLoading: false,
    isSuccess: true,
    isError: false,
    data: {
        count: 0,
        integratedOther : [
            {
                id: 1,
                icon: svgIcons.Intercom,
                store: "Intercom",
                storeEmail: "sofia@shipwoise.com"
            },
            {
                id: 2,
                icon: svgIcons.Zendesk,
                store: "Zendesk",
                storeEmail: "james@shipwoise.com"
            },
            
        ]
    }
}
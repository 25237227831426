import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from "./style.module.scss";
import {
  Profile,
  NotificationsAndAlerts,
  Notifications,
} from "../../components";
import { BreadcumbItem } from "components/ui";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className={styles.settingsPanelContainer}
      >
        {value === index && (
          <Box className={styles.settings}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function handleBreadcrumb(page: string) {
    navigate(page);
  }

  return (
    <div className={styles.disputesWrapper}>
      <Notifications.Activate />
      <div className={styles.nav}>
        <h3>Settings</h3>
        <div className={styles.navPath}>
          <BreadcumbItem
            onClick={() => handleBreadcrumb("/home")}
            title={`Home`}
            className={styles.cursor}
          />
          <BreadcumbItem title={`Settings`} className={styles.cursor} />
          <BreadcumbItem
            title={value === 0 ? "My Details" : "Notifications"}
            className={styles.cursor}
            off
          />
        </div>
      </div>

      <div className={styles.settingsWrapper}>
        <Box sx={{ width: "100%" }}>
          <Box className={styles.settingsTabsWrapper}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className={styles.settingsTabs}
                label="My Details"
                {...a11yProps(0)}
              />
              {/* <Tab className={styles.settingsTabs} label="Linked Accounts" {...a11yProps(1)} /> */}
              {/* <Tab
                className={styles.settingsTabs}
                label="Notifications"
                {...a11yProps(1)}
              /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Profile />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <NotificationsAndAlerts />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default Settings;

import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputeOfferDetails {
    /**
     * Function to invoke to get dispute details
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_OFFER_DETAILS(payload));

        return res;
    }

    async invokeDisputeOrderSummary(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_ORDER_SUMMARY(payload));

        return res;
    }

    async invokeByFraudAnalysisDetails(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_FRAUD_ANALYSIS_DETAILS(payload));

        return res;
    }

    async invokeByDisputeCustomerInformation(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_CUSTOMER(payload));

        return res;
    }

    async invokeByManageDisputeFullfillment(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_FULFILLMENT(payload));

        return res;
    }

    async invokeByManageDisputeTrackingDetails(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_MANAGE_DISPUTE_TRACKING_DETAILS(payload));

        return res;
    }

    async invokeAddSupportingEvidence(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_ADD_SUPPORTING_EVIDENCE(payload));

        return res;
    }
}
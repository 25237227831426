import { FC } from 'react';

import { svgIcons } from '../../elements';

import styles from './style.module.scss';

interface Props {
  isConnected?: boolean;
  isTrial?: boolean; 
}

const ProgressLine: FC<Props> = ({ isConnected, isTrial }) => {
  const iconConnect = isConnected ? svgIcons.CheckThree : svgIcons.CircleTwo_1;

  return (
    <div className={styles.progressWrapper}>
      <div className={styles.progressChecks}>
        <img src={svgIcons.CheckThree} alt="install" />

        <p>App install</p>
      </div>
      <div className={styles.progressChecks}>
        <img src={iconConnect} alt="connect" />
        <p>Connect Account</p>
      </div>
      <div className={styles.progressChecks}>
        <img src={svgIcons.CircleTwo_2} alt="Start Now" />
        <p>Start Now</p>
      </div>
      <div className={styles.full} />
      {isTrial === undefined ? <div className={styles.half} /> : <div className={styles.halfToFull} />}
      <div className={styles.half} />
    </div>
  );
};

export default ProgressLine;

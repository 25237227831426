import { svgIcons } from "../../../elements";

export const mockEcommerceData = {
    isLoading: false,
    isSuccess: true,
    isError: false,
    data: {
        count: 3,
        integratedEcommerce : [
            {
                id: 1,
                icon: svgIcons.Lachiepor,
                store: "lachiepor.co",
                storeEmail: "info@hoiu.com"
            },
            {
                id: 2,
                icon: svgIcons.Majanogout,
                store: "manchojewels.com",
                storeEmail: "micheal@manchojewels.com"
            },
            {
                id: 3,
                icon: svgIcons.Leather,
                store: "leather.co",
                storeEmail: "email@another.com"
            },
        ]
    }
}
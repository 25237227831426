import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getItem } from '../../../utils/localStorage';
import styles from './style.module.scss';

interface userDataProp {
  "id": number,
  "externalId": string,
  "appId": string,
  "domain": string,
  "name": string,
  "email": string,
  "phone": string
  "shopType": string,
  "bigCommerceStoreHash": string,
  "bigCommerceStoreInternalHash": string,
  "accessToken": string,
  "refreshToken": string,
  "scopes": string,
  "onboarded": boolean,
  "riskAccepted": boolean,
  "chargeActive": boolean
}

const Profile = () => {
  const [userData, setUserData] = useState<userDataProp>()
  const userDataObj: string | any = getItem("addShopifyShop");
  const addShopifyShopData = JSON.parse(userDataObj);

  const shopsData = useSelector((state: any) => state?.shop?.shopsData);

  useEffect(() => {
    if (shopsData?.isSuccess) {
      setUserData(shopsData?.data)
    }
  }, [shopsData])

  return (
    <div className={styles.profileWrapper}>
      <h3>Basic Details</h3>
      <div className={styles.details}>
        <div className={styles.detail}>
          <div>
            <label htmlFor="Fname">Full Name</label>
            <p>Please use your legal name</p>
          </div>
          <input type="text" name="Fname" placeholder="" value={addShopifyShopData?.name
            ? addShopifyShopData?.name
            : userData?.name
          } />
        </div>
        <div className={styles.detail}>
          <div>
            <label htmlFor="Pnumber">Phone Number</label>
            <p>For SMS updates & verification</p>
          </div>
          <input type="text" name="Pnumber" placeholder="" value={addShopifyShopData?.phone
            ? addShopifyShopData?.phone
            : userData?.phone
          } />
        </div>
        <div className={styles.detail}>
          <div>
            <label htmlFor="dob">Email</label>
            <p>Your email </p>
          </div>
          <input
            type="email"
            name="email"
            placeholder=""
            value={addShopifyShopData?.email
              ? addShopifyShopData?.email
              : userData?.email
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from '@mui/material';
import { svgIcons } from '../../elements';
import styles from './style.module.scss';

// todo-fix-icons
const Success: FC = () => {
  const navigate = useNavigate();
  const { data } = useSelector((state: any) => state?.connectPayPalStore?.connectPaypal);

  return (
    <div className={styles.connectWrapper}>
      <div className={styles.connectLeft}>
        <div className={styles.lineConnect}>
          <img src={svgIcons.ChargePayLogo} alt="log" />
          <img className={styles.lineCheck} src={svgIcons.Check_All} alt="check" />
          <img src={svgIcons.Lachiepor} alt="shopify" />
          <img className={styles.lineCheck} src={svgIcons.Check_All} alt="shuffle" />
          <img src={svgIcons.PayPalFill} alt="paypal" />
          <div className={styles.line} />
        </div>
        <h3>Connection Successful</h3>

        <div className={styles.infoWrapper}>
          <div className={styles.secure}>
            <img src={svgIcons.ShieldCheck} alt="shieldCheck" />
            <p>100% secure connection</p>
          </div>
        </div>

      </div>
      <div className={styles.connectRight}>
        <p>You can connect an PayPal account to get started:</p>
        <div className={styles.paypalConnectedBox}>
          <div className={styles.paypalEmail}>
            <img src={svgIcons.PayPalFill} alt="tp" />
            <Tooltip title={data?.payerEmail} arrow placement="top-end">
              <p>{data?.payerEmail}</p>
            </Tooltip>
            <Button>
              <img className={styles.delete} src={svgIcons.Trash_2} alt='delete' />
            </Button>
          </div>
          <Button className={styles.continue} onClick={() => navigate('/home')}>
            Go to Dashboard
          </Button>
        </div>
        <div className={styles.paypalBig}>
          <img src={svgIcons.PaypalBig} alt='paypal' />
        </div>
      </div>
    </div>
  );
};

export default Success;

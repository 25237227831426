import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDisputeEvidenceDetailsRequest } from "../../../../redux/actions";

import styles from './style.module.scss';

const Evidence = () => {
    const dispatch = useDispatch();
    const { disputeId } = useParams();

    // const disputeEvidenceData = useSelector((state: any) => state?.disputeDetailsStore?.disputeEvidenceDetails);

    useEffect(() => {
        if (disputeId) {
            const payload = disputeId;
            dispatch(getDisputeEvidenceDetailsRequest(payload));
        }
    }, [disputeId, dispatch]);

    return (
        <div className={styles.evidenceWrapper}>
            <p>
                No Evidence avaiable
            </p>
        </div>
    )
}

export default Evidence;
import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResProp {
  data: {
    notificationId: string,
    description: string,
    status: string | null,
    date: string | null,
    typeId: string | null,
    type: string
  },
  status:number
}

export function* getNotificationsListRequest(action: any) {
  try {
    const res: ResProp = yield Api.getNotificationsList();
    if(res.status === (500 || 401)){
      yield put(actions.getNotificationsListError());
    }else{
      yield put(actions.getNotificationsListSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getNotificationsListError());
  }
}

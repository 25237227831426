import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResProp {
  data: [{
    "merchantId": string,
    "description": string,
    "payerId": string,
    "payerEmail": string
  }],
  status:number
}

export function* getListPspListRequest() {
  try {
    const res: ResProp = yield Api.getListPspList();
    if(res.status === (500 || 401)){
      yield put(actions.getListPspListError());
    }else{
      yield put(actions.getListPspListSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getListPspListError());
  }
}

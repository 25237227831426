import { useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";
import {
  Notifications,
  OtherIntegrations,
  PaymentAccountIntegration,
} from "../../components";

import styles from "./style.module.scss";
import { BreadcumbItem } from "components/ui";

const Integrations = () => {
  const navigate = useNavigate();
  const [clickedTab, setClickedTab] = useState<string>("paymentAccount");

  function handleBreadcrumb(page: string) {
    navigate(page);
  }

  return (
    <div className={styles.disputesWrapper}>
      <Notifications.Activate />
      <div className={styles.nav}>
        <h3>Integrations</h3>
        <div className={styles.navPath}>
          <BreadcumbItem
            title={"Home"}
            onClick={() => handleBreadcrumb("/home")}
            className={styles.cursor}
          />
          <BreadcumbItem title={"Integrations"} className={styles.cursor} />
          <BreadcumbItem
            title={
              clickedTab === "paymentAccount"
                ? "Payment Account (PSP)"
                : "Other Integrations"
            }
            className={styles.cursor}
            off
          />
        </div>
      </div>

      <Box className={styles.boxWrapper} sx={{ width: "100%" }}>
        <Box className={styles.tabsWrapper}>
          <div className={styles.integrationTabs}>
            <Button
              className={
                clickedTab === "paymentAccount"
                  ? cn(styles.integrationTab, styles.activeTab)
                  : styles.integrationTab
              }
              onClick={() => setClickedTab("paymentAccount")}
            >
              <p>Payment Account (PSP)</p>
            </Button>
            <Button
              className={
                clickedTab === "otherIntegrations"
                  ? cn(styles.integrationTab, styles.activeTab)
                  : styles.integrationTab
              }
              onClick={() => setClickedTab("otherIntegrations")}
            >
              <p>Other Integrations</p>
            </Button>
          </div>
        </Box>
        {clickedTab === "paymentAccount" ? (
          <div className={styles.customWrapper}>
            <PaymentAccountIntegration />
          </div>
        ) : (
          <div className={styles.customWrapper}>
            <OtherIntegrations />
          </div>
        )}
      </Box>
    </div>
  );
};

export default Integrations;

import { useCallback, useState } from 'react';

import { Connect, ProgressLine, Success } from '../../components';

import styles from './style.module.scss';

// todo-fix-icons

const Paypal = () => {
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const handleConnect = useCallback(() => {
    setIsConnected(true);
  }, []);

  return (
    <div className={styles.paypalWrapper}>
      <ProgressLine isConnected={isConnected} />
      <div className={styles.paypalBottom}>
        {!isConnected ? <Connect handleConnect={handleConnect} /> : <Success />}
      </div>
    </div>
  );
};

export default Paypal;

import { combineReducers } from "redux";
import authStore from './auth/reducer'
import connectPayPalStore from './connectPayPal/reducer';
import dashboard from './dashboard/reducer';
import disputesList from "./disputesList/reducer";
import disputeDetailsStore from "./disputeDetails/reducer";
import shop from "./shop/reducer";
import shopifyController from "./shopifyController/reducer";
import notifications from "./notifications/reducer";
import brainTreeStore from "./brainTree/reducer";
import klarnaStore from "./klarna/reducer";
import listPSP from "./listPSP/reducer";
import stripe from "./stripe/reducer";

const appReducer = combineReducers({
  authStore,
  connectPayPalStore,
  dashboard,
  disputesList,
  disputeDetailsStore,
  shop,
  shopifyController,
  notifications,
  brainTreeStore,
  klarnaStore,
  listPSP,
  stripe,
});

// HERE, CLEAERING THE WHOLE STATE AS FRESH WHILE LOGGING OUT THE USER.
const makeRootReducer = (state: any, action: any) => {

  return appReducer(state, action);
};

export default makeRootReducer;
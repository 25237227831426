import { Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { svgIcons } from "../../elements";
import { postShopifyCreateSubscriptionRequest, postShopsAddRiskRequest } from "../../redux/actions";
import Loading from "../loading/loading";
import Modal from "../modal/Modal";

import styles from "./style.module.scss";

interface risksProps {
    isOpen: boolean;
    onClose: () => void;
}

const riskDescriptions = [
    {
        id: 0,
        description: "Activate your account for FREE to automate your disputes with ChargePay."
    },
    {
        id: 1,
        description: "You will not be charged today and unless ChargePay wins your disputes."
    }
]

const DisputesRisk = ({ isOpen, onClose }: risksProps) => {
    const dispatch = useDispatch()
    const { isLoading, isSuccess, isError, data } = useSelector((state: any) => state?.shopifyController?.shopifyCreateSubscription)
  
    useEffect(() => {
      if (isSuccess && data?.confirmationUrl) {
        window.location.replace(data?.confirmationUrl);
      } else if (isError) {
        toast.error('Oops! Automation fail. Please try again', {
          theme: 'colored'
        });
      }
    }, [isSuccess, isError, data])
  
    const handleAutomateDispute = () => {
      dispatch(postShopifyCreateSubscriptionRequest())
    }

    const handleRisk = () => {
        dispatch(postShopsAddRiskRequest())
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
            <div className={styles.riskModal}>
                <div className={styles.riskWrapper}>
                    <div className={styles.riskHeader}>
                        <img src={svgIcons.Warning_Red} alt="" />
                        <h4>Your Disputes are at risk!</h4>
                    </div>

                    <div className={styles.riskBody}>
                        {riskDescriptions?.map((risk) => {
                            return (
                                <div key={risk?.id} className={styles.riskExpect}>
                                    <p>{risk?.description}</p>
                                </div>
                            )
                        })}
                    </div>

                    <div className={styles.riskFooter}>
                        <Button variant="contained" onClick={handleAutomateDispute}>
                            {isLoading ? <Loading /> : "Automate my disputes"} 
                        </Button>
                        <Button variant="text" className={styles.riskUnderstand} onClick={() => handleRisk()}>
                            I understand the risk
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DisputesRisk;
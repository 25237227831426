import { FC } from "react";
import styles from "./style.module.scss";

const Option:FC = () => {
  return (
    <div className={styles.optionWrapper}>
      <div className={styles.purchaseCard}>
        Pay by card
      </div>
    </div>
  )
}

export default Option
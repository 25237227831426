import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    notificationsList: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
}

//notifications-list
const getNotificationsListRequest = (state: any, action: any) => {
    return update(state, {
        notificationsList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getNotificationsListSuccess = (state: any, action: any) => {
    return update(state, {
        notificationsList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getNotificationsListError = (state: any, action: any) => {
    return update(state, {
        notificationsList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.GET_NOTIFICATIONS_LIST_REQUEST]: getNotificationsListRequest,
        [constants.GET_NOTIFICATIONS_LIST_SUCCESS]: getNotificationsListSuccess,
        [constants.GET_NOTIFICATIONS_LIST_ERROR]: getNotificationsListError,
    },
    initialState
);
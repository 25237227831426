import { Button } from '@mui/material';
import { Switch } from '../../';
import { svgIcons } from '../../../elements';

import styles from './style.module.scss';

const ecommerceData = [
  {
    id: 1,
    checked: false,
    shopName: "Lachiepor.co",
    icon: svgIcons.Lachiepor,
    shopType: "Shopify"
  },
  {
    id: 2,
    checked: true,
    shopName: "mahomon.com",
    icon: svgIcons.Lachiepor,
    shopType: "Shopify"
  }
]

const payPalAccountData = [
  {
    id: 1,
    checked: false,
    paypalAccount: "Paypal account-5412",
    paypalId: "ADA54D65A4D56A4",
    email: "zeeshan@shipowise.com"
  },
  {
    id: 2,
    checked: true,
    paypalAccount: "Paypal account-8452",
    paypalId: "D1D13AD21A3D",
    email: "jui@gmail.com"
  },
  {
    id: 3,
    checked: true,
    paypalAccount: "Paypal account-5412",
    paypalId: "EG1123E12R3W5E",
    email: "sam@gamil.com"
  }
]

const LinkedAccounts = () => {
  return (
    <div className={styles.linkedAccWrapper}>
      <div className={styles.heading}>
        <div>
          <h3>Linked Accounts</h3>
          <p className={styles.intro}>
            Your integrations and connected accounts
          </p>
        </div>
        <button>
          <img src={svgIcons.Add} alt="" /> Add Account
        </button>
      </div>

      <div className={styles.ecommerce}>
        <div className={styles.title}>
          <img src={svgIcons.Ecommerce} alt="Ecommerce" /> Ecommerce
        </div>
        <p className={styles.desc}>Last synced 20 minutes ago</p>
        <div className={styles.details}>
          {ecommerceData?.map((ecommerce) => {
            return (
              <div key={ecommerce?.id} className={styles.detail}>
                <div className={styles.linkeEcommerce}>
                  <div className={styles.emailWrapper}>
                    <Switch checked={ecommerce?.checked} onChange={() => { }} />
                    <div className={styles.emailInfo}>
                      <p className={styles.email}>{ecommerce?.shopName}</p>
                      <div className={styles.ecommerceIcon}>
                        <img src={ecommerce?.icon} alt="" />
                        <p>{ecommerce?.shopType}</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.actions}>
                    <Button variant="outlined">
                      <img src={svgIcons.Delete} alt="delete" />
                      <p>Remove</p>
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.info}>
        <div className={styles.title}>
          <img src={svgIcons.Paypal} alt="Paypal" /> PayPal Accounts
        </div>
        <p className={styles.desc}>Last synced 10 minutes ago</p>
        <div className={styles.details}>
          {payPalAccountData?.map((paypal) => {
            return (
              <div className={styles.detail}>
                <div className={styles.paypalWrapper}>
                  <div className={styles.emailWrapper}>
                    <Switch checked={paypal?.checked} onChange={() => { }} />
                    <div className={styles.emailInfo}>
                      <p className={styles.email}>{paypal?.paypalAccount}</p>
                      <p className={styles.paypalId}>{paypal?.paypalId} | {paypal?.email}</p>
                    </div>
                  </div>
                  <div className={styles.actions}>
                    <Button variant="outlined">
                      <img src={svgIcons.Delete} alt="delete" />
                      <p>Remove</p>
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default LinkedAccounts;

import { useSelector } from 'react-redux';
import moment from 'moment';
import { getColorByStatus } from "../../../../utils/disputeUtils";
import styles from './style.module.scss';

const DisputeOpenedByBuyer = ({ disputeState }: any) => {
    const { data } = useSelector((state: any) => state?.disputeDetailsStore?.disputeDetails);
    return (
        <div className={styles.disputeOpenedWrapper}>
            <h4>Dispute opened by buyer</h4>
            <div className={styles.disputeOpenedData}>
                <div>
                    <h6>Status</h6>
                    <p className={
                        (disputeState !== "RESOLVED" &&
                            disputeState !== "UNDER_PAYPAL_REVIEW" &&
                            disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&

                            disputeState !== "response template" &&
                            disputeState !== "dispute lost")
                            ? styles.disputeAwaitingStatus
                            : styles.disputeSubmitStatus
                    } style={
                        (disputeState !== "RESOLVED" &&
                            disputeState !== "UNDER_PAYPAL_REVIEW" &&
                            disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&

                            disputeState !== "response template" &&
                            disputeState !== "dispute lost")
                            ? {
                                backgroundColor: getColorByStatus(data?.status)?.backgroundColor,
                                color: getColorByStatus(data?.status)?.textColor
                            } : { display: "block" }}>
                        {
                            (
                                disputeState !== "RESOLVED" &&
                                disputeState !== "UNDER_PAYPAL_REVIEW" &&
                                disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&

                                disputeState !== "response template" &&
                                disputeState !== "dispute lost"
                            )
                                && data?.status?.split('_').join(' ')
                        }
                    </p>
                </div>

                {
                    disputeState !== "RESOLVED" &&
                    disputeState !== "UNDER_PAYPAL_REVIEW" &&
                    disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&

                    disputeState !== "response template" &&
                    disputeState !== "dispute lost" &&
                    <div>
                        <h6>Due date for response</h6>
                        <p className={styles.disputeDueDate}>
                            {data?.sellerResponseDueDate &&
                                moment(data?.sellerResponseDueDate).format("MMMM Do YYYY")
                            }
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}

export default DisputeOpenedByBuyer;
import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputesList {
    /**
     * Function to invoke to get disputes list
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTES_LIST(payload));

        return res;
    }
}
import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResProp {
    data: {
        "id": number,
        "merchantId": string,
        "privateKey": string,
        "publicKey": string,
        "active": boolean
    },
    status:number,
}

export function* postBrainTreeSettingsRequest(action: any) {
    try {
        const res: ResProp = yield Api.postBrainTreeSettings(action.payload);
        yield put(actions.postBrainTreeSettingsSuccess(res.data));
    } catch (e) {
        yield put(actions.postBrainTreeSettingsError());
    }
}

export function* getBrainTreeListRequest(action: any) {
    try {
        const res: ResProp = yield Api.getBrainTreeList(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.getBrainTreeListError());
        }else{
            yield put(actions.getBrainTreeListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getBrainTreeListError());
    }
}
import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetLinkedPayPal {
  /**
   * Function to invoke to get linked paypal
   */
  async invoke() {

    const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_LINKED_PAYPAL)
    
    return res;
  }

}
import axios from "axios";
import { configs } from "configs";
import Swal from "sweetalert2";
// import { postAddSupprotingEvidenceRequest } from "redux/actions";
import { getItem } from 'utils/localStorage';

const useSupportingEvidence = () => {
  const handleSupportingEvidence = async (data: any, reset:any, setUploadFile:any) => {
    const formData: any = new FormData();
    formData.append("file", data.file);
    for (var pair of formData.entries()) {
    }
    const token = getItem("accessToken");
    const API_UPLOAD_CONFIG = {
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    for (var pair of formData.entries()) {
    }
    try {
      ///evidence/provide-evidence?disputeId=${payload?.disputeId}&text=${payload?.text}&comments=${payload?.comments}
      const response = await axios.post(
        `${configs.BASE_URL}/evidence/provide-evidence?disputeId=${data?.disputeId}&text=${data?.category}&comments=${data?.comments}`,
        formData,
        API_UPLOAD_CONFIG
      );
      if (response.data) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.data,
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        setUploadFile([])
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Something is wrong with submission file",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return { handleSupportingEvidence };
};

export default useSupportingEvidence;

import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class PostConnectPayPal {
  /**
   * Function to invoke to paypal connect
   */
  async invoke(payload: any) {
    const res = await ApiService.getInstance().post(apiConstants.CONNECT_PAYPAL(payload.code));

    if (res.data.id) {
      return res;
    } else {
      let newError = new Error()
      newError = { ...newError }
      throw newError
    }
  }

  async invokeConnectedPayPalList(payload: any) {
    
    const res = await ApiService.getInstance().get(apiConstants.GET_CONNECTED_PAYPAL_LIST(payload));

    return res;
  }

  async invokeDeletePayPal(payload: number) {
    
    const res = await ApiService.getInstance().delete(apiConstants.DELETE_PAYPAL(payload));

    return res;
  }
}
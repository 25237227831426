import { Login } from "../../components";
import { svgIcons } from "../../elements";
import styles from "./style.module.scss";

const LoginPage = () => {
  return (
    <div className={styles.loginPage}>
      <div className={styles.login}>
        <div className={styles.logo}>
          <img
            className={styles.logoIcon}
            src={svgIcons.ChargePayLogo}
            alt="tp"
          />
          <img
            className={styles.logoName}
            src={svgIcons.ChargePayName}
            alt="ChargePay"
          />
        </div>
        <Login />
        <div className={styles.footer}> by &copy; ChargePay&#8482; 2023</div>
      </div>
      <div className={styles.loginImage}>
        <div className={styles.gridWrapper}>
          <div className={styles.loginGrids}>
            <div className={styles.gridTopFirst} />
            <div className={styles.gridTopMiddle} />
            <div className={styles.gridTopLast} />
          </div>
          <div className={styles.loginGrids}>
            <div className={styles.gridSecondFirst} />
            <div className={styles.gridSecondMiddle} />
            <div className={styles.gridSecondLast} />
          </div>
          <div className={styles.loginGridsTwo}>
            <div className={styles.gridThirdFirst} />
            <div className={styles.gridThirdMiddle} />
            <div className={styles.gridThirdLast} />
          </div>
          <div className={styles.loginGrids}>
            <div className={styles.gridBottomFirst} />
            <div className={styles.gridBottomMiddle} />
            <div className={styles.gridBottomLast} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResProp {
  data: {
    userId: string,
    planDisplayName: string,
    planName: string
  }
}

export function* postCreateUserDataRequest(action: any) {
  try {
    const res: ResProp = yield Api.postCreateUserData(action.payload);
    yield put(actions.postCreateUserSuccess(res.data));
  } catch (e) {
    yield put(actions.postCreateUserError());
  }
}

import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class DeleteLinkedShops {
  /**
   * Function to invoke to delete linked ecommerce shops
   */
  async invoke(payload: number) {

    const res = await ApiService.getInstance().delete(apiConstants.DASHBOARD_DELETE_LINKED_SHOPS(payload));
    
    return res;
  }
}
import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";


const initialState = {
    addShopifyShop: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopsNotifications: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopsSaveNotifications: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopsAddOnboarded: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopsAddRisk: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopsData: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },
}

//add-shopify-shop
const postAddShopifyShopRequest = (state: any, action: any) => {
    return update(state, {
        addShopifyShop: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postAddShopifyShopSuccess = (state: any, action: any) => {
    return update(state, {
        addShopifyShop: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postAddShopifyShopError = (state: any, action: any) => {
    return update(state, {
        addShopifyShop: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shops-notifications
const getShopsNotificationsRequest = (state: any, action: any) => {
    return update(state, {
        shopsNotifications: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getShopsNotificationsSuccess = (state: any, action: any) => {
    return update(state, {
        shopsNotifications: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getShopsNotificationsError = (state: any, action: any) => {
    return update(state, {
        shopsNotifications: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shops-save-notifications
const postShopsSaveNotificationsRequest = (state: any, action: any) => {
    return update(state, {
        shopsSaveNotifications: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postShopsSaveNotificationsSuccess = (state: any, action: any) => {
    return update(state, {
        shopsSaveNotifications: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postShopsSaveNotificationsError = (state: any, action: any) => {
    return update(state, {
        shopsSaveNotifications: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shops-add-onboarded
const postShopsAddOnboardedRequest = (state: any, action: any) => {
    return update(state, {
        shopsAddOnboarded: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postShopsAddOnboardedSuccess = (state: any, action: any) => {
    return update(state, {
        shopsAddOnboarded: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postShopsAddOnboardedError = (state: any, action: any) => {
    return update(state, {
        shopsAddOnboarded: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shops-add-risk
const postShopsAddRiskRequest = (state: any, action: any) => {
    return update(state, {
        shopsAddRisk: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postShopsAddRiskSuccess = (state: any, action: any) => {
    return update(state, {
        shopsAddRisk: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postShopsAddRiskError = (state: any, action: any) => {
    return update(state, {
        shopsAddRisk: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shops-data
const getShopsDataRequest = (state: any, action: any) => {
    return update(state, {
        shopsData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getShopsDataSuccess = (state: any, action: any) => {
    return update(state, {
        shopsData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getShopsDataError = (state: any, action: any) => {
    return update(state, {
        shopsData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_ADD_SHOPIFY_SHOP_REQUEST]: postAddShopifyShopRequest,
        [constants.POST_ADD_SHOPIFY_SHOP_SUCCESS]: postAddShopifyShopSuccess,
        [constants.POST_ADD_SHOPIFY_SHOP_ERROR]: postAddShopifyShopError,

        [constants.GET_SHOPS_NOTIFICATIONS_REQUEST]: getShopsNotificationsRequest,
        [constants.GET_SHOPS_NOTIFICATIONS_SUCCESS]: getShopsNotificationsSuccess,
        [constants.GET_SHOPS_NOTIFICATIONS_ERROR]: getShopsNotificationsError,

        [constants.POST_SHOPS_SAVE_NOTIFICATIONS_REQUEST]: postShopsSaveNotificationsRequest,
        [constants.POST_SHOPS_SAVE_NOTIFICATIONS_SUCCESS]: postShopsSaveNotificationsSuccess,
        [constants.POST_SHOPS_SAVE_NOTIFICATIONS_ERROR]: postShopsSaveNotificationsError,

        [constants.POST_SHOPS_ADD_ONBOARDED_REQUEST]: postShopsAddOnboardedRequest,
        [constants.POST_SHOPS_ADD_ONBOARDED_SUCCESS]: postShopsAddOnboardedSuccess,
        [constants.POST_SHOPS_ADD_ONBOARDED_ERROR]: postShopsAddOnboardedError,

        [constants.POST_SHOPS_ADD_RISK_REQUEST]: postShopsAddRiskRequest,
        [constants.POST_SHOPS_ADD_RISK_SUCCESS]: postShopsAddRiskSuccess,
        [constants.POST_SHOPS_ADD_RISK_ERROR]: postShopsAddRiskError,

        [constants.GET_SHOPS_DATA_REQUEST]: getShopsDataRequest,
        [constants.GET_SHOPS_DATA_SUCCESS]: getShopsDataSuccess,
        [constants.GET_SHOPS_DATA_ERROR]: getShopsDataError,
    },
    initialState
);
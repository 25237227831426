import { useState } from 'react';
import cn from 'classnames';
import { svgIcons } from '../../elements';

import styles from './style.module.scss';

interface SearchProps {
  onSearch: (query: string) => void;
  onType: (query: string) => void;
  type: string;
  placeholder: string;
  className?: string;
}

// todo-fix-icons

export default function Search(props: SearchProps) {
  const { onSearch, onType, type, placeholder, className } = props;
  const [query, setQuery] = useState('');

  return (
    <div className={styles.searchWrapper}>
      <input
        className={cn(styles.search, className)}
        type={type}
        placeholder={placeholder}
        value={query}
        onChange={({ target }) => {
          setQuery(target.value);
          onType(target.value);
        }}
        onKeyUp={({ key }) => key === 'Enter' && onSearch(query)}
      />
      <span className={styles.searchIcon}>
        <span className={styles.searchIcon}>
          <img src={svgIcons.Search} alt="search" /> 
        </span>
      </span>
    </div>
  );
}

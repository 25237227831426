import React from 'react';
import { useSelector } from 'react-redux';
import LinearDeterminate from '../disputeSummary/linearProgress';
import { svgIcons } from '../../../elements';

import styles from './style.module.scss';

const DisputeResolver = () => {
    const { data } = useSelector((state: any) => state?.disputeDetailsStore?.disputeDetails);

    return (
        <div className={styles.disputeResolverWrapper}>
            <div>
                <div className={styles.disputeResolverAI}>
                    <img src={svgIcons.Star_Blue} alt='star' />
                    <div>
                        <h6>AI Dispute Resolver</h6>
                        <p>ChargePay will help and guide you with its artifical inteligence responses and suggestions for you to win this dipute.</p>
                    </div>
                </div>
                <div className={styles.disputeResolverAIBody}>
                    <div>
                        <img src={svgIcons?.Process_Rafiki} alt='process_dispute' />
                    </div>
                    <div className={styles.disputeResolverDisc}>
                        <h3>We are working on this dispute!</h3>
                        {/*<div className={styles.reviewInfo}>

                            <div className={styles.score}>
                                <div className={styles.TrackiScore}>
                                    <p className={styles.TrackiScorePercentage}>
                                        0%
                                    </p>
                                </div>
                                <div className={styles.linearDeterminateWrapper}>
                                    <LinearDeterminate />
                                </div>
                            </div>

                            <div className={styles.timeEstimate}>
                                <h5>Time Estimate</h5>
                                <p>
                                    {data?.resolutionEstimatedTime && `${data?.resolutionEstimatedTime} Days`}
                                </p>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisputeResolver;
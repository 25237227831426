import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetRecoveredDisputes {
  /**
   * Function to invoke to get recovered disputes
   */
  async invoke() {
    
    const res = await ApiService.getInstance().get(apiConstants.DASHBOARD_RECOVERED_DISPUTES);

    return res;
  }

}

import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import LinearDeterminate from '../disputeSummary/linearProgress';
import { svgIcons } from "../../../elements";

import styles from './style.module.scss';
// import DisputeTrackiScore from '../disputeTrackiScore';

const DisputeUnderReview = ({ setDisputeState }: any) => {
    const { data } = useSelector((state: any) => state?.disputeDetailsStore?.disputeDetails);

    const handleViewSubmittedDispute = () => {
        setDisputeState('response template');
    }

    return (
        <div className={styles.disputeUnderReview}>
            <div className={styles.review}>
                <div className={styles.getCoffee}>
                    <img src={svgIcons.GettingCoffee} alt='GettingCoffee' />
                </div>
                <div className={styles.statusStatement}>
                    <h2>Sit Back and Relax, Your Dispute is Under Review</h2>
                    <p>Here are more details about your dispute</p>
                </div>
                {/*<div className={styles.reviewInfo}>

                    <div className={styles.score}>
                        <div className={styles.TrackiScore}>
                            <div className={styles.TrackiScoreTextWrapper}>
                                <div className={styles.TrackiScoreText}>
                                    <h5>ChargePayScore®</h5>
                                    <img src={svgIcons.Alert_Circle_Black} alt='Alert' />
                                </div>
                                <p>Odds For Winning This Dispute</p>
                            </div>
                            <p className={styles.TrackiScorePercentage}>
                                {data?.trackiScore ?? 0}%
                            </p>
                        </div>
                        <div className={styles.linearDeterminateWrapper}>
                            <LinearDeterminate />
                        </div>
                    </div>

                    <div className={styles.timeEstimate}>
                        <h5>Time Estimate</h5>
                        <p>
                            {data?.resolutionEstimatedTime && `${data?.resolutionEstimatedTime} Days`}
                        </p>
                    </div>
                </div>*/}

                {/*<div className={styles.viewSubmittedDispute}>
                    <Button
                        variant="contained"
                        onClick={handleViewSubmittedDispute}
                        data-testid='view-submitted-dispute-details'
                    >
                        View Submitted Dispute Details
                    </Button>
                </div>*/}
            </div>
        </div>
    )
}

export default DisputeUnderReview;
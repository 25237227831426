import _ from 'lodash';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDisputeTrackingDetailsRequest } from "redux/actions";

const useTrackingDetails = ({ disputeId }: any) => {
  const dispatch = useDispatch();

  const { isSuccess, data, isLoading, isError } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeTrackingDetails
  );
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeTrackingDetailsRequest(payload));
    }
  }, [disputeId]);
  interface Item {
    key: string;
    value: string;
  }

  function transformArrayToObject(array: Item[]): Record<string, string> {
    const transformedObject: Record<string, string> = {};
    if (array) {
      array.forEach(item => {
        const { key, value } = item;
        if (key.trim() !== '') { // Skip if key is empty
          transformedObject[key] = value;
        }
      });
      return transformedObject;
    }
    return transformedObject;
  }
  function formatObject(obj: any, keyMap: any) {
    let currentAttribute = obj?.attributes;
    const clonedData = _.cloneDeep(obj);
    const finalObject = currentAttribute && transformArrayToObject(currentAttribute)
    delete clonedData?.attributes;
    const checkObj = { ...finalObject, ...clonedData }
    const formattedObj: any = {};
    for (const key in checkObj) {
      if (checkObj.hasOwnProperty(key)) {
        const name = keyMap[key] || key;
        formattedObj[name] = checkObj[key];
      }
    }
    return formattedObj;
  }
  const keyMap: any = {
    trackingNumber: "Tracking Number",
    tracking_id: "Tracking Number",
    estimatedDelivery: "Estimated Delivery",
    from: "From",
    origin: "Origin",
    to: "To",
    service: "Service",
    uniqueId: "Unique Id",
    destination: "Destination",
    weight: "Weight",
    nextTrackingNumbers: "Next tracking numbers",
    foundIn: "Found in",
    feature: "Feature",
    postalProduct: "Postal Product",
    trackedWithCouriers: "Tracked with couriers",
    orderId: "Order #",
    orderHash: "Order #",
    distributor: "Distributor",
    channel: "Channel",
    reference: "Reference",
    partner: "Partner",
    lastMileTracking: "Last Mile tracking",
    daysInTransit: "Days in transit",
  };

  const formattedObj = formatObject(data, keyMap as any);
  function hasData(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        return true;
      }
    }
    return false;
  }
  const checkHasData = hasData(formattedObj);
  return { formattedObj, isSuccess, checkHasData, isLoading, isError };
};
export default useTrackingDetails;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from 'sweetalert2';
import Loading from "./Loading";
import { postAddShopifyShopRequest } from "../redux/actions";
import { clearItems, getItem } from "../utils/localStorage";

import styles from "./style.module.scss";

export const AuthenticateStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const shop = searchParams.get("shop");

  const { isSuccess, isError, data } = useSelector((state: any) => state?.shop?.addShopifyShop);

  const handleLogout = () => {
    clearItems();
    navigate("/login");
  }

  useEffect(() => {
    if (isSuccess && data) {
      const accessToken = getItem("accessToken");
      accessToken && (
        data?.onboarded ? navigate("/home") : navigate("/paypal")
      )
    } else if (isError) {
      Swal.fire({
        icon: 'error',
        title: 'Oops... Something went wrong!',
        text: 'Please logout and try again',
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          handleLogout()
        }
      })
    }
  }, [isSuccess, data, isError, handleLogout, navigate])

  useEffect(() => {
    if (code && shop) {
      const payload = {
        code,
        store: shop,
      }
      dispatch(postAddShopifyShopRequest(payload))
    }
  }, [shop, code, dispatch])

  return (
    <div className={styles.authenticate}>
      <Loading />
      <h3>Please wait...</h3>
    </div>
  );
};
import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    stripeLogin: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    stripeOnboardLogin: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },
    stripeUpdateProfile: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    }

}

//shopify-hmac
const postStripeLoginRequest = (state: any, action: any) => {
    return update(state, {
        stripeLogin: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postStripeLoginSuccess = (state: any, action: any) => {
    return update(state, {
        stripeLogin: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postStripeLoginError = (state: any, action: any) => {
    return update(state, {
        stripeLogin: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// post-stripe-onboard
const postStripeOnboardLoginRequest = (state: any, action: any) => {
    return update(state, {
        stripeOnboardLogin: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postStripeOnboardLoginSuccess = (state: any, action: any) => {
    return update(state, {
        stripeOnboardLogin: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postStripeOnboardLoginError = (state: any, action: any) => {
    return update(state, {
        stripeOnboardLogin: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
// post-stripe-update-business-profile
const postStripeUpdateBusinessProfileRequest = (state: any, action: any) => {
    return update(state, {
        stripeUpdateProfile: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postStripeUpdateBusinessProfileSuccess = (state: any, action: any) => {
    return update(state, {
        stripeUpdateProfile: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postStripeUpdateBusinessProfileError = (state: any, action: any) => {
    return update(state, {
        stripeUpdateProfile: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_STRIPE_LOGIN_REQUEST]: postStripeLoginRequest,
        [constants.POST_STRIPE_LOGIN_SUCCESS]: postStripeLoginSuccess,
        [constants.POST_STRIPE_LOGIN_ERROR]: postStripeLoginError,

        [constants.POST_STRIPE_ONBOARD_LOGIN_REQUEST]: postStripeOnboardLoginRequest,
        [constants.POST_STRIPE_ONBOARD_LOGIN_SUCCESS]: postStripeOnboardLoginSuccess,
        [constants.POST_STRIPE_ONBOARD_LOGIN_ERROR]: postStripeOnboardLoginError,

        [constants.POST_STRIPE_UPDATE_BUSINESS_PROFILE_REQUEST]: postStripeUpdateBusinessProfileRequest,
        [constants.POST_STRIPE_UPDATE_BUSINESS_PROFILE_SUCCESS]: postStripeUpdateBusinessProfileSuccess,
        [constants.POST_STRIPE_UPDATE_BUSINESS_PROFILE_ERROR]: postStripeUpdateBusinessProfileError,

    },
    initialState
);
import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    klarnaSettings: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    klarnaList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
}

//klarna--settings
const postKlarnaSettingsRequest = (state: any, action: any) => {
    return update(state, {
        klarnaSettings: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postKlarnaSettingsSuccess = (state: any, action: any) => {
    return update(state, {
        klarnaSettings: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const postKlarnaSettingsError = (state: any, action: any) => {
    return update(state, {
        klarnaSettings: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//klarna--list
const getKlarnaListRequest = (state: any, action: any) => {
    return update(state, {
        klarnaList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getKlarnaListSuccess = (state: any, action: any) => {
    return update(state, {
        klarnaList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getKlarnaListError = (state: any, action: any) => {
    return update(state, {
        klarnaList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_KLARNA_SETTINGS_REQUEST]: postKlarnaSettingsRequest,
        [constants.POST_KLARNA_SETTINGS_SUCCESS]: postKlarnaSettingsSuccess,
        [constants.POST_KLARNA_SETTINGS_ERROR]: postKlarnaSettingsError,

        [constants.GET_KLARNA_LIST_REQUEST]: getKlarnaListRequest,
        [constants.GET_KLARNA_LIST_SUCCESS]: getKlarnaListSuccess,
        [constants.GET_KLARNA_LIST_ERROR]: getKlarnaListError,

    },
    initialState
);

export const setItem = (dataName: any, data: any) => {
    localStorage.setItem(dataName, data)
}

export const getItem = (dataName: any) => {
    const value = localStorage.getItem(dataName);
    return (value);
}

export const removeItem = (dataName: any) => {
    const value = localStorage.removeItem(dataName);
    if (value !== null) {
        return value;
    }
}

export const clearItems = () => {
    localStorage.clear();
}
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../loading/loading";
import { postBrainTreeSettingsRequest, getBrainTreeListRequest } from "../../redux/actions";

import styles from "./style.module.scss";

interface brainTreeProp {
    handleNewIntegration: () => void;
}

const dataCollector = [
    {
        id: 0,
        title: "Merchant ID",
        placeholder: "Type Merchant ID...",
        registerName: "merchantId",
        error: "Please fill the valid Merchant ID"
    },
    {
        id: 1,
        title: "Public Key",
        placeholder: "Type Public Key...",
        registerName: "publicKey",
        error: "Please fill the valid Public Key"
    },
    {
        id: 2,
        title: "Private Key",
        placeholder: "Type Private Key...",
        registerName: "privateKey",
        error: "Please fill the valid Private Key"
    },
]

const BrainTreeIntegration = ({ handleNewIntegration }: brainTreeProp) => {
    const dispatch = useDispatch();
    const [isBrainTreeCheck, setIsBrainTreeCheck] = useState<boolean>(false);
    const { register, handleSubmit, formState: { errors } } = useForm({});

    const { isLoading, isSuccess, isError, data } = useSelector((state: any) => state?.brainTreeStore?.brainTreeSettings)


    const onSubmit = (data: any) => {
        setIsBrainTreeCheck(true);
        dispatch(postBrainTreeSettingsRequest(data));

        // const payload = {
        //     page: 1,
        //     pageLength: 7
        // }
        // dispatch(getBrainTreeListRequest(payload));         //to get BrainTree integrated list
    };

    useEffect(() => {
        if (isBrainTreeCheck) {
            if (isSuccess) {
                toast.success('BrainTree connect successfully', {
                    theme: 'colored'
                });
                setIsBrainTreeCheck(false);
                setTimeout(
                    () => handleNewIntegration(),
                    3000
                );
            }
            else if (isError) {
                toast.error('Oops! BrainTree connect fail. Please try again', {
                    theme: 'colored'
                });
                setIsBrainTreeCheck(false);
            }
        }
    }, [isSuccess, isError])

    return (
        <div className={styles.braintreeWrapper}>
            <ToastContainer />
            <h3 className={styles.braintreeHeading}>BrainTree</h3>
            <div className={styles.braintreeDescri}>
                <p>To connect your BrainTree account to ChargePay please follow the steps below:</p>
                <div className={styles.braintreeSteps}>
                    <ol>
                        <li>Login to your BrainTree dashboard</li>
                        <li>{'Go to Settings > API'}</li>
                        <li>Click on <span>“Generate new API Key”</span></li>
                        <li>Click on <span>“View”</span> on the top Private Key available</li>
                        <li>Insert the credentials according to the relevant fields below and click <span>“Connect”.</span></li>
                    </ol>
                </div>
                {
                    isLoading ? <Loading /> : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={styles.form}>
                                {dataCollector?.map((data) => {
                                    return (
                                        <div key={data?.id} className={styles.returnShippingAddress}>
                                            <label>{data?.title}</label>
                                            <input placeholder={data?.placeholder}
                                                {...register(data?.registerName, {
                                                    validate: (value) => value.length > 0
                                                })}
                                            />
                                            {errors[data?.registerName] && <p className={styles.error}>{data?.error}</p>}
                                        </div>
                                    )
                                })}
                            </div>
                            <Button variant="contained" type="submit">Connect</Button>
                        </form>
                    )
                }
            </div>
        </div>
    )
}

export default BrainTreeIntegration;
import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import {configs} from '../../../configs/index';


export class PostKlarnaSettings {
  /**
   * Function to invoke to BrainTree
   */
  async invoke(payload: any) {

    const res = await ApiService.getInstance().post(apiConstants.POST_KLARNA_SETTINGS, payload, { baseURL: configs.BASE_URL as string })

    return res;
  }

  async invokeKlarnaList(payload: any) {

    const res = await ApiService.getInstance().get(apiConstants.GET_KLARNA_LIST(payload))

    return res;
  }

}
import { svgIcons } from '../../../elements';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './style.module.scss';

interface Props {
    headingData: {
        title: string,
        description?: string,
        viewAll?: boolean,
        link?: string
    }
}
const RecentOrderHeader: FC<Props> = ({ headingData }) => {
    const navigate = useNavigate();
    
    const handleViewAll = () =>{
        navigate(`/${headingData.link}`);
    }
    return (
        <div className={styles.recentOrderHeader}>

            <div className={styles.recentOrderHeader_sub}>
                <p className={styles.recentOrderHeading}>
                    {headingData.title}
                </p>
                {
                    headingData.viewAll && (
                        <div className={styles.recentOrderHeader_Btn}>
                            <button className={styles.btn} onClick={handleViewAll}>
                                <img src={svgIcons.Eye} alt="badgeBase" />
                                <p>View All</p>
                            </button>
                        </div>
                    )
                }
            </div>

            <div className={styles.recentOrderHeader_description}>
                <p>{headingData.description}</p>
            </div>
        </div>
    )
}
export default RecentOrderHeader;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { svgIcons } from "elements";
import {
  AddAccount,
  NoDataFound,
  Loading,
  BrainTreeIntegration,
  Pagination,
} from "components";
import {
  deletePayPalRequest,
  getListPspListRequest,
  postConnectPayPalRequest,
} from "redux/actions";

import styles from "./style.module.scss";
import KlarnaIntegration from "components/klarna";

const PaymentAccountIntegration = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isAddAccountOpen, setIsAddAccountOpen] = useState<boolean>(false);
  const [isBrainTreeForm, setBrainTreeForm] = useState<boolean>(false);
  const [isKlarnaForm, setisKlarnaForm] = useState<boolean>(false);
  const tableList = 7;

  const code = new URLSearchParams(window.location.search).get("code");

  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.listPSP?.listPspList
  );
  const connectPaypal = useSelector(
    (state: any) => state?.connectPayPalStore?.connectPaypal
  );
  const deletePayPal = useSelector(
    (state: any) => state?.connectPayPalStore?.deletePayPal
  );

  const onAddAccountClick = () => {
    setIsAddAccountOpen(!isAddAccountOpen);
    setBrainTreeForm(false);
    setisKlarnaForm(false);
  };
  const handlePaypalRemove = (id: number) => {
    dispatch(deletePayPalRequest(id));
  };

  const handleNewIntegration = () => {
    setBrainTreeForm(false);
    setIsAddAccountOpen(false);
    setisKlarnaForm(false);
    dispatch(getListPspListRequest());
  };

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    if (code) {
      const payload = {
        code,
      };
      dispatch(postConnectPayPalRequest(payload));
    }
  }, [code, dispatch]);

  useEffect(() => {
    // const payload = {
    //     page: page,
    //     pageLength: tableList
    // }
    // dispatch(getConnectedPayPalListRequest(payload))
    dispatch(getListPspListRequest());
  }, [
    connectPaypal?.data,
    deletePayPal?.isSuccess,
    deletePayPal?.data,
    dispatch,
  ]);

  return (
    <div className={styles.integrationMainWrapper}>
      <div className={styles.integrationMain}>
        <div className={styles.integrationHeader}>
          <h3>Payment Account (PSP)</h3>
          <Button onClick={onAddAccountClick}>
            {isAddAccountOpen ? (
              <p>Cancel</p>
            ) : (
              <>
                <img src={svgIcons.Plus_Circle} alt="plus" />
                <p>Add new PSP</p>
              </>
            )}
          </Button>
        </div>
        {isBrainTreeForm ? (
          <div className={styles.addAccount}>
            <BrainTreeIntegration handleNewIntegration={handleNewIntegration} />
          </div>
        ) : isKlarnaForm ? (
          <div className={styles.addAccount}>
            <KlarnaIntegration handleNewIntegration={handleNewIntegration} />
          </div>
        ) : (
          <>
            {isAddAccountOpen ? (
              <div className={styles.addAccount}>
                <AddAccount
                  name="Add new account"
                  setBrainTreeForm={setBrainTreeForm}
                  setisKlarnaForm={setisKlarnaForm}
                />
              </div>
            ) : (
              <div className={styles.integrationDataWrapper}>
                {isLoading && <Loading />}
                {(!isLoading && isError) ||
                (isSuccess && data?.length === 0) ||
                (!isLoading && !data?.length) ? (
                  <NoDataFound
                    icon={svgIcons?.UnderReview}
                    isError={false}
                    errorStatement="Oops! Something went wrong. Please try again."
                    notFoundMessage="No Payment Account (PSP) found"
                  />
                ) : (
                  <>
                    {isSuccess &&
                      data?.length > 0 &&
                      data?.map((payment: any) => {
                        return (
                          <div
                            key={payment?.id}
                            className={styles.integratedData}
                          >
                            <div className={styles.integratedInfo}>
                              <div className={styles.icon}>
                                {payment.paymentProcessor === "PayPal" && (
                                  <img src={svgIcons.Paypal} alt="icon" />
                                )}
                                {payment.paymentProcessor === "Klarna" && (
                                  <img src={svgIcons.klarna} alt="icon" />
                                )}
                                {payment.paymentProcessor === "stripe" && (
                                  <img src={svgIcons.Stripe} alt="icon" />
                                )}
                                {payment.paymentProcessor === "BrainTree" && (
                                  <img src={svgIcons.BrainTree} alt="icon" />
                                )}
                              </div>
                              <div>
                                <h4>{payment?.description}</h4>
                                <p>{payment?.payerEmail}</p>
                                <p className={styles.id}>
                                  {payment?.payerId ?? payment?.merchantId}
                                </p>
                              </div>
                            </div>
                            <Button
                              onClick={() => handlePaypalRemove(payment?.id)}
                            >
                              <img src={svgIcons.Trash_1} alt="trash" />
                            </Button>
                          </div>
                        );
                      })}
                    {Math.ceil(data?.length / tableList) > 1 && (
                      <Pagination
                        onPageChange={onPageChange}
                        page={page}
                        totalPages={Math.ceil(data?.length / tableList)}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentAccountIntegration;

import { Button } from "@mui/material";
import { svgIcons } from "../../../elements";
import Loading from "../../loading/loading";
import NoDataFound from "../../noDataFound";
import { mockOtherIntegrationData } from "./mockOtherIntegrationData";

import styles from "./style.module.scss";

const OtherIntegrations = () => {
    // const [isAddAccountOpen, setIsAddAccountOpen] = useState<boolean>(false);

    const { isLoading, isSuccess, isError, data } = mockOtherIntegrationData;

    // const onAddAccountClick = () => {
    //     setIsAddAccountOpen(!isAddAccountOpen)
    // };

    return (
        <div className={styles.integrationMainWrapper}>
            <div className={styles.integrationMain}>
                <div className={styles.integrationHeader}>
                    <h3>Other Integrations</h3>
                    {/* <Button onClick={onAddAccountClick}>
                        {
                            isAddAccountOpen ? (
                                <p>Cancel</p>
                            ) : (
                                <>
                                    <img src={svgIcons.Plus_Circle} alt="plus" />
                                    <p>Add new integration</p>
                                </>
                            )
                        }
                    </Button> */}
                </div>
                {/* {
                    isAddAccountOpen &&
                    <div className={styles.addAccount}>
                        <AddAccount name="Add new integration" />
                    </div>
                } */}
                <div className={styles.integrationDataWrapper}>
                    {isLoading && <Loading />}
                    {
                        ((!isLoading && isError) || (isSuccess && data?.count === 0) || (!isLoading && !data?.count))
                            ? <NoDataFound
                                icon={svgIcons?.UnderReview}
                                isError={false}
                                errorStatement="Oops! Something went wrong. Please try again."
                                notFoundMessage="No integration found"
                            />
                            : (
                                isSuccess && data && data?.integratedOther?.map((otherIntegrated) => {
                                    return (
                                        <div key={otherIntegrated?.id} className={styles.integratedData}>
                                            <div className={styles.integratedInfo}>
                                                <div className={styles.icon}>
                                                    <img src={otherIntegrated?.icon} alt='icon' />
                                                </div>
                                                <div>
                                                    <h4>{otherIntegrated?.store}</h4>
                                                    <p>{otherIntegrated?.storeEmail}</p>
                                                </div>
                                            </div>
                                            <Button>
                                                <img src={svgIcons.Trash_1} alt="trash" />
                                            </Button>
                                        </div>
                                    )
                                })
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default OtherIntegrations;
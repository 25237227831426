import { svgIcons } from '../../elements';

export const storeIconArray: { [key: string]: any }[] = [
    {
        shopType: "SHOPIFY",
        icon: svgIcons.LachieporTwo
    },
    {
        shopType: "SHOPIFYPAYMENTS",
        icon: svgIcons.Lachiepor
    },
    {
        shopType: "WIX",
        icon: svgIcons.Wix
    },
    {
        shopType: "ECWID",
        icon: svgIcons.Ecwid
    },
    {
        shopType: "BIGCOMMERCE",
        icon: svgIcons.Costco_Gray
    },
    {
        shopType: "WOOCOMMERCE",
        icon: svgIcons.Leather
    },
    {
        shopType: "MAGENTO",
        icon: svgIcons.Majanogout_Gray
    },
    {
        shopType: "PRESTATSHOP",
        icon: svgIcons.Prestashop
    },
    {
        shopType: "SQUARESPACE",
        icon: svgIcons.Squarespace
    },
    {
        shopType: "BRAINTREE",
        icon: svgIcons.BrainTree
    },
    {
        shopType: "PAYPAL",
        icon: svgIcons.Paypal
    },
    {
        shopType: "KLARNA",
        icon: svgIcons.klarna
    },
    {
        shopType: "STRIPE",
        icon: svgIcons.Stripe
    },
];

export const getIconByShopType = (shopType: string) => {
    let shopData = storeIconArray.find((item, index) => {
        return item?.shopType?.toLowerCase() === shopType?.toLowerCase();
    })
    return shopData
}
import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResProp {
  data: {
    "id": number,
    "name": string,
    "email": string,
    "shopType": string
  },
  status:number
}

interface ShopDataResProp {
  data: {
    "id": number,
    "externalId": string,
    "appId": string,
    "domain": string,
    "name": string,
    "email": string,
    "shopType": string,
    "bigCommerceStoreHash": string,
    "bigCommerceStoreInternalHash": string,
    "accessToken": string,
    "refreshToken": string,
    "scopes": string,
    "onboarded": boolean,
    "riskAccepted": boolean,
    "chargeActive": boolean
  },
  status:number
}

export function* postAddShopifyShopRequest(action: any) {
  try {
    const res: ResProp = yield Api.postAddShopifyShop(action.payload);
    yield put(actions.postAddShopifyShopSuccess(res.data));
  } catch (e) {
    yield put(actions.postAddShopifyShopError());
  }
}

export function* getShopsNotificationsRequest() {
  try {
    const res: ResProp = yield Api.getShopsNotifications();
    if(res.status === (500 || 401)){
      yield put(actions.getShopsNotificationsError());
    }else{
      yield put(actions.getShopsNotificationsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getShopsNotificationsError());
  }
}

export function* postShopsSaveNotificationsRequest(action: any) {
  try {
    const res: ResProp = yield Api.postShopsSaveNotifications(action.payload);
    yield put(actions.postShopsSaveNotificationsSuccess(res.data));
  } catch (e) {
    yield put(actions.postShopsSaveNotificationsError());
  }
}

export function* postShopsAddOnboardedRequest() {
  try {
    const res: ResProp = yield Api.postShopsAddOnboarded();
    yield put(actions.postShopsAddOnboardedSuccess(res.data));
  } catch (e) {
    yield put(actions.postShopsAddOnboardedError());
  }
}

export function* postShopsAddRiskRequest() {
  try {
    const res: ResProp = yield Api.postShopsAddRisk();
    yield put(actions.postShopsAddRiskSuccess(res.data));
  } catch (e) {
    yield put(actions.postShopsAddRiskError());
  }
}

export function* getShopsDataRequest() {
  try {
    const res: ShopDataResProp = yield Api.getShopsData();
    if(res.status === (500 || 401)){
      yield put(actions.getShopsDataError());
    }else{
      yield put(actions.getShopsDataSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getShopsDataError());
  }
}

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  postStripeOnboardRequest,
  postStripeLoginRequest,
} from "redux/actions";
import { Theme, useTheme } from '@mui/material/styles';
import { getItem, removeItem, setItem } from "utils/localStorage";
import { SelectChangeEvent } from "@mui/material";

const useStripeInstall = () => {
  const dispatch = useDispatch();
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const location = useLocation();
  // Extract the path from the URL
  const path = location.pathname;
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  //new Changes
  const queryParams = getQueryParams(window.location.search);
  const returnedState: any = queryParams.get("state");
  const storedState = getItem('stripe_oauth_state');
  // Extracting query parameters from the UsRL
  // Constructing the string with the desired format
  const resultString: any = {
    code: queryParams.get("code"),
    userId: queryParams.get("user_id"),
    accountId: queryParams.get("account_id"),
    stripeUserId: queryParams.get("stripe_user_id"),
  };
  const userId = getItem("userId");
  const signature = getItem("signature");
  const fromStripe = getItem("fromStripe");
  const accountId = getItem("stripeAccountId");

  const theme = useTheme();
  const [selectedMode, setSelectedMode] = React.useState<string[]>(['live']);
  const handleChange = (event: SelectChangeEvent<typeof selectedMode>) => {
    const {
      target: { value },
    } = event;
    setSelectedMode(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const modes = [
    'live',
    'test',
  ];

  function getStyles(name: string, selectedMode: string[], theme: Theme) {
    return {
      fontWeight:
        selectedMode.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleClick = () => {
    function generateState(length: number) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    }
    const customData = {
      nonce: generateState(16),  // For CSRF protection
      mode: selectedMode[0],
    };
    const stateObject = JSON.stringify(customData);

    const encodedState = btoa(stateObject);  // Base64 encoding
    setItem('stripe_oauth_state', encodedState);
    let clientId = null;
    if (selectedMode[0] === "live") {
      clientId = "ca_PJqgLGtDDlRo8E9VlzoB3eC4ddNKs6Sp";
    } else if (selectedMode[0] === "test") {
      clientId = "ca_PJqgC04rd3iF7ay9znxdYkVgCmiCZSQT";
    }
    window.location.href =
      `https://marketplace.stripe.com/oauth/v2/authorize?client_id=${clientId}&redirect_uri=https://app.chargepay.ai/home&state=${encodedState}`;
  }


  const tokenHandler = async () => {
    try {
      const token = await getAccessTokenSilently();
      setItem("accessToken", token);
      const loginString = {
        accountId,
        userId,
        signature,
        token
      };
      dispatch(postStripeLoginRequest(loginString));
    } catch (error: any) {
      toast.error("Oops! Something went wrong", {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated && fromStripe) {
      tokenHandler();
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (
  //     resultString.accountId &&
  //     resultString.stripeUserId &&
  //     resultString.userId &&
  //     resultString.code
  //   ) {
  //     if (returnedState !== storedState) {
  //       console.error('State parameter mismatch!');
  //     } else {
  //       const decodedState = atob(returnedState);  // For Base64 decoding
  //       const stateData = JSON.parse(decodedState);
  //       resultString.mode = stateData["mode"];
  //       removeItem('stripe_oauth_state');
  //       dispatch(postStripeOnboardRequest(resultString));
  //     }
  //   }
  // }, [resultString.accountId, resultString.stripeUserId, resultString.userId, resultString.code]);



  useEffect(() => {
    if (
      resultString.accountId &&
      resultString.stripeUserId &&
      resultString.userId &&
      resultString.code
    ) {
      if (returnedState !== storedState) {
        console.error('State parameter mismatch!');
      } else {
        try {
          const decodedState = atob(returnedState);  // For Base64 decoding
          const cleanedState = decodedState.replace(/[\u0000-\u001F\u007F-\u009F\�ée]/g, "");

          console.log('Decoded State:', cleanedState, decodedState, { storedState });

          if (decodedState) {
            const stateData = JSON.parse(decodedState);
            resultString.mode = stateData["mode"];
            removeItem('stripe_oauth_state');
            dispatch(postStripeOnboardRequest(resultString));
          } else {
            console.error('Decoded state is empty or undefined:', { cleanedState });
          }
        } catch (error) {
          console.error('Failed to parse JSON:', { error }, 'Decoded State:');
        }
      }
    }
  }, [resultString.accountId, resultString.stripeUserId, resultString.userId, resultString.code]);

  return { resultString, theme, handleChange, getStyles, handleClick, MenuProps, modes, selectedMode };
};
export default useStripeInstall;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../../loading/loading";
import styles from "./style.module.scss";
import { Textarea } from "components/ui";
import { Box, TextField, Tooltip } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { svgIcons } from "elements";
import { isEmpty } from "lodash";
import Button from "components/ui/Button";
import ErrorMessage from "components/utils/ErrorMessage";
import useSupportingEvidence from "hooks/disputeDetails/useSupportingEvidence";
const fileTypes = ["PNG", "JPG", "JPEG", "PDF"];

const SupportingEvidence = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } :any= useForm();
  const { disputeId } = useParams();
  const { handleSupportingEvidence }:any = useSupportingEvidence();
  const [uploadFile, setUploadFile] = useState<any>([]);
  const handleFileUpload = (doc: any) => {
    setUploadFile(doc[0]);
  };
  useEffect(() => {
    if (uploadFile?.name) {
      clearErrors("file");
    }
  }, [uploadFile?.name]);

  const onSubmit = (data: any) => {
    const payload = {
      category: data?.category,
      comments: data?.comments,
      disputeId,
      file: uploadFile,
    };
    handleSupportingEvidence(payload, reset, setUploadFile);
    // reset();
    // setUploadFile([])
  };
  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.dropDownWrapper}>
          <div className={styles.initialSelects}>
            <div style={{ marginBottom: "12px" }}>
              <label htmlFor="">Category</label>
            </div>
            <Box gap={2} display={`flex`} justifyContent={`space-between`}>
              <Controller
                control={control}
                name={"category"}
                rules={{
                  required: {
                    value: true,
                    message: "This category is required",
                  },
                }}
                render={({ field }) => {
                  return (
                    <TextField
                      sx={{ maxWidth: "400px" }}
                      fullWidth
                      label={"Category"}
                      {...field}
                    />
                  );
                }}
              />
            </Box>
            {errors && <ErrorMessage name="category" errors={errors} />}
            <div className={styles.textAreaWrapper}>
              <div style={{ marginBottom: "4px", marginTop: "8px" }}>
                <label>Comments</label>
              </div>
              <Textarea
                minRows={10}
                aria-label="maximum height"
                placeholder="Type here..."
                {...register("comments", {
                  required: {
                    value: true,
                    message: "Comments is required",
                  },
                })}
              />
              {errors && <ErrorMessage name="comments" errors={errors} />}
            </div>
            <Tooltip
              arrow
              placement="top"
              title="Upload or drag and drop the file"
            >
              <div className={styles.uploadFile}>
                <label>Upload File</label>
                <div className={""}>
                  <FileUploader
                    multiple={true}
                    handleChange={(doc: any) => handleFileUpload(doc)}
                    name="file"
                    types={fileTypes}
                    children={
                      <div className={styles.documentField}>
                        <img
                          style={{ display: uploadFile.name ? "none" : "" }}
                          src={svgIcons.Upload_In_Blue}
                          alt="upload"
                        />
                        <p style={{ display: uploadFile.name ? "none" : "" }}>
                          Upload File
                        </p>
                        <input
                          data-testid="dispute-document"
                          hidden={uploadFile && uploadFile?.name ? false : true}
                          readOnly
                          value={uploadFile?.name}
                        />
                      </div>
                    }
                  />
                </div>
                <ErrorMessage name={"file"} errors={errors} />
              </div>
            </Tooltip>
            <Button
              {...{
                btnTitle: "Create",
                btnLabel: "Create",
                className: styles.selectButtonStyle,
                type: "submit",
                onClick: () => {
                  if (isEmpty(uploadFile?.name)) {
                    setError("file", {
                      type: "required",
                      message: "Please add a file",
                    });
                  } else {
                    clearErrors("file");
                  }
                },
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SupportingEvidence;

import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postConnectPayPalRequest, postShopsAddOnboardedRequest } from '../../redux/actions';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generatePayPalAuthorizeUrlOnboarding } from '../../utils/GenerateStoreUrls';
import { svgIcons } from '../../elements';
import { Loading } from '../index';

import styles from './style.module.scss';

interface Props {
  handleConnect: () => void;
}

const Connect: FC<Props> = ({ handleConnect }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clickedSkip, setClickedSkip] = useState(false);

  const { isLoading, isError, isSuccess } = useSelector((state: any) => state.connectPayPalStore.connectPaypal);

  const addShopifyShop = useSelector((state: any) => state?.shop?.addShopifyShop);

  const code = new URLSearchParams(window.location.search).get('code');

  const onAddAccountClick = (store: string) => {
    let redirectUrl = "";
    if (store === "PayPal") {
      redirectUrl = generatePayPalAuthorizeUrlOnboarding();
    }
    return (redirectUrl);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('PayPal connected successfully', {
        theme: 'colored'
      });
      setTimeout(() => {
        handleConnect();
      }, 4000);
    }
    else if (isError) {
      toast.error('Oops! PayPal connect fail. Please try again', {
        theme: 'colored'
      });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (code) {
      const payload = {
        code
      };
      dispatch(postConnectPayPalRequest(payload));
    }
  }, [code, dispatch]);

  useEffect(() => {
    if (clickedSkip) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, skip it!'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(postShopsAddOnboardedRequest())
          navigate("/home")
        } else if (result.isDismissed) {
          setClickedSkip(false)
        }
      })
    }
  }, [clickedSkip, dispatch, navigate])

  return (
    <div className={styles.connectWrapper}>
      <ToastContainer />
      <div className={styles.connectLeft}>
        <div className={styles.lineConnect}>
          <img src={svgIcons.ChargePayLogo} alt="log" />
          <img className={styles.lineCheck} src={svgIcons.Check_All} alt="check" />
          <img src={svgIcons.Lachiepor} alt="shopify" />
          <img className={styles.lineCheck} src={svgIcons.Shuffle} alt="shuffle" />
          <img src={svgIcons.PayPalFill} alt="paypal" />
          <div className={styles.line} />
        </div>
        <div>
          <h3>Connect your account</h3>
          <p>
            Your store details are synced below!
          </p>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <img src={svgIcons.Category} alt="category" />
            <p className={styles.shopType}>{addShopifyShop?.data?.shopType ? addShopifyShop?.data?.shopType : "Shop-Type"}</p>
          </div>
          <div className={styles.info}>
            <img src={svgIcons.BankTwo} alt="bank" />
            <p>{addShopifyShop?.data?.name ? addShopifyShop?.data?.name : "Shop-Name"}</p>
          </div>
          <div className={styles.info}>
            <img src={svgIcons.Mail} alt="mail" />
            <p>{addShopifyShop?.data?.email ? addShopifyShop?.data?.email : "Shop-Email"}</p>
          </div>
          <div className={styles.secure}>
            <img src={svgIcons.ShieldCheck} alt="shieldCheck" />
            <p>100% secure connection</p>
          </div>
        </div>
      </div>
      <div className={styles.connectRight}>
        <div className={styles.connectRightDefault}>
          <h5>Shopify payment connected by default.</h5>
          <p>You can connect an PayPal account to get started:</p>
          <Button variant="contained">
            {!isLoading
              ? (
                <a target='_blank' href={onAddAccountClick("PayPal")} rel="noopener noreferrer">
                  <img src={svgIcons.PayPalFill} alt="tp" />
                  <p>Log in with PayPal</p>
                </a>
              ) : (
                <div>
                  <Loading />
                </div>
              )
            }
          </Button>

        </div>

        <div className={styles.paypalBig}
          style={{
            backgroundImage: `url(${svgIcons.PaypalBig})`,
            backgroundRepeat: "no-repeat"
          }}
        >
          <Button variant="text" className={styles.skip} onClick={() => setClickedSkip(true)}>Skip</Button>
        </div>
      </div>
    </div>
  );
};

export default Connect;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getShopsNotificationsRequest, postShopsSaveNotificationsRequest } from "../../../redux/actions";
import styles from "./style.module.scss";

const notificationsData = [
  {
    key: "linkedAccounts",
    title: "Linked Accounts",
    description: "Paypal/ecommerce etc",
    checked: false,
  },
  {
    key: "syncUpdates",
    title: "Sync Updates",
    description: "These are notifications for when someone tags you in a comment, post or story",
    checked: false,
  },
  {
    key: "disputeUpdates",
    title: "Dispute Updates",
    description: "These are notifications to remind you of updates you might have missed",
    checked: false,
  },
  {
    key: "moreActivityAboutYou",
    title: "More activity about you",
    description: "These are notifications for posts on your profile, likes and other reactions to your posts, and more",
    checked: false,
  },
]

const NotificationsAndAlerts = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState(notificationsData)
  const [notificationChange, setNotificationChange] = useState<any>({
    change: false,        //to check to call postSaveNotification when checkbox changes
    updated: false        //to check ui gets updated after api call
  });

  const shopsNotifications = useSelector((state: any) => state?.shop?.shopsNotifications);
  const shopsSaveNotifications = useSelector((state: any) => state?.shop?.shopsSaveNotifications);

  useEffect(() => {
    if (shopsNotifications?.isSuccess && shopsNotifications?.data) {
      let notificationData: any = [];
      const notificationsCheck = [...notificationsData];
      notificationsCheck?.forEach((notification) => {
        if (shopsNotifications?.data[notification?.key]) {
          notificationData.push({
            ...notification,
            checked: true
          })
        } else {
          notificationData.push({
            ...notification,
            checked: false
          })
        }
      })
      setNotificationChange({
        change: true,
        updated: true
      })
      setNotifications(notificationData)
    }
  }, [shopsNotifications])

  useEffect(() => {
    dispatch(getShopsNotificationsRequest())
  }, [shopsSaveNotifications?.isSuccess, dispatch])

  useEffect(() => {
    if (notificationChange?.change && !notificationChange?.updated) {
      let payload = {}
      notifications?.forEach((notification) => {
        if (notification?.title === "Linked Accounts") {
          payload = {
            ...payload,
            "linkedAccounts": notification?.checked,
          }
        } else if (notification?.title === "Sync Updates") {
          payload = {
            ...payload,
            "syncUpdates": notification?.checked,
          }
        } else if (notification?.title === "Dispute Updates") {
          payload = {
            ...payload,
            "disputeUpdates": notification?.checked,
          }
        } else if (notification?.title === "More activity about you") {
          payload = {
            ...payload,
            "moreActivityAboutYou": notification?.checked,
          }
        }
      })
      dispatch(postShopsSaveNotificationsRequest(payload))
    }
  }, [notifications, dispatch])

  const handleCheck = (notification: any, num: number) => {
    setNotificationChange({
      change: true,
      updated: false
    });
    const allNotifications = [...notifications];
    notification.checked = !notification?.checked;
    allNotifications[num] = notification;
    setNotifications([...allNotifications]);
  }

  return (
    <div className={styles.notificationsWrapper}>
      <div className={styles.heading}>
        <h3>Notifications</h3>
        <p className={styles.intro}>
          Turn on and off notifications
        </p>
      </div>
      <div className={styles.infoDiv}>
        {notifications?.map((notification, index) => {
          return (
            <div className={styles.info} key={notification?.key}>
              <div className={styles.notify}>
                <div className={styles.desc}>
                  <h3>{notification?.title}</h3>
                  <p>{notification?.description}</p>
                </div>
                <div className={styles.infoSwitches}>
                  <div className={styles.infoSwitch} onClick={() => handleCheck(notification, index)}>
                    <label className={styles.switch}>
                      <input type="checkbox" checked={notification?.checked} />
                    </label>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default NotificationsAndAlerts;

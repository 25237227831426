import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDisputeFraudAnalysisRequest } from "redux/actions";
import { useParams } from "react-router-dom";
import Loading from "components/loading/loading";
import { Box, Paper } from "@mui/material";

const FraudAnalysis = () => {
  const [disputePaymentGateway, setDisputePaymentGateway] = useState("");
  const dispatch = useDispatch();
  const { disputeId } = useParams();
  const { data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  const {
    data: fraudAnalysisData,
    isLoading,
    isSuccess,
  } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFraudAnalysis
  );
  useEffect(() => {
    if (data?.paymentProcessor) {
      setDisputePaymentGateway(data?.paymentProcessor);
    }
  }, [data]);
  useEffect(() => {
    dispatch(getDisputeFraudAnalysisRequest(disputeId));
  }, []);
  return (
    <div className={styles.fraudAnalysisWrapper}>
      <div style={{ width: "100%" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {fraudAnalysisData?.data ? (
              <Box>
                <Paper>
                  <div
                    className={styles.fraudAnalysisDetails}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className={styles.fraudAnalysisData}>
                      <p>Fraud Score</p>
                      <div className={styles.fraudAnalysisValue}>
                        <p>{fraudAnalysisData?.data?.fraudScore}</p>
                      </div>
                    </div>
                    <div className={styles.fraudAnalysisData}>
                      <p>Risk Level</p>
                      <div className={styles.fraudAnalysisValue}>
                        <p>{fraudAnalysisData?.data?.riskLevel}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className={styles.fraudAnalysisDetails}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className={styles.fraudAnalysisData}>
                      <p>Order Name</p>
                      <div className={styles.fraudAnalysisValue}>
                        <p>{fraudAnalysisData?.data?.orderName}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.fraudAnalysisData}>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          lineHeight: "18px",
                          color: "#101828",
                          marginBottom: "5px",
                        }}
                      >
                        Indicators
                      </p>
                      <ul style={{ padding: "0px 25px" }}>
                        {fraudAnalysisData?.data?.indicators.map(
                          (item: string, index: number) => (
                            <li
                              style={{
                                fontSize: "14px",
                                color: "#939292",
                                padding: "4px 6px",
                              }}
                              key={index}
                            >
                              {item}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </Paper>
              </Box>
            ) : (
              <div className={styles.evidenceWrapper}>
                <p>No Transaction Details avaiable</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FraudAnalysis;

import Filters from "./svg/filters.svg";
import Calender from "./svg/calender.svg";
import Right from "./svg/right.svg";
import Refresh from "./svg/refresh.svg";
import Search from "./svg/search.svg";
import Arrows from "./svg/arrows.svg";
import Check from "./svg/check.svg";
import Err from "./svg/error.svg";
import Prev from "./svg/prev.svg";
import Next from "./svg/next.svg";
import FilterDown from "./svg/filterDropdown.svg";
import Delete from "./svg/trash.svg";
import Completed from "./svg/completed.svg";
import Progress from "./svg/progress.svg";
import Failed from "./svg/failed.svg";
import Download from "./svg/download.svg";
import NoDispute from "./svg/noDispute.svg";
import Info from "./svg/info.svg";
import Lachiepor from "./svg/lachiepor.svg";
import LachieporTwo from "./svg/lachieporTwo.svg";
import Majanogout from "./svg/majanogout.svg";
import SelfService from "./svg/selfService.svg";
import StoreOne from "./svg/unltdStoresOne.svg";
import Wallet from "./svg/ultdAccOne.svg";
import SelfPlatform from "./svg/selfPlatform.svg";
import Priority from "./svg/priorityOne.svg";
import Digital from "./svg/digitalOne.svg";
import Courier from "./svg/courier.svg";
import Subscription from "./svg/subscription.svg";
import OfferOne from "./svg/offerOne.svg";
import OfferTwo from "./svg/offerTwo.svg";
import StoreTwo from "./svg/unltdStoresTwo.svg";
import Dedicated from "./svg/dedicated.svg";
import DigitalTwo from "./svg/digitalTwo.svg";
import SubscriptionTwo from "./svg/subscriptionTwo.svg";
import FullyManaged from "./svg/fullyManaged.svg";
import Costco from "./svg/costco.svg";
import Ecommerce from "./svg/ecommerce.svg";
import View from "./svg/view.svg";
import Edit from "./svg/edit.svg";
import Add from "./svg/add.svg";
import CircleCheck from "./svg/circle.svg";
import NotificationActive from "./svg/notificationActive.svg";
import NotificationInactive from "./svg/notificationInactive.svg";
import BackInTime from "./svg/backInTime.svg";
import Card from "./svg/card.svg";
import DashBoard from "./svg/dashboard.svg";
import Disputes from "./svg/disputes.svg";
import Message from "./svg/message-circle.svg";
import Messages from "./svg/messages.svg";
import Down from "./svg/down.svg";
import TPConnect from "./svg/tpConnect.svg";
import Upload from "./svg/upload.svg";
import Upload_In_Blue from "./svg/upload-in-blue.svg";
import Document from "./svg/document.svg";
import Plus from "./svg/plus.svg";
import ArrowLeft from "./svg/arrowLeft.svg";
import ArrowRight from "./svg/arrow-right.svg";
import Amazon from "./svg/amazon.svg";
import Google from "./svg/google.svg";
import Netflix from "./svg/netflix.svg";
import Skype from "./svg/skype.svg";
import Update from "./svg/update.svg";
import PaypalDiv from "./svg/paypalDiv.svg";
import Paypal from "./svg/paypal.svg";
import PaypalBig from "./svg/paypalBig.svg";
import PayPalFill from "./svg/paypalfill.svg";
import PayPal_Icon_Text from "./svg/paypal_Icon_Text.svg";
import PayPal_Linked_Account from "./svg/payPalLinkedAccount.svg";
import Close from "./svg/close.svg";
import CheckTwo from "./svg/checkTwo.svg";
import ProgressChart from "./svg/progressChart.svg";
import ArrowUp from "./svg/arrowUp.svg";
import ArrowDown from "./svg/arrowDown.svg";
import Regress from "./svg/regressChart.svg";
import Cart from "./svg/cart.svg";
import Cart_Alt from "./svg/bx_cart-alt.svg";
import SettingsTwo from "./svg/settingsTwo.svg";
import CheckThree from "./svg/checkThree.svg";
import CircleTwo from "./svg/circleTwo.svg";
import CircleTwo_1 from "./svg/circleTwo_1.svg";
import CircleTwo_2 from "./svg/circleTwo_2.svg";
import Alert_Circle from "./svg/alert-circle.svg";
import Alert_Circle_Black from "./svg/alert_circle_black.svg";
import Alert_Circle_Blue from "./svg/alert-circle-blue.svg";
import Bar_Chart from "./svg/bar_chart.svg";
import YellowFlag from "./svg/yellowFlag.svg";
import Money from "./svg/Money.svg";
import BlueEyeBadge from "./svg/blueEyeBadge.svg";
import Eye from "./svg/eye.svg";
import Leather from "./svg/leather.svg";
import ArrowUpDown from "./svg/arrowUpDown.svg";
import GreenTick from "./svg/greenTick.svg";
import RedCross from "./svg/redCross.svg";
import RedOverdue from "./svg/redOverdue.svg";
import Star from "./svg/Star.svg";
import Star_Blue from "./svg/starBlue.svg";
import Trash_1 from "./svg/trash-1.svg";
import Trash_2 from "./svg/trash-2.svg";
import CheckFour from "./svg/checkFour.svg";
import CheckFive from "./svg/checkFive.svg";
import CheckSix from "./svg/CheckSix.svg";
import Arrow_Up from "./svg/arrow-up.svg";
import InProgress from "./svg/inProgress.svg";
import Edit_White from "./svg/edit_white.svg";
import Majanogout_Gray from "./svg/majanogout_gray.svg";
import Costco_Gray from "./svg/costco_gray.svg";
import CheckIcon from "./svg/check-icon.svg";
import Bank from "./svg/bankBuilding.svg";
import ChartLineUp from "./svg/ChartLineUp.svg";
import Chart from "./svg/chart.svg";
import Coins from "./svg/Coins.svg";
import MasterCard from "./svg/masterCard.svg";
import UnderReview from "./svg/underReview.svg";
import Result from "./svg/result.svg";
import Link from "./svg/link.svg";
import GettingCoffee from "./svg/GettingCoffee-rafiki.svg";
import Undraw_Winner from "./svg/undraw_winners.svg";
import Undraw_Lost from "./svg/undraw_lost.svg";
import Animation_640_1 from "./svg/animation_640_1.svg";
import Animation_640_3 from "./svg/animation_640_3.svg";
import Animation_640_4 from "./svg/animation_640_4.svg";
import Half_Slive_Polo_Tshirt from "./svg/half-slive-polo-tshirt.svg";
import chevronRight from "./svg/chevronRight.svg";
import Squarespace from "./svg/squarespace.svg";
import Wix from "./svg/wix.svg";
import Ecwid from "./svg/ecwid.svg";
import Prestashop from "./svg/prestashop.svg";
import Other_Integration_Gear from "./svg/otherIntegrationGear.svg";

import Activity from "./svg/activity.svg";
import Currency_Dollar from "./svg/currency-dollar.svg";
import Layers_three from "./svg/layers-three-01.svg";
import Trophy from "./svg/trophy-01.svg";
import Coffee_Break from "./svg/Coffee-break-rafiki-1.svg";
import ChargePayLogo from "./svg/ChargePayLogo.svg";
import ChargePayName from "./svg/ChargePayName.svg";
import Accounts from "./svg/Accounts.svg";
import Logout from "./svg/log-out-01.svg";
import Settings from "./svg/Settings.svg";
import Puzzle_Piece from "./svg/puzzle-piece-01.svg";
import Plus_Circle from "./svg/plus-circle.svg";
import Intercom from "./svg/intercom-icon-1.svg";
import Zendesk from "./svg/Mask-group.svg";
import Mail from "./svg/mail.svg";
import Category from "./svg/category.svg";
import BankTwo from "./svg/bankOffice.svg";
import ShieldCheck from "./svg/shield-check.svg";
import Check_All from "./svg/check_all.svg";
import Shuffle from "./svg/shuffle.svg";
import Glasses from "./svg/glasses-01.svg";
import Message_Alert from "./svg/message-alert-circle.svg";
import Message_Check from "./svg/message-check-circle.svg";
import Message_X from "./svg/message-x-circle.svg";
import Warning from "./svg/warning.svg";
import Warning_Red from "./svg/warning_red.svg";
import BrainTree from "./svg/brainTree.svg";
import Stripe from "./svg/Stripe.svg";
import Stripe_Icon_Text from "./svg/Stripe_Icon_Text.svg";
import ChevronRight from "./svg/chevron-right.svg";
import Corner_Down_Right from "./svg/corner-down-right.svg";
import Process_Rafiki from "./svg/Process-rafiki-1.svg";
import klarna from "./svg/klarna.svg";
import OrderFulfillment from "./svg/orderFulFillment.svg"
import CustomerInformation from "./svg/customerInformation.svg"
import OrderStatus from "./svg/orderStatus.svg"
import TrackingLocation from "./svg/trackingLocation.svg"
export const svgIcons = {
  Ecommerce,
  Paypal,
  View,
  Edit,
  Edit_White,
  Add,
  OfferTwo,
  StoreTwo,
  Dedicated,
  DigitalTwo,
  SubscriptionTwo,
  FullyManaged,
  SelfService,
  StoreOne,
  Wallet,
  SelfPlatform,
  Priority,
  Digital,
  Courier,
  Subscription,
  OfferOne,
  CircleCheck,
  NotificationActive,
  NotificationInactive,
  BackInTime,
  Card,
  DashBoard,
  Disputes,
  Message,
  Messages,
  Down,
  TPConnect,
  Upload,
  Upload_In_Blue,
  Document,
  Plus,
  Filters,
  Calender,
  Right,
  Refresh,
  Search,
  Arrows,
  Check,
  Err,
  Prev,
  Next,
  FilterDown,
  Delete,
  Completed,
  Progress,
  Failed,
  Download,
  NoDispute,
  Info,
  Lachiepor,
  Majanogout,
  Majanogout_Gray,
  Costco,
  Costco_Gray,
  LachieporTwo,
  ArrowLeft,
  ArrowRight,
  Amazon,
  Google,
  Skype,
  Netflix,
  Update,
  PaypalDiv,
  Close,
  CheckTwo,
  ProgressChart,
  ArrowUp,
  Arrow_Up,
  ArrowDown,
  Regress,
  Cart,
  Cart_Alt,
  SettingsTwo,
  CheckThree,
  CheckFour,
  CheckFive,
  CheckSix,
  CheckIcon,
  CircleTwo,
  CircleTwo_1,
  CircleTwo_2,
  PaypalBig,
  Alert_Circle,
  Alert_Circle_Black,
  Alert_Circle_Blue,
  Bar_Chart,
  YellowFlag,
  Money,
  BlueEyeBadge,
  Eye,
  Leather,
  ArrowUpDown,
  GreenTick,
  RedCross,
  RedOverdue,
  Star,
  Star_Blue,
  PayPalFill,
  PayPal_Linked_Account,
  Trash_1,
  Trash_2,
  InProgress,
  chevronRight,
  PayPal_Icon_Text,
  Bank,
  ChartLineUp,
  Chart,
  Coins,
  MasterCard,
  UnderReview,
  Result,
  Link,
  GettingCoffee,
  Undraw_Winner,
  Undraw_Lost,
  Animation_640_1,
  Animation_640_3,
  Animation_640_4,
  Half_Slive_Polo_Tshirt,
  Squarespace,
  Wix,
  Ecwid,
  Prestashop,
  Other_Integration_Gear,
  klarna,
  Activity,
  Currency_Dollar,
  Layers_three,
  Trophy,
  Coffee_Break,
  ChargePayLogo,
  ChargePayName,
  Accounts,
  Logout,
  Settings,
  Puzzle_Piece,
  Plus_Circle,
  Intercom,
  Zendesk,
  Mail,
  Category,
  BankTwo,
  ShieldCheck,
  Check_All,
  Shuffle,
  Glasses,
  Message_Alert,
  Message_Check,
  Message_X,
  Warning,
  Warning_Red,
  BrainTree,
  Stripe,
  Stripe_Icon_Text,
  ChevronRight,
  Corner_Down_Right,
  Process_Rafiki,
  OrderFulfillment,
  CustomerInformation,
  OrderStatus,
  TrackingLocation
};

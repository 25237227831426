import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import {configs} from '../../../configs/index';

export class PostFilteredDisputes {
    /**
     * Function to invoke to get filtered disputes
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.FIND_FILTERED_DISPUTES, payload, { baseURL: configs.BASE_URL as string });
        
        return res; 
    }
}
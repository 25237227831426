import { Button as MuiButton, Tooltip } from "@mui/material";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
export default function Button({
  variant,
  placement,
  btnTitle,
  btnLabel,
  noStyle,
  endIcon,
  startIcon,
  className,
  size,
  href,
  ...rest
}: any) {
  return (
    <Tooltip
      title={btnTitle ?? "Click"}
      arrow
      placement={placement ? placement : "top"}
    >
      {href ? (
        <Link
          className={className ? className : noStyle ? "" : styles.linkButton}
          to={href}
        >
          {startIcon ?? null}
          <span>{btnLabel}</span>
          {endIcon ?? null}
        </Link>
      ) : (
        <MuiButton
          variant={variant ? variant : "contained"}
          startIcon={startIcon}
          endIcon={endIcon}
          className={
            className ? className : noStyle ? "" : styles.saveButtonStyle
          }
          size={size ? size : "medium"}
          {...rest}
        >
          {btnLabel}
        </MuiButton>
      )}
    </Tooltip>
  );
}

import React from "react";
import styles from './style.module.scss';

const loadingImg =
  "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

const Loading = () => (
  <div className={styles.spinner}>
    <img src={loadingImg} alt="Loading..." />
  </div>
);

export default Loading;
import { Box, Button } from "@mui/material";
import { svgIcons } from "elements";
import useStripeInstall from "hooks/stripeHooks/useStripeInstall";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const StripeInstall = () => {
  const {
    theme,
    MenuProps,
    modes,
    selectedMode,
    handleChange,
    getStyles,
    handleClick,
  } = useStripeInstall()

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          height: "560px",
          width: "560px",
          border: "1px solid #e5e5e5",
          borderRadius: "10px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >

        <Box
          display={`flex`}
          justifyContent={`center`}
          mt={`20px`}
        >
          <img
            src={svgIcons.ChargePayLogo}
            alt="CP_logo"
          />
          <img
            src={svgIcons.ChargePayName}
            alt="CP_name"
          />
        </Box>
        <Box>
          <FormControl sx={{ m: 1, mt: 7, width: 450 }}>
            <InputLabel id="demo-multiple-name-label">Select Mode</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={selectedMode}
              onChange={handleChange}
              input={<OutlinedInput label="Select Mode" />}
              MenuProps={MenuProps}
            >
              {modes.map((name: string) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, selectedMode, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <Button
            sx={{
              mt: 6,
              alignSelf: "end"
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
export default StripeInstall;
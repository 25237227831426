import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeDetailsResProp {
    data: {
        "id": string,
        "createTime": string,
        "updateTime": string,
        "status": string,
        "reason": string,
        "disputeState": string,
        "disputeLifeCycleStage": string,
        "order": string,
        "storeOrderId": string,
        "shopName": string,
        "shopType": string,
        "sellerMerchantId": string,
        "disputeAmountValue": number,
        "disputeAmountCurrencyCode": string,
        "sellerResponseDueDate": string,
        "buyerResponseDueDate": string,
        "sellerTransactionId": string,
        "paypalId": string,
        "email": string,
        "perEmail": string
    },
    status: number
}

export function* getDisputeDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeDetailsError());
        } else {
            yield put(actions.getDisputeDetailsSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeDetailsError());
    }
}

export function* getDisputeTransactionDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeTransactionDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeTransactionDetailsError());
        } else {
            yield put(actions.getDisputeTransactionDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeTransactionDetailsError());
    }
}

export function* getDisputeEvidenceDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeEvidenceDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeEvidenceDetailsError());
        } else {
            yield put(actions.getDisputeEvidenceDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeEvidenceDetailsError());
    }
}

export function* getDisputeMessageDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeMessageDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeMessageDetailsError());
        } else {
            yield put(actions.getDisputeMessageDetailsSuccess(res.data));
        }


    } catch (e) {
        yield put(actions.getDisputeMessageDetailsError());
    }
}

export function* getDisputeAdjudicationDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeAdjudicationDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeAdjudicationDetailsError());
        } else {
            yield put(actions.getDisputeAdjudicationDetailsSuccess(res.data));
        }


    } catch (e) {
        yield put(actions.getDisputeAdjudicationDetailsError());
    }
}

export function* getDisputeMoneyMovementDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeMoneyMovementDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeMoneyMovementDetailsError());
        } else {
            yield put(actions.getDisputeMoneyMovementDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeMoneyMovementDetailsError());
    }
}

export function* getDisputeSupportingInfoDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeSupportingInfoDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeSupportingInfoDetailsError());
        } else {
            yield put(actions.getDisputeSupportingInfoDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeSupportingInfoDetailsError());
    }
}

export function* getDisputeOfferDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeOfferDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeOfferDetailsError());
        } else {
            yield put(actions.getDisputeOfferDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeOfferDetailsError());
    }
}

export function* getDisputeOrderSummaryRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getDisputeOrderSummary(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeOrderSummaryError());
        } else {
            yield put(actions.getDisputeOrderSummarySuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeOrderSummaryError());
    }
}

export function* getDisputeFraudAnalysisRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getManageDisputeFraudAnalysisDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeFraudAnalysisError({}));
        } else {
            yield put(actions.getDisputeFraudAnalysisSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeFraudAnalysisError());
    }
}
export function* getDisputeCustomerDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getManageDisputeByCustomerDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeCustomerDetailsError());
        } else {
            yield put(actions.getDisputeCustomerDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeCustomerDetailsError());
    }
}
export function* getDisputeOrderFulfillmentDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getManageDisputeOrderFullmentDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeOrderFulfillmentDetailsError());
        } else {
            yield put(actions.getDisputeOrderFulfillmentDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeOrderFulfillmentDetailsError());
    }
}

export function* getDisputeTrackingDetailsRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.getManageDisputeGetTrackingDetails(action.payload);
        if (res.status === (500 || 401)) {
            yield put(actions.getDisputeTrackingDetailsError());
        } else {
            yield put(actions.getDisputeTrackingDetailsSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getDisputeTrackingDetailsError());
    }
}
export function* postAddSupprotingEvidenceRequest(action: any) {
    try {
        const res: DisputeDetailsResProp = yield Api.postDisputeSupportingEvidence(action.payload);
        yield put(actions.postAddSupprotingEvidenceSuccess(res.data));
    } catch (e) {
        yield put(action.postAddSupprotingEvidenceError())
    }
}
import CurrencyFormat from "react-currency-format";
import { getDisputeCurrency } from '../../utils/disputeUtils';

const FormatCurrency = ({ value, currencyCode }: any) => {
  return (
    <CurrencyFormat
      displayType={"text"}
      thousandSeparator={true}
      value={value}
      prefix={currencyCode ? getDisputeCurrency(currencyCode)?.currencySign : '$'}
    />
  );
};

export default FormatCurrency;

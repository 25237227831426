
export const generatePayPalAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_PAYPAL_CLIENT_ID!);
    params.append("scope", process.env.REACT_APP_PAYPAL_SCOPES!);
    params.append("redirect_uri", process.env.REACT_APP_PAYPAL_REDIRECT_URL!);
    rebuiltAuthorizeUrl= `${process.env.REACT_APP_PAYPAL_AUTHORIZE_URL}${params.toString()}`
    return rebuiltAuthorizeUrl;
}
export const generatePayPalAuthorizeUrlOnboarding = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_PAYPAL_CLIENT_ID!);
    params.append("scope", process.env.REACT_APP_PAYPAL_SCOPES!);
    params.append("redirect_uri", process.env.REACT_APP_PAYPAL_REDIRECT_URL_ONBOARD!);
    rebuiltAuthorizeUrl= `${process.env.REACT_APP_PAYPAL_AUTHORIZE_URL}${params.toString()}`
    return rebuiltAuthorizeUrl;
}
export const generateStripeAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_STRIPE_CLIENT_ID!);
    params.append("scope", process.env.REACT_APP_STRIPE_SCOPES!);
    params.append("redirect_uri", process.env.REACT_APP_STRIPE_REDIRECT_URL!);
    rebuiltAuthorizeUrl= `${process.env.REACT_APP_STRIPE_AUTHORIZE_URL}${params.toString()}`
    return rebuiltAuthorizeUrl;
}
export const generateBrainTreeAuthorizeUrl = () => {
    let rebuiltAuthorizeUrl;
    // const params = new URLSearchParams();
    // params.append("client_id", process.env.REACT_APP_INTERCOM_CLIENT_ID!);
    // params.append("scope", process.env.REACT_APP_INTERCOM_SCOPES!);
    // params.append("redirect_uri", process.env.REACT_APP_INTERCOM_REDIRECT_URL!);
    // rebuiltAuthorizeUrl= `${process.env.REACT_APP_INTERCOM_AUTHORIZE_URL}${params.toString()}`
    rebuiltAuthorizeUrl= ``
    return rebuiltAuthorizeUrl;
}

export const generateShopifyAuthorizeUrl = (shop: string) => {
    let rebuiltAuthorizeUrl;
    const params = new URLSearchParams();
    params.append("client_id", process.env.REACT_APP_SHOPIFY_CLIENT_ID!);
    params.append("redirect_uri", process.env.REACT_APP_SHOPIFY_REDIRECT_URL!);
    params.append("scope", process.env.REACT_APP_SHOPIFY_SCOPES!);
    // rebuiltAuthorizeUrl = `${process.env.REACT_APP_SHOPIFY_AUTHORIZE_URL}${params.toString()}`;
    rebuiltAuthorizeUrl = `https://${shop}/admin/oauth/authorize?${params.toString()}`;

    return rebuiltAuthorizeUrl;
}

export const getAuthorizeResult = (pathName: string) => {
    const apiOrigin = process.env.REACT_APP_BACKEND_API_BASE_URL;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let data;
    let url;
    if (pathName.includes("wix/authenticate")) {
        url = `${apiOrigin}/shops/add-wix-shop`;
        data = {
            code: params.get("code"),
            instanceId: params.get("instanceId")
        }
    } else if (pathName.includes("bigcommerce/authenticate")) {
        url = `${apiOrigin}/shops/add-bigcommerce-shop`;
        data = {
            code: params.get("code"),
            scope: params.get("scope"),
            context: params.get("context")
        }
    } else if (pathName.includes("square/authenticate")) {
        url = `${apiOrigin}/shops/add-squarespace-shop`;
        data = {
            code: params.get("code"),
            state: params.get("state"),
            error: params.get("error")
        }
    } else if (pathName.includes("ecwid/authenticate")) {
        url = `${apiOrigin}/shops/add-ecwid-shop`;
        data = {
            code: params.get("code"),
            storeId: params.get("store_id")
        }
    } else if (pathName.includes("shopify/authenticate")) {
        url = `${apiOrigin}/shops/add-shopify-shop`;
        data = {
            hmac: params.get("hmac"),
            shop: params.get("shop")
        }
    }
    return { data, url };
};
import { setItem } from "../../../utils/localStorage";
import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";

export class PostAddShopifyShop {
    /**
     * Function to invoke to shop
     */
    async invoke(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_ADD_SHOPIFY_SHOP(payload));

        const accessToken = res?.data?.token?.replace('Bearer ', '').trim();

        if (accessToken) {
            setItem('accessToken', accessToken);
            setItem('addShopifyShop', JSON.stringify(res?.data))
            return res;
        } else {
            let newError = new Error()
            newError = { ...newError }
            throw newError
        }
    }

    async invokeShopsNotifications() {
        const res = await ApiService.getInstance().get(apiConstants.GET_SHOPS_NOTIFICATIONS);

        return res;
    }

    async invokeShopsSaveNotifications(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_SHOPS_SAVE_NOTIFICATIONS, payload)

        return res;
    }

    async invokeShopsAddOnboarded() {
        const res = await ApiService.getInstance().post(apiConstants.POST_SHOPS_ADD_ONBOARDED)

        return res;
    }

    async invokeShopsAddRisk() {
        const res = await ApiService.getInstance().post(apiConstants.POST_SHOPS_ADD_RISK)

        return res;
    }

    async invokeShopsData() {
        const res = await ApiService.getInstance().get(apiConstants.GET_SHOPS_DATA);

        return res;
    }
}
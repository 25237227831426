import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getDisputeMessageDetailsRequest } from "../../../redux/actions";
import { getColorByStage, getColorByReason } from '../../../utils/disputeUtils';
import { svgIcons } from '../../../elements';

import styles from './style.module.scss';

const DisputeCurrent = () => {
    const dispatch = useDispatch();
    const [messageData, setMessageData] = useState<any[]>([]);
    const [disputeData, setDisputeData] = useState<any>({});
    const { disputeId } = useParams();

    const { disputeDetails, disputeMessageDetails } = useSelector((state: any) => state?.disputeDetailsStore);

    useEffect(() => {
        if (disputeId) {
            const payload = disputeId;
            dispatch(getDisputeMessageDetailsRequest(payload));
        }
    }, [disputeId, dispatch]);

    useEffect(() => {
        if (disputeMessageDetails.data && disputeDetails.data) {
            setMessageData(disputeMessageDetails.data);
            setDisputeData(disputeDetails.data);
        }
    }, [disputeMessageDetails, disputeDetails])

    return (
        <div className={styles.disputeCurrentWrapper}>
            <div>
                <div className={styles.disputeCurrentData}>
                    <div>
                        <h6>Stage</h6>
                        <p className={styles.textCapitalize} style={{
                            backgroundColor: getColorByStage(disputeData.disputeLifeCycleStage)?.backgroundColor,
                            color: getColorByStage(disputeData.disputeLifeCycleStage)?.textColor
                        }}>
                            {disputeData?.disputeLifeCycleStage?.split('_').join(' ')}
                        </p>
                    </div>

                    <div>
                        <h6>Reason of Dispute</h6>
                        <p className={styles.textCapitalize} style={{
                            backgroundColor: getColorByReason(disputeData?.reason)?.backgroundColor,
                            color: getColorByReason(disputeData?.reason)?.textColor
                        }}>
                            {disputeData?.reason?.split('_').join(' ')}
                        </p>
                    </div>

                    <div>
                        <h6>Due date to submit response</h6>
                        <p className={styles.disputeDueDate}>
                            {disputeData?.sellerResponseDueDate &&
                                moment(disputeData?.sellerResponseDueDate).format("MMMM Do YYYY")
                            }
                        </p>
                    </div>
                </div>

                <div className={styles.disputeCurrentMessageWrapper}>
                    <div>
                        <>
                            {messageData?.map((message: any) => {
                                return (
                                    <div className={styles.disputeCurrentMessage} key={message.id}>
                                        <img src={svgIcons.Message} alt='message' />
                                        <div className={styles.disputeMessageText}>
                                            <h6>{`Message Received From ${message.postedBy}`}</h6>
                                            <p>{message.content}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisputeCurrent;
import * as ApiConstants from '../configs';
import axios from "axios";

function responseTransformer(res: any, transformResponse: any) {
  //document why this function 'responseTransformer' is empty
}

class ApiService {

  axiosClient: any;
  private static rootStore: any;


  constructor(authToken = '') {
    if (authToken) {
      // @ts-ignore
      this.axiosClient = axios.create({ ...ApiConstants.API_CONFIG(authToken), baseURL: ApiConstants.configs.BASE_URL })
    } else {
      // @ts-ignore
      this.axiosClient = axios.create({ ...ApiConstants.API_CONFIG(), baseURL: ApiConstants.configs.BASE_URL })
    }
  }

  static init(rootStore: any) {
    this.rootStore = rootStore
    return new ApiService()
  }

  static getInstance = (token?: string | null) => {
    const authToken: string | null = token || localStorage.getItem('accessToken');
    return new ApiService(authToken!)
  }


  get = async (apiPath: any, options?: { baseURL: string; extras?: { timeout: any; }; transformRequest?: (arg0: any, arg1: any) => any; }) => {

    const source = axios.CancelToken.source()
    const timeout = setTimeout(source.cancel, options?.extras?.timeout || ApiConstants.API_CONFIG().timeout)

    return this.axiosClient
      .get(apiPath, {
        baseURL: options?.baseURL,
        cancelToken: source.token,
        transformRequest: [
          (data: any, headers: any) => {
            return options?.transformRequest ? options.transformRequest(data, headers) : data
          },
        ],
        /*
            validateStatus returns the response in case of error
        */
        validateStatus: () => {
          return true
        },
        ...options?.extras

      }).then((response: any) => {
        clearTimeout(timeout)
        return response
      }).catch((actualError: { name: string; }) => {
        clearTimeout(timeout)
        if (actualError.name === 'TypeError') {

          const customError = new Error('Network Error')
          customError.name = 'APIError'
          throw customError

        } else {
          throw actualError
        }
      })
  }


  post = async (apiPath: string, payload?: any, options?: { baseURL: string; extras?: any; transformRequest?: any; }) => {
    const source = axios.CancelToken.source()
    const timeout = setTimeout(source.cancel, options?.extras?.timeout || ApiConstants.API_CONFIG().timeout)

    return this.axiosClient
      .post(apiPath, JSON.stringify(payload), {
        baseURL: options?.baseURL,
        cancelToken: source.token,
        headers: this.axiosClient.defaults.headers.common,
        transformRequest: [
          (data: any, headers: any) => {
            return options?.transformRequest ? options.transformRequest(data, headers) : data
          },
        ],
        /*
            validateStatus returns the response in case of error
        */
        validateStatus: () => {
          return true
        },
      }).then((response: any) => {
        clearTimeout(timeout)
        return response
      }).catch((actualError: { name: string; }) => {
        clearTimeout(timeout)
        if (actualError.name === 'TypeError') {

          const customError = new Error('Network Error')
          customError.name = 'APIError'
          throw customError

        } else {
          throw actualError
        }
      })
  }


  put = async (apiPath: any, payload: any, options: { extras: { timeout: any; }; baseURL: any; transformResponse: any; }) => {
    const source = axios.CancelToken.source()
    const timeout = setTimeout(source.cancel, options?.extras?.timeout || ApiConstants.API_CONFIG().timeout)

    return this.axiosClient
      .put(apiPath, JSON.stringify(payload), {
        baseURL: options?.baseURL,
        cancelToken: source.token,
        headers: this.axiosClient.defaults.headers.common,
        transformResponse: (res: any) => (responseTransformer(res, options?.transformResponse)),
        /*
         validateStatus returns the response in case of error
        */
        validateStatus: () => {
          return true
        },
      }).then((response: any) => {
        clearTimeout(timeout)
        return response
      }).catch((actualError: { name: string; }) => {
        clearTimeout(timeout)
        if (actualError.name === 'TypeError') {

          const customError = new Error('Network Error')
          customError.name = 'APIError'
          throw customError

        } else {
          throw actualError
        }
      })
  }

  delete = async (apiPath: any) => {
    return this.axiosClient.delete(apiPath).then((response: any) => response)
  }
}


export default ApiService
import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetNotificationsList {
  /**
   * Function to invoke notifications
   */
  async invoke() {

    const res = await ApiService.getInstance().get(apiConstants.GET_NOTIFICATIONS_LIST);
    
    return res;
  }

}
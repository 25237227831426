import { FC, useEffect, useState } from 'react';
import cloneDeep from "lodash/cloneDeep";
import { useDispatch } from 'react-redux';
import { Tooltip, Button } from '@mui/material';
import { postFilteredDisputesRequest, getDisputesListRequest } from "../../../redux/actions";
import { svgIcons } from '../../../elements';
import { StatusSection, ResponseDate, Reasons, paymentProcessor, Type } from "../../../utils/disputeUtils";
import { getItem, removeItem, setItem } from '../../../utils/localStorage';

import styles from './style.module.scss';

interface StatusProps {
    title: string,
    color: string,
    status: boolean,
}

interface ReasonsProps {
    title: string,
    status: boolean,
}
interface DisputeFilterType {
    setDateFilterCheck?:any;
}


const DisputeFilter: FC<DisputeFilterType> = ({setDateFilterCheck}) => {
    const dispatch = useDispatch();
    const [statusFilter, setStatusFilter] = useState<StatusProps[]>(StatusSection);
    const [responseDateFilter, setResponseDateFilter] = useState<ReasonsProps[]>(ResponseDate);
    const [reasonsFilter, setReasonsFilter] = useState<ReasonsProps[]>(Reasons);
    const [paymentProcessorFilter, setPaymentProcessorFilter] = useState(paymentProcessor);
    const [typeFilter, setTypeFilter] = useState<ReasonsProps[]>(Type);
    const [filterCheck, setFilterCheck] = useState(true);

    const currentPage = getItem("disputePage");
    const disputePageLength = getItem("disputePageLength");

    const handleStatus = (data: any, index: any) => {
        const dataList = cloneDeep(statusFilter);
        data.status = !data.status;
        dataList[index] = data;
        setStatusFilter(dataList);
    }

    const handleReason = (data: any, index: any) => {
        const dataList = cloneDeep(reasonsFilter);
        data.status = !data.status;
        dataList[index] = data;
        setReasonsFilter(dataList);
    }

    const handleDate = (data: any, index: any) => {
        const dataList = cloneDeep(responseDateFilter);
        data.status = !data.status;
        dataList[index] = data;
        setResponseDateFilter(dataList);
    }

    const handlePayment = (data: any, index: any) => {
        const dataList = cloneDeep(paymentProcessorFilter);
        data.status = !data.status;
        dataList[index] = data;
        setPaymentProcessorFilter(dataList);
    }

    const handleType = (data: any, index: any) => {
        const dataList = cloneDeep(typeFilter);
        data.status = !data.status;
        dataList[index] = data;
        setTypeFilter(dataList);
    }

    const handleFiltering = () => {
        //FOR-STATUS
        const statusArr = statusFilter.filter((statusData) => statusData.status);
        const statusFilterArr = statusArr.map((item) => {
            return item?.title.toUpperCase();
        });

        //FOR-RESPONSE-DATE-PAYLOAD
        const responseDateArr = responseDateFilter.filter((filterData) => filterData.status);
        let oneDay = false;
        let oneWeek = false;
        let oneMonth = false;
        responseDateArr.forEach((item) => {
            if (item?.title === "1 day") {
                oneDay = true;
            } else if (item?.title === "1 week") {
                oneWeek = true;
            } else if (item?.title === "1 month") {
                oneMonth = true;
            }
        });

        //FOR-REASONS
        const reasonsArr = reasonsFilter.filter((filterData) => filterData.status);
        const reasonFilter = reasonsArr.map((item) => {
            return item?.title.toUpperCase();
        });

        //FOR-PAYMENT-PROCESSOR-PAYLOAD
        const paymentArr = paymentProcessorFilter.filter((filterData) => filterData.status);
        const paymentFilter = paymentArr.map((item) => {
            return item?.processorName.toUpperCase();
        });

        //FOR-TYPE
        const typeArr = typeFilter.filter((filterData) => filterData.status);
        const typeFilterArr = typeArr.map((item) => {
            return item?.title.toUpperCase();
        });
        const payload = {
            "page": Number(currentPage),
            "pageLength": Number(disputePageLength),
            "statuses": statusFilterArr.length ? statusFilterArr : null,
            "upto3": false,
            "upto7": false,
            "above7": false,
            "reasons": reasonFilter.length ? reasonFilter : null,
            "stage": typeFilterArr?.length ? typeFilterArr : null,
            "stores": null,
            "paymentProcessor": paymentFilter?.length ? paymentFilter : null,
            oneDay,
            oneWeek,
            oneMonth
        }
        setItem("filteringDispute", true);
        setItem("filterPayload", JSON.stringify(payload));
        dispatch(postFilteredDisputesRequest(payload));
    }

    const handleFilterReset = () => {
        setStatusFilter(StatusSection);
        setReasonsFilter(Reasons);
        setTypeFilter(Type);

        setResponseDateFilter(ResponseDate);
        setPaymentProcessorFilter(paymentProcessor);
        // setStoresFilter(data);
        setDateFilterCheck(false);
        removeItem("filteringDispute");
        removeItem("filterPayload");

        const payload = {
            page: 1,
            pageLength: Number(disputePageLength)
        }
        dispatch(getDisputesListRequest(payload));
    }

    useEffect(() => {
        let checkFilter;
        checkFilter = statusFilter.filter((status) => status.status);
        checkFilter.length === 0 && (checkFilter = reasonsFilter.filter((reason) => reason.status))
        checkFilter.length === 0 && (checkFilter = paymentProcessorFilter.filter((payment) => payment.status))
        checkFilter.length === 0 && (checkFilter = responseDateFilter.filter((date) => date.status))
        checkFilter.length === 0 && (checkFilter = typeFilter.filter((date) => date.status))
        if (checkFilter.length > 0) {
            setFilterCheck(false);
        } else {
            setFilterCheck(true);
        }
    }, [statusFilter, reasonsFilter, paymentProcessorFilter, responseDateFilter, typeFilter]);

    return (
        <>
            <div className={styles.filtersDrop}>
                <div className={styles.filtersDropOptions}>
                    <div className={styles.check}>
                        <div className={styles.filtersDropTitle}>Status</div>
                        {statusFilter.map((item, index) => {
                            return (
                                <div className={styles.checkbox} key={index}>
                                    <input id={item.title}
                                        checked={item.status}
                                        type="checkbox" name={item.title}
                                        onChange={() => handleStatus({ ...item }, index)}
                                    />
                                    <div style={{ background: item.color }} />
                                    <label htmlFor={item.title}>{item.title.split('_').join(' ')}</label>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.check}>
                        <div className={styles.filtersDropTitle}>Response Date</div>
                        {responseDateFilter.map((response, index) => {
                            return (
                                <div className={styles.checkbox} key={index} onClick={() => handleDate({ ...response }, index)}>
                                    <input type="checkbox" name={response.title} checked={response.status} readOnly />
                                    <label htmlFor={response.title}>{response.title}</label>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.check}>
                        <div className={styles.reasonFilterTitle}>Reasons</div>
                        <div className={styles.reasonFilterOptions}>
                            {reasonsFilter.map((reason, index) => {
                                return (
                                    <Tooltip title={reason.title.split('_').join(' ')} arrow key={index} placement="top-end">
                                        <div className={styles.checkbox} onClick={() => handleReason({ ...reason }, index)}>
                                            <input type="checkbox"
                                                name={reason.title}
                                                checked={reason.status}
                                                readOnly
                                            />
                                            <label htmlFor={reason.title}>{reason.title.split('_').join(' ')}</label>
                                        </div>
                                    </Tooltip>
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.check}>
                        <div className={styles.filtersDropTitle}>Payment Processor</div>
                        {paymentProcessorFilter.map((gateWay, index) => {
                            return (
                                <div className={styles.checkbox} key={index} onClick={() => handlePayment({ ...gateWay }, index)}>
                                    <input type="checkbox" name={gateWay.processorName} checked={gateWay.status} readOnly />
                                    <label htmlFor={gateWay.processorName}>
                                        {gateWay.processorName}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.check}>
                        <div className={styles.filtersDropTitle}>Type</div>
                        {typeFilter.map((item, index) => {
                            return (
                                <div className={styles.checkbox} key={index} onClick={() => handleType({ ...item }, index)}>
                                    <input type="checkbox"
                                        name={item?.title}
                                        checked={item?.status}
                                        readOnly
                                    />
                                    <label htmlFor={item.title}>{item.title.split('_').join(' ')}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.searchButtons}>
                <Button
                    variant="contained"
                    className={styles.buttonApply}
                    onClick={handleFiltering}
                    disabled={filterCheck}
                    data-testid="apply-filter"
                >
                    <img src={svgIcons.CheckTwo} alt="check" /> Apply Filters
                </Button>
                <button className={styles.buttonReset} onClick={handleFilterReset} data-testid="reset-filter">
                    Reset
                </button>
            </div>
        </>
    );
}

export default DisputeFilter;
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDisputeOrderSummaryRequest } from "../../../redux/actions";
import { svgIcons } from "../../../elements";

import OrderSummary from "./orderSummary";
import TransactionDetails from "./transactionDetails";
import Evidence from "./evidence";
import FraudAnalysis from "./fraudAnalysis";

import styles from "./style.module.scss";
import { getIconByShopType } from "../../../utils/storeIconsUtlis";
import OrderStatus from "./orderStatus";
import CustomerInformation from "./customerInformation";
import TrackingJourney from "./trackingJourney";
import OrderFulfillment from "./orderFulfillment";

export default function DisputeAccordion() {
  const dispatch = useDispatch();
  const { disputeId } = useParams();

  const { data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  const disputeOrderSummary = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeOrderSummary
  );

  React.useEffect(() => {
    if (disputeId) {
      dispatch(getDisputeOrderSummaryRequest(disputeId));
    }
  }, [disputeId, dispatch]);

  return (
    <div>
      {!disputeOrderSummary?.isError &&
        disputeOrderSummary?.isSuccess &&
        disputeOrderSummary?.data?.length > 0 && (
          <Accordion
            defaultExpanded
            style={{ borderRadius: "12px 12px 0 0" }}
            className={styles.accordianWrapper}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={styles.accordianSummary}
              style={{ borderRadius: "12px 12px 0 0" }}
            >
              <Typography className={styles.accordianTitle}>
                {data?.shopType ? (
                  <img
                    src={getIconByShopType(data?.shopType)?.icon}
                    alt="shopType"
                  />
                ) : (
                  data?.paymentProcessor && (
                    <img
                      src={getIconByShopType(data?.paymentProcessor)?.icon}
                      alt="paymentProcessor"
                    />
                  )
                )}
                <p>Order Summary</p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <OrderSummary />
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

      <Accordion className={styles.accordianWrapper} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <img src={svgIcons.OrderFulfillment} alt="order_fulfillment" />
            <p>Order Fulfillment</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <OrderFulfillment />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={styles.accordianWrapper} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <img src={svgIcons.OrderStatus} alt="order_fulfillment" />
            <p>Order Status Page</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <OrderStatus />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <img src={svgIcons.CustomerInformation} alt="order_fulfillment" />
            <p>Customer Information</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <CustomerInformation />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <img src={svgIcons.OrderFulfillment} alt="order_fulfillment" />
            <p>Full Tracking Journey</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <TrackingJourney />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <img src={svgIcons.Bank} alt="order_fulfillment" />
            <p>Transaction Details</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <TransactionDetails />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className={styles.accordianWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={styles.accordianSummary}
        >
          <Typography className={styles.accordianTitle}>
            <img src={svgIcons.Coins} alt="order_fulfillment" />
            <p>Evidence</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordianDetails}>
          <Typography>
            <Evidence />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          borderRadius: "0 0 12px 12px",
        }}
        className={styles.accordianWrapper}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={styles.accordianSummary}
          style={{ borderRadius: "0 0 12px 12px" }}
        >
          <Typography className={styles.accordianTitle}>
            <img src={svgIcons.ChartLineUp} alt="order_fulfillment" />
            <p>Fraud Analysis</p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ borderRadius: "0 0 12px 12px" }}>
          <Typography>
            <FraudAnalysis />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

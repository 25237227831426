import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";


const initialState = {
    shopifyHmac: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopifyCreateCharge: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopifyCreateSubscription: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },

    shopifyActivateCharge: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {},
    },
}

//shopify-hmac
const getShopifyHmacRequest = (state: any, action: any) => {
    return update(state, {
        shopifyHmac: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getShopifyHmacSuccess = (state: any, action: any) => {
    return update(state, {
        shopifyHmac: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getShopifyHmacError = (state: any, action: any) => {
    return update(state, {
        shopifyHmac: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shopify-create-charge
const postShopifyCreateChargeRequest = (state: any, action: any) => {
    return update(state, {
        shopifyCreateCharge: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postShopifyCreateChargeSuccess = (state: any, action: any) => {
    return update(state, {
        shopifyCreateCharge: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postShopifyCreateChargeError = (state: any, action: any) => {
    return update(state, {
        shopifyCreateCharge: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shopify-create-subscription
const postShopifyCreateSubscriptionRequest = (state: any, action: any) => {
    return update(state, {
        shopifyCreateSubscription: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postShopifyCreateSubscriptionSuccess = (state: any, action: any) => {
    return update(state, {
        shopifyCreateSubscription: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postShopifyCreateSubscriptionError = (state: any, action: any) => {
    return update(state, {
        shopifyCreateSubscription: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//shopify-activate-subscription
const postShopifyActivateChargeRequest = (state: any, action: any) => {
    return update(state, {
        shopifyActivateCharge: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postShopifyActivateChargeSuccess = (state: any, action: any) => {
    return update(state, {
        shopifyActivateCharge: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postShopifyActivateChargeError = (state: any, action: any) => {
    return update(state, {
        shopifyActivateCharge: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.GET_SHOPIFY_HMAC_REQUEST]: getShopifyHmacRequest,
        [constants.GET_SHOPIFY_HMAC_SUCCESS]: getShopifyHmacSuccess,
        [constants.GET_SHOPIFY_HMAC_ERROR]: getShopifyHmacError,

        [constants.POST_SHOPIFY_CREATE_CHARGE_REQUEST]: postShopifyCreateChargeRequest,
        [constants.POST_SHOPIFY_CREATE_CHARGE_SUCCESS]: postShopifyCreateChargeSuccess,
        [constants.POST_SHOPIFY_CREATE_CHARGE_ERROR]: postShopifyCreateChargeError,

        [constants.POST_SHOPIFY_CREATE_SUBSCRIPTION_REQUEST]: postShopifyCreateSubscriptionRequest,
        [constants.POST_SHOPIFY_CREATE_SUBSCRIPTION_SUCCESS]: postShopifyCreateSubscriptionSuccess,
        [constants.POST_SHOPIFY_CREATE_SUBSCRIPTION_ERROR]: postShopifyCreateSubscriptionError,

        [constants.POST_SHOPIFY_ACTIVATE_CHARGE_REQUEST]: postShopifyActivateChargeRequest,
        [constants.POST_SHOPIFY_ACTIVATE_CHARGE_SUCCESS]: postShopifyActivateChargeSuccess,
        [constants.POST_SHOPIFY_ACTIVATE_CHARGE_ERROR]: postShopifyActivateChargeError,
    },
    initialState
);
import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class PostStripeUpdateBusinessProfile {
  /**
   * Function to invoke to stripe
   */
  async invoke(payload: any) {

    const res = await ApiService.getInstance().post(apiConstants.POST_STRIPE_UPDATE_BUSINESS_PROFILE, payload)

    //save access token from res header

    return res;
  }

}
export const POST_CREATE_USER_REQUEST = "POST_CREATE_USER_REQUEST";
export const POST_CREATE_USER_SUCCESS = "POST_CREATE_USER_SUCCESS";
export const POST_CREATE_USER_ERROR = "POST_CREATE_USER_ERROR";

//paypal
export const POST_CONNECT_PAYPAL_REQUEST = "POST_CONNECT_PAYPAL_REQUEST";
export const POST_CONNECT_PAYPAL_SUCCESS = "POST_CONNECT_PAYPAL_SUCCESS";
export const POST_CONNECT_PAYPAL_ERROR = "POST_CONNECT_PAYPAL_ERROR";

export const GET_CONNECTED_PAYPAL_LIST_REQUEST = "GET_CONNECTED_PAYPAL_LIST_REQUEST";
export const GET_CONNECTED_PAYPAL_LIST_SUCCESS = "GET_CONNECTED_PAYPAL_LIST_SUCCESS";
export const GET_CONNECTED_PAYPAL_LIST_ERROR = "GET_CONNECTED_PAYPAL_LIST_ERROR";

export const DELETE_PAYPAL_REQUEST = "DELETE_PAYPAL_REQUEST";
export const DELETE_PAYPAL_SUCCESS = "DELETE_PAYPAL_SUCCESS";
export const DELETE_PAYPAL_ERROR = "DELETE_PAYPAL_ERROR";


export const GET_TOTAL_REVENUE_PROTECTED_REQUEST = "GET_TOTAL_REVENUE_PROTECTED_REQUEST";
export const GET_TOTAL_REVENUE_PROTECTED_SUCCESS = "GET_TOTAL_REVENUE_PROTECTED_SUCCESS";
export const GET_TOTAL_REVENUE_PROTECTED_ERROR = "GET_TOTAL_REVENUE_PROTECTED_ERROR";

export const GET_LINKED_SHOPS_REQUEST = "GET_LINKED_SHOPS_REQUEST";
export const GET_LINKED_SHOPS_SUCCESS = "GET_LINKED_SHOPS_SUCCESS";
export const GET_LINKED_SHOPS_ERROR = "GET_LINKED_SHOPS_ERROR";

export const DELETE_LINKED_SHOPS_REQUEST = "DELETE_LINKED_SHOPS_REQUEST";
export const DELETE_LINKED_SHOPS_SUCCESS = "DELETE_LINKED_SHOPS_SUCCESS";
export const DELETE_LINKED_SHOPS_ERROR = "DELETE_LINKED_SHOPS_ERROR";

export const GET_LINKED_PAYPAL_REQUEST = "GET_LINKED_PAYPAL_REQUEST";
export const GET_LINKED_PAYPAL_SUCCESS = "GET_LINKED_PAYPAL_SUCCESS";
export const GET_LINKED_PAYPAL_ERROR = "GET_LINKED_PAYPAL_ERROR";

export const GET_RECENT_DISPUTES_REQUEST = "GET_RECENT_DISPUTES_REQUEST";
export const GET_RECENT_DISPUTES_SUCCESS = "GET_RECENT_DISPUTES_SUCCESS";
export const GET_RECENT_DISPUTES_ERROR = "GET_RECENT_DISPUTES_ERROR";

export const GET_OPEN_DISPUTES_REQUEST = "GET_OPEN_DISPUTES_REQUEST";
export const GET_OPEN_DISPUTES_SUCCESS = "GET_OPEN_DISPUTES_SUCCESS";
export const GET_OPEN_DISPUTES_ERROR = "GET_OPEN_DISPUTES_ERROR";

export const GET_RECOVERED_DISPUTES_REQUEST = "GET_RECOVERED_DISPUTES_REQUEST";
export const GET_RECOVERED_DISPUTES_SUCCESS = "GET_RECOVERED_DISPUTES_SUCCESS";
export const GET_RECOVERED_DISPUTES_ERROR = "GET_RECOVERED_DISPUTES_ERROR";

export const GET_CURRENT_WEEK_DISPUTES_REQUEST = "GET_CURRENT_WEEK_DISPUTES_REQUEST";
export const GET_CURRENT_WEEK_DISPUTES_SUCCESS = "GET_CURRENT_WEEK_DISPUTES_SUCCESS";
export const GET_CURRENT_WEEK_DISPUTES_ERROR = "GET_CURRENT_WEEK_DISPUTES_ERROR";

export const GET_CURRENT_MONTH_DISPUTES_REQUEST = "GET_CURRENT_MONTH_DISPUTES_REQUEST";
export const GET_CURRENT_MONTH_DISPUTES_SUCCESS = "GET_CURRENT_MONTH_DISPUTES_SUCCESS";
export const GET_CURRENT_MONTH_DISPUTES_ERROR = "GET_CURRENT_MONTH_DISPUTES_ERROR";

export const GET_DISPUTES_LIST_REQUEST = "GET_DISPUTES_LIST_REQUEST";
export const GET_DISPUTES_LIST_SUCCESS = "GET_DISPUTES_LIST_SUCCESS";
export const GET_DISPUTES_LIST_ERROR = "GET_DISPUTES_LIST_ERROR";

export const POST_FILTERED_DISPUTES_REQUEST = "POST_FILTERED_DISPUTES_REQUEST";
export const POST_FILTERED_DISPUTES_SUCCESS = "POST_FILTERED_DISPUTES_SUCCESS";
export const POST_FILTERED_DISPUTES_ERROR = "POST_FILTERED_DISPUTES_ERROR";

export const GET_PROCESSING_DISPUTES_REQUEST = "GET_PROCESSING_DISPUTES_REQUEST";
export const GET_PROCESSING_DISPUTES_SUCCESS = "GET_PROCESSING_DISPUTES_SUCCESS";
export const GET_PROCESSING_DISPUTES_ERROR = "GET_PROCESSING_DISPUTES_ERROR";

export const GET_AWAITING_RESPONSE_DISPUTES_REQUEST = "GET_AWAITING_RESPONSE_DISPUTES_REQUEST";
export const GET_AWAITING_RESPONSE_DISPUTES_SUCCESS = "GET_AWAITING_RESPONSE_DISPUTES_SUCCESS";
export const GET_AWAITING_RESPONSE_DISPUTES_ERROR = "GET_AWAITING_RESPONSE_DISPUTES_ERROR";

//dispute-details
export const GET_DISPUTE_DETAILS_REQUEST = "GET_DISPUTE_DETAILS_REQUEST";
export const GET_DISPUTE_DETAILS_SUCCESS = "GET_DISPUTE_DETAILS_SUCCESS";
export const GET_DISPUTE_DETAILS_ERROR = "GET_DISPUTE_DETAILS_ERROR";

export const GET_DISPUTE_TRANSACTION_DETAILS_REQUEST = "GET_DISPUTE_TRANSACTION_DETAILS_REQUEST";
export const GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS = "GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS";
export const GET_DISPUTE_TRANSACTION_DETAILS_ERROR = "GET_DISPUTE_TRANSACTION_DETAILS_ERROR";

export const GET_DISPUTE_EVIDENCE_DETAILS_REQUEST = "GET_DISPUTE_EVIDENCE_DETAILS_REQUEST";
export const GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS = "GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS";
export const GET_DISPUTE_EVIDENCE_DETAILS_ERROR = "GET_DISPUTE_EVIDENCE_DETAILS_ERROR";

export const GET_DISPUTE_MESSAGE_DETAILS_REQUEST = "GET_DISPUTE_MESSAGE_DETAILS_REQUEST";
export const GET_DISPUTE_MESSAGE_DETAILS_SUCCESS = "GET_DISPUTE_MESSAGE_DETAILS_SUCCESS";
export const GET_DISPUTE_MESSAGE_DETAILS_ERROR = "GET_DISPUTE_MESSAGE_DETAILS_ERROR";

export const GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST = "GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST";
export const GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS = "GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS";
export const GET_DISPUTE_ADJUDICATION_DETAILS_ERROR = "GET_DISPUTE_ADJUDICATION_DETAILS_ERROR";

export const GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST = "GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST";
export const GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS = "GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS";
export const GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR = "GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR";

export const GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST = "GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST";
export const GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS = "GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS";
export const GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR = "GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR";

export const GET_DISPUTE_OFFER_DETAILS_REQUEST = "GET_DISPUTE_OFFER_DETAILS_REQUEST";
export const GET_DISPUTE_OFFER_DETAILS_SUCCESS = "GET_DISPUTE_OFFER_DETAILS_SUCCESS";
export const GET_DISPUTE_OFFER_DETAILS_ERROR = "GET_DISPUTE_OFFER_DETAILS_ERROR";

export const GET_DISPUTE_ORDER_SUMMARY_REQUEST = "GET_DISPUTE_ORDER_SUMMARY_REQUEST";
export const GET_DISPUTE_ORDER_SUMMARY_SUCCESS = "GET_DISPUTE_ORDER_SUMMARY_SUCCESS";
export const GET_DISPUTE_ORDER_SUMMARY_ERROR = "GET_DISPUTE_ORDER_SUMMARY_ERROR";

export const POST_ADD_SUPPORTING_EVIDENCE_REQUEST = "POST_ADD_SUPPORTING_EVIDENCE_REQUEST";
export const POST_ADD_SUPPORTING_EVIDENCE_SUCCESS = "POST_ADD_SUPPORTING_EVIDENCE_SUCCESS";
export const POST_ADD_SUPPORTING_EVIDENCE_ERROR = "POST_ADD_SUPPORTING_EVIDENCE_ERROR";

export const GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST = "GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST"
export const GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS = "GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS"
export const GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR = "GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR"

export const GET_DISPUTE_CUSTOMER_DETAILS_REQUEST = "GET_DISPUTE_CUSTOMER_DETAILS_REQUEST";
export const GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS = "GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS";
export const GET_DISPUTE_CUSTOMER_DETAILS_ERROR = "GET_DISPUTE_CUSTOMER_DETAILS_ERROR";

export const GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_REQUEST = "GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_REQUEST"
export const GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_SUCCESS = "GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_SUCCESS"
export const GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_ERROR = "GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_ERROR"

export const GET_DISPUTE_TRACKING_DETAILS_REQUEST = "GET_DISPUTE_TRACKING_DETAILS_REQUEST"
export const GET_DISPUTE_TRACKING_DETAILS_SUCCESS = "GET_DISPUTE_TRACKING_DETAILS_SUCCESS"
export const GET_DISPUTE_TRACKING_DETAILS_ERROR = "GET_DISPUTE_TRACKING_DETAILS_ERROR"

//shops
export const POST_ADD_SHOPIFY_SHOP_REQUEST = "POST_ADD_SHOPIFY_SHOP_REQUEST";
export const POST_ADD_SHOPIFY_SHOP_SUCCESS = "POST_ADD_SHOPIFY_SHOP_SUCCESS";
export const POST_ADD_SHOPIFY_SHOP_ERROR = "POST_ADD_SHOPIFY_SHOP_ERROR";

export const GET_SHOPS_NOTIFICATIONS_REQUEST = "GET_SHOPS_NOTIFICATIONS_REQUEST";
export const GET_SHOPS_NOTIFICATIONS_SUCCESS = "GET_SHOPS_NOTIFICATIONS_SUCCESS";
export const GET_SHOPS_NOTIFICATIONS_ERROR = "GET_SHOPS_NOTIFICATIONS_ERROR";

export const POST_SHOPS_SAVE_NOTIFICATIONS_REQUEST = "POST_SHOPS_SAVE_NOTIFICATIONS_REQUEST";
export const POST_SHOPS_SAVE_NOTIFICATIONS_SUCCESS = "POST_SHOPS_SAVE_NOTIFICATIONS_SUCCESS";
export const POST_SHOPS_SAVE_NOTIFICATIONS_ERROR = "POST_SHOPS_SAVE_NOTIFICATIONS_ERROR";

export const POST_SHOPS_ADD_ONBOARDED_REQUEST = "POST_SHOPS_ADD_ONBOARDED_REQUEST";
export const POST_SHOPS_ADD_ONBOARDED_SUCCESS = "POST_SHOPS_ADD_ONBOARDED_SUCCESS";
export const POST_SHOPS_ADD_ONBOARDED_ERROR = "POST_SHOPS_ADD_ONBOARDED_ERROR";

export const POST_SHOPS_ADD_RISK_REQUEST = "POST_SHOPS_ADD_RISK_REQUEST";
export const POST_SHOPS_ADD_RISK_SUCCESS = "POST_SHOPS_ADD_RISK_SUCCESS";
export const POST_SHOPS_ADD_RISK_ERROR = "POST_SHOPS_ADD_RISK_ERROR";

export const GET_SHOPS_DATA_REQUEST = "GET_SHOPS_DATA_REQUEST";
export const GET_SHOPS_DATA_SUCCESS = "GET_SHOPS_DATA_SUCCESS";
export const GET_SHOPS_DATA_ERROR = "GET_SHOPS_DATA_ERROR";

//shopify-controller
export const GET_SHOPIFY_HMAC_REQUEST = "GET_SHOPIFY_HMAC_REQUEST";
export const GET_SHOPIFY_HMAC_SUCCESS = "GET_SHOPIFY_HMAC_SUCCESS";
export const GET_SHOPIFY_HMAC_ERROR = "GET_SHOPIFY_HMAC_ERROR";

export const POST_SHOPIFY_CREATE_CHARGE_REQUEST = "POST_SHOPIFY_CREATE_CHARGE_REQUEST";
export const POST_SHOPIFY_CREATE_CHARGE_SUCCESS = "POST_SHOPIFY_CREATE_CHARGE_SUCCESS";
export const POST_SHOPIFY_CREATE_CHARGE_ERROR = "POST_SHOPIFY_CREATE_CHARGE_ERROR";

export const POST_SHOPIFY_CREATE_SUBSCRIPTION_REQUEST = "POST_SHOPIFY_CREATE_SUBSCRIPTION_REQUEST";
export const POST_SHOPIFY_CREATE_SUBSCRIPTION_SUCCESS = "POST_SHOPIFY_CREATE_SUBSCRIPTION_SUCCESS";
export const POST_SHOPIFY_CREATE_SUBSCRIPTION_ERROR = "POST_SHOPIFY_CREATE_SUBSCRIPTION_ERROR";

export const POST_SHOPIFY_ACTIVATE_CHARGE_REQUEST = "POST_SHOPIFY_ACTIVATE_CHARGE_REQUEST";
export const POST_SHOPIFY_ACTIVATE_CHARGE_SUCCESS = "POST_SHOPIFY_ACTIVATE_CHARGE_SUCCESS";
export const POST_SHOPIFY_ACTIVATE_CHARGE_ERROR = "POST_SHOPIFY_ACTIVATE_CHARGE_ERROR";

//notification
export const GET_NOTIFICATIONS_LIST_REQUEST = "GET_NOTIFICATIONS_LIST_REQUEST";
export const GET_NOTIFICATIONS_LIST_SUCCESS = "GET_NOTIFICATIONS_LIST_SUCCESS";
export const GET_NOTIFICATIONS_LIST_ERROR = "GET_NOTIFICATIONS_LIST_ERROR";

//Briantree
export const POST_BRAINTREE_SETTINGS_REQUEST = "POST_BRAINTREE_SETTINGS_REQUEST";
export const POST_BRAINTREE_SETTINGS_SUCCESS = "POST_BRAINTREE_SETTINGS_SUCCESS";
export const POST_BRAINTREE_SETTINGS_ERROR = "POST_BRAINTREE_SETTINGS_ERROR";

export const GET_BRAINTREE_LIST_REQUEST = "GET_BRAINTREE_LIST_REQUEST";
export const GET_BRAINTREE_LIST_SUCCESS = "GET_BRAINTREE_LIST_SUCCESS";
export const GET_BRAINTREE_LIST_ERROR = "GET_BRAINTREE_LIST_ERROR";

//Klarna
export const POST_KLARNA_SETTINGS_REQUEST = "POST_KLARNA_SETTINGS_REQUEST";
export const POST_KLARNA_SETTINGS_SUCCESS = "POST_KLARNA_SETTINGS_SUCCESS";
export const POST_KLARNA_SETTINGS_ERROR = "POST_KLARNA_SETTINGS_ERROR";

export const GET_KLARNA_LIST_REQUEST = "GET_BRAINTREE_LIST_REQUEST";
export const GET_KLARNA_LIST_SUCCESS = "GET_BRAINTREE_LIST_SUCCESS";
export const GET_KLARNA_LIST_ERROR = "GET_BRAINTREE_LIST_ERROR";

//List-PSP
export const GET_LIST_PSP_LIST_REQUEST = "GET_LIST_PSP_LIST_REQUEST";
export const GET_LIST_PSP_LIST_SUCCESS = "GET_LIST_PSP_LIST_SUCCESS";
export const GET_LIST_PSP_LIST_ERROR = "GET_LIST_PSP_LIST_ERROR";

//stripe-integartion
export const POST_STRIPE_LOGIN_REQUEST = "POST_STRIPE_LOGIN_REQUEST";
export const POST_STRIPE_LOGIN_SUCCESS = "POST_STRIPE_LOGIN_SUCCESS";
export const POST_STRIPE_LOGIN_ERROR = "POST_STRIPE_LOGIN_ERROR";

//stripe-onboard-integartion
export const POST_STRIPE_ONBOARD_LOGIN_REQUEST = "POST_STRIPE_ONBOARD_LOGIN_REQUEST";
export const POST_STRIPE_ONBOARD_LOGIN_SUCCESS = "POST_STRIPE_ONBOARD_LOGIN_SUCCESS";
export const POST_STRIPE_ONBOARD_LOGIN_ERROR = "POST_STRIPE_ONBOARD_LOGIN_ERROR";

//stripe-update-business-profile
export const POST_STRIPE_UPDATE_BUSINESS_PROFILE_REQUEST = "POST_STRIPE_UPDATE_BUSINESS_PROFILE_REQUEST";
export const POST_STRIPE_UPDATE_BUSINESS_PROFILE_SUCCESS = "POST_STRIPE_UPDATE_BUSINESS_PROFILE_SUCCESS";
export const POST_STRIPE_UPDATE_BUSINESS_PROFILE_ERROR = "POST_STRIPE_UPDATE_BUSINESS_PROFILE_ERROR";
import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";


const initialState = {
    listPspList: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
}

const getListPspListRequest = (state: any, action: any) => {
    return update(state, {
        listPspList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getListPspListSuccess = (state: any, action: any) => {
    return update(state, {
        listPspList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getListPspListError = (state: any, action: any) => {
    return update(state, {
        listPspList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.GET_LIST_PSP_LIST_REQUEST]: getListPspListRequest,
        [constants.GET_LIST_PSP_LIST_SUCCESS]: getListPspListSuccess,
        [constants.GET_LIST_PSP_LIST_ERROR]: getListPspListError,
    },
    initialState
);
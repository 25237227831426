import { Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { postShopifyCreateSubscriptionRequest } from "../../redux/actions";
import Loading from "../loading/loading";
import Modal from "../modal/Modal";

import styles from "./style.module.scss";

interface activateProps {
    isOpen: boolean;
    onClose: () => void;
    skip: () => void;
}

const activateIdea = [
    {
        id: 0,
        title: "TODAY",
        description: " -> Get started with ChargePay for free."
    },
    {
        id: 1,
        title: "IN THE COMING MONTHS",
        description: " -> We gather your disputes and start handling them."
    },
    {
        id: 2,
        title: "WHEN DISPUTES ARE SETTLED",
        description: " -> You pay 20% only if the dispute ",
        bold: "resolved in your favor."
    },
]

const ActivateYourAccount = ({ isOpen, onClose, skip }: activateProps) => {
    const dispatch = useDispatch();

    const { isLoading, isSuccess, isError, data } = useSelector((state: any) => state?.shopifyController?.shopifyCreateSubscription)

    const handleActivation = () => {
        dispatch(postShopifyCreateSubscriptionRequest())
    }

    useEffect(() => {
        if (isSuccess && data?.confirmationUrl) {
            window.location.replace(data?.confirmationUrl);
        } else if (isError) {
            toast.error('Oops! Subscription fail. Please try again', {
                theme: 'colored'
            });
        }
    }, [isSuccess, isError, data])

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
            <div className={styles.activateAccountModal}>
                <ToastContainer />
                <div className={styles.activateAccountWrapper}>
                    <div className={styles.activateAccountDetails}>
                        <div className={styles.activateAccountHeader}>
                            <h4>Activate Your Account</h4>
                            <p>
                                ChargePay automates dispute resolution and only charges for successful outcomes. Approve the billing cap on Shopify to use our service.
                            </p>
                        </div>
                        <div className={styles.activateAccountBody}>
                            <h6>Here’s what you can expect:</h6>
                            <div className={styles.activateAccountExpectWrapper}>
                                {activateIdea?.map((steps) => {
                                    return (
                                        <div key={steps?.id} className={styles.activateAccountExpect}>
                                            <div className={styles.activateExpectTitle}>
                                                <p>{steps?.title}</p>
                                            </div>
                                            <div>
                                                <p>{steps?.description}{steps?.bold && <span>{steps?.bold}</span>} </p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={styles.activateAccountFooter}>
                        <Button variant="contained" onClick={handleActivation}>
                            {isLoading ? <Loading /> : "Activate My Account"}
                        </Button>
                        <Button variant="text" className={styles.skip} onClick={skip}>
                            Skip for now
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ActivateYourAccount;
import cn from "classnames";
import React from "react";
import styles from "./style.module.scss";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

export default function Modal({
  isOpen,
  onClose,
  children,
  className,
}: PopupProps) {
  const handleOuterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.target === e.currentTarget && onClose();
  };

  return (
    <div
      data-testid="modal"
      className={cn(styles.popupWrapper, { [styles.isOpen]: isOpen })}
      onClick={handleOuterClick}
    >
      <div className={cn(styles.popup, className)}>
        {children}
      </div>
    </div>
  );
}
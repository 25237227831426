import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDisputeTransactionDetailsRequest } from "../../../../redux/actions";
import styles from "./style.module.scss";
import { Box, Paper, Tooltip } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";

const TransactionDetails = () => {
  const dispatch = useDispatch();
  const { disputeId } = useParams();

  const { isSuccess, data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeTransactionDetails
  );
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeTransactionDetailsRequest(payload));
    }
  }, [dispatch, disputeId]);

  return (
    <div>
      {isSuccess && data?.length > 0 ? (
        <Box display={`flex`} flexDirection={`column`} gap={1}>
          {data.map((item: any) => (
            <Paper style={{ padding: "15px" }}>
              <>
                <div className={styles.transactionInfo}>
                  <div className={styles.transactionData}>
                    <p>Authorization Key</p>
                    <div className={styles.transactionValue}>
                      <Tooltip
                        arrow
                        placement="top"
                        title={item?.authorizationKey}
                      >
                        {item?.authorizationKey}
                      </Tooltip>
                    </div>
                  </div>
                  <div className={styles.transactionData}>
                    <p>Transaction Date</p>
                    <div className={styles.transactionValue}>
                      <Tooltip
                        arrow
                        placement="top"
                        title={`${
                          item?.createTime &&
                          dayjs(item?.createTime).format("Do MMMM YYYY")
                        }`}
                      >
                        {item?.createTime &&
                          dayjs(item?.createTime).format("Do MMMM YYYY")}
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className={styles.transactionInfo}>
                  <div className={styles.transactionData}>
                    <p>Brand</p>
                    <div className={styles.transactionValue}>{item?.brand}</div>
                  </div>
                  <div className={styles.transactionData}>
                    <p>BIN & Last 4</p>
                    <div className={styles.transactionValue}>{item?.last4}</div>
                  </div>
                </div>

                <div className={styles.transactionInfo}>
                  <div className={styles.transactionData}>
                    <p>Card Category</p>
                    <div className={styles.transactionValue}>
                      {item?.CardCategory}
                    </div>
                  </div>
                  <div className={styles.transactionData}>
                    <p>Gateway</p>
                    <div className={styles.gateway}></div>
                  </div>
                </div>

                <div className={styles.transactionInfo}>
                  <div className={styles.transactionData}>
                    <p>Charge Descriptor</p>
                    <div className={styles.transactionValue}>
                      {item?.ChargeDescriptor}
                    </div>
                  </div>
                  <div className={styles.transactionData}>
                    <p>Exp Date</p>
                    <div className={styles.transactionValue}>
                      {item?.creditCardExpMonth} / {item?.creditCardExpYear}
                    </div>
                  </div>
                </div>

                <div className={styles.transactionInfo}>
                  <div className={styles.transactionData}>
                    <p>Type of Card</p>
                    <div className={styles.transactionValue}>
                      {item?.creditCardCompany}
                    </div>
                  </div>
                  <div className={styles.transactionData}>
                    <p>Country</p>
                    <div className={styles.transactionValue}>
                      {item?.country}
                    </div>
                  </div>
                </div>

                <div className={styles.transactionInfo}>
                  <div className={styles.transactionData}>
                    <p>Reason Code</p>
                    <div className={styles.transactionValue}>
                      {item?.ReasonCode}
                    </div>
                  </div>
                </div>
              </>
            </Paper>
          ))}
        </Box>
      ) : (
        <div className={styles.evidenceWrapper}>
          <p>No Transaction Details avaiable</p>
        </div>
      )}
      {/* {isSuccess && data?.authorizationKey ? (
        <>
          <div className={styles.transactionInfo}>
            <div className={styles.transactionData}>
              <p>Authorization Key</p>
              <div className={styles.transactionValue}>
                {data?.authorizationKey}
              </div>
            </div>
            <div className={styles.transactionData}>
              <p>Transaction Date</p>
              <div className={styles.transactionValue}>
                {data?.transactionDate}
              </div>
            </div>
          </div>

          <div className={styles.transactionInfo}>
            <div className={styles.transactionData}>
              <p>Brand</p>
              <div className={styles.transactionValue}>{data?.brand}</div>
            </div>
            <div className={styles.transactionData}>
              <p>BIN & Last 4</p>
              <div className={styles.transactionValue}>{data?.BIN_Last4}</div>
            </div>
          </div>

          <div className={styles.transactionInfo}>
            <div className={styles.transactionData}>
              <p>Card Category</p>
              <div className={styles.transactionValue}>
                {data?.CardCategory}
              </div>
            </div>
            <div className={styles.transactionData}>
              <p>Gateway</p>
              <div className={styles.transactionValue}></div>
            </div>
          </div>

          <div className={styles.transactionInfo}>
            <div className={styles.transactionData}>
              <p>Charge Descriptor</p>
              <div className={styles.transactionValue}>
                {data?.ChargeDescriptor}
              </div>
            </div>
            <div className={styles.transactionData}>
              <p>Exp Date</p>
              <div className={styles.transactionValue}>{data?.ExpDate}</div>
            </div>
          </div>

          <div className={styles.transactionInfo}>
            <div className={styles.transactionData}>
              <p>Type of Card</p>
              <div className={styles.transactionValue}>{data?.TypeofCard}</div>
            </div>
            <div className={styles.transactionData}>
              <p>Country</p>
              <div className={styles.transactionValue}>{data?.Country}</div>
            </div>
          </div>

          <div className={styles.transactionInfo}>
            <div className={styles.transactionData}>
              <p>Reason Code</p>
              <div className={styles.transactionValue}>{data?.ReasonCode}</div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.evidenceWrapper}>
          <p>No Transaction Details avaiable</p>
        </div>
      )} */}
    </div>
  );
};

export default TransactionDetails;

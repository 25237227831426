import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getDisputesListRequest,
  postFilteredDisputesRequest,
} from "redux/actions";
import { Loading, Pagination, NoDataFound } from "../..";
import { svgIcons } from "elements";
import styles from "./style.module.scss";
import { getIconByShopType } from "utils/storeIconsUtlis";
import FormatCurrency from 'components/formatCurrency';
import { getColorByStatus } from 'utils/disputeUtils';
import {getItem, removeItem, setItem } from 'utils/localStorage';

const disputeListColumns = [
  "Dispute ID",
  "Store Name",
  "Date",
  "Amount",
  "Processor",
  "Reason",
  "Response Due",
  "Decision Date",
  "Status",
];

const DisputeList: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const tableList = 10;

  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state.disputesList.disputesList || {}
  );

  const filterSwitch = getItem("filteringDispute");

  const handleIdClick = (disputeData: any) => {
    navigate(`/dispute/${disputeData?.id}`);
  };

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    setItem("disputePage", page);
    setItem("disputePageLength", tableList);

    const filterPayload = getItem("filterPayload");
    const filterPayloadObj = filterPayload && JSON.parse(filterPayload);
    const updatedFilterPayload = {
      ...filterPayloadObj,
      page,
    };

    const payload =
      filterSwitch === "true" && updatedFilterPayload
        ? updatedFilterPayload
        : {
            page,
            pageLength: tableList,
          };

    filterSwitch === "true" && filterPayload
      ? dispatch(postFilteredDisputesRequest(payload))
      : dispatch(getDisputesListRequest(payload));
  }, [page, dispatch, filterSwitch]);

  useEffect(() => {
    return () => {
      removeItem("filteringDispute");
      removeItem("filterPayload");
      removeItem("disputePage");
      removeItem("disputePageLength");
    };
  }, []);

  const handleDisputeStatus = (disputeData: any) => {
    if (
      disputeData?.status &&
      disputeData?.status?.toLowerCase() === "resolved"
    ) {
      if (disputeData?.disputeOutcome === "RESOLVED_SELLER_FAVOUR") {
        return "Won";
      } else if (disputeData?.disputeOutcome === "RESOLVED_BUYER_FAVOUR") {
        return "Lost";
      } else {
        return "Resolved";
      }
    } else {
      return disputeData?.status && disputeData?.status?.split("_").join(" ");
    }
  };

  return (
    <div className={styles.tableWrapper}>
      {isLoading && <Loading />}
      {(!isLoading && isError) ||
      (isSuccess && data?.count === 0) ||
      (!isLoading && !data?.count) ? (
        <NoDataFound
          icon={svgIcons?.Coffee_Break}
          isError={isError}
          errorStatement="Oops! Something went wrong. Please try again."
          notFoundMessage="No dispute found"
        />
      ) : (
        isSuccess &&
        data?.count > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  {disputeListColumns.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?.disputeResponseList?.map((dispute: any) => (
                    <tr key={dispute.id} className={styles.disputeWrapper}>
                      <td
                        className={styles.id}
                        onClick={() => handleIdClick(dispute)}
                        data-testid="dispute-order"
                      >
                        {dispute?.id ?? "Missing Order"}
                      </td>
                      <td>{dispute?.shopName}</td>
                      <td>
                        {dispute?.createTime &&
                          moment(dispute?.createTime).format("MMMM DD, YYYY")}
                      </td>
                      <td>
                      <FormatCurrency
                     value={Number(dispute?.disputeAmountValue)?.toFixed(2)}
                     currencyCode={dispute?.disputeAmountCurrencyCode}
                    />
                        {/* {
                          getDisputeCurrency(dispute?.disputeAmountCurrencyCode)
                            ?.currencySign
                        }
                        {Number(dispute?.disputeAmountValue)
                          ?.toFixed(2)
                          ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} */}
                      </td>
                      <td className={styles.store}>
                        {dispute?.paymentProcessor && (
                          <img
                            src={
                              getIconByShopType(dispute?.paymentProcessor)?.icon
                            }
                            alt={`${dispute?.paymentProcessor}`}
                          />
                        )}
                        <div className={styles.storeText}>
                          <p className={styles.storeName}>
                            {dispute?.paymentProcessor}
                          </p>
                          <p className={styles.storeEmail}>
                            {dispute?.storeEmail}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.reasonType}>
                          <p>{dispute?.reason.split("_").join(" ")}</p>
                        </div>
                      </td>
                      <td>
                        {dispute?.sellerResponseDueDate &&
                          moment(dispute?.sellerResponseDueDate).format(
                            "MMMM DD, YYYY"
                          )}
                      </td>
                      <td>
                        {dispute?.decisionDate &&
                          moment(dispute?.decisionDate).format("MMMM DD, YYYY")}
                      </td>
                      <td>
                        <div
                          className={styles.status}
                          style={{
                            backgroundColor:
                              dispute?.status &&
                              getColorByStatus(handleDisputeStatus(dispute))
                                ?.backgroundColor,
                          }}
                        >
                          <p>
                            {dispute?.status && handleDisputeStatus(dispute)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {Math.ceil(data?.count / tableList) > 1 && (
              <Pagination
                onPageChange={onPageChange}
                page={page}
                totalPages={Math.ceil(data?.count / tableList)}
              />
            )}
          </>
        )
      )}
    </div>
  );
};
export default DisputeList;

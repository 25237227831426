import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@mui/material";
import cn from "classnames";
import { Search, DisputeList, DisputeFilter, Notifications } from "components";
import {
  postFilteredDisputesRequest,
  getDisputesListRequest,
} from "redux/actions";
import { svgIcons } from "elements";
import { StatusSection } from "utils/disputeUtils";
import { setItem, getItem } from "utils/localStorage";

import styles from "./style.module.scss";
import { cloneDeep } from "lodash";
import { useOpenDatePicker } from "hooks";
import { BreadcumbItem } from "components/ui";
import moment from "moment";

const Disputes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //custom hooks
  const { openDatePicker, datePickerRef } = useOpenDatePicker();
  const [page, setPage] = useState(1);
  const [disputesPayloadType, setDisputesPayloadType] = useState<string>("");
  const [dateFilterCheck, setDateFilterCheck] = useState(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [disputeStatusBtnCheck, setDisputeStatusBtnCheck] = useState(false);
  const [disputesPayload, setDisputesPayload] = useState<any>();
  const [disputeClick, setDisputeClick] = useState(false);
  const [disputeStatusButton, setDisputeStatusButton] = useState([
    ...StatusSection,
  ]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  function handleBreadcrumb(page: string) {
    navigate(page);
  }
  const handleSearch = (searchItem: string) => {
    setPage(1);
    setItem("disputePage", 1);
    const payload = {
      ...disputesPayload,
      page: 1,
      search: searchItem ? searchItem : null,
    };
    setDisputesPayload(payload);
    if (disputesPayloadType === "singleAccount") {
      const payload = {
        ...disputesPayload,
        search: searchItem ? searchItem : null,
      };
      setDisputesPayload(payload);
    } else if (disputesPayloadType === "disputeFilter") {
      const payload = {
        ...disputesPayload,
        search: searchItem ? searchItem : null,
      };
      setDisputesPayload(payload);
    } else if (disputesPayloadType === "manageNormal") {
      //handle the case where manageNormal case going on and user start searching and rube
      if (searchItem) {
        setDisputesPayloadType("disputeFilter");
        const payload = {
          page: Number(currentPage),
          pageLength: Number(disputePageLength),
          statuses: null,
          carriers: null,
          upto3: false,
          upto7: false,
          above7: false,
          reasons: null,
          stage: null,
          stores: null,
          paymentProcessor: null,
          oneDay: false,
          oneWeek: false,
          oneMonth: false,
          fromDate: null,
          toDate: null,
          search: searchItem ? searchItem : null,
        };
        setDisputesPayload(payload);
      } else {
        const payload = {
          page: Number(currentPage),
          pageLength: Number(disputePageLength),
        };
        setDisputesPayload(payload);
      }
    }
  };

  const handleDisputeStatus = (disputeStatus: any, index: number) => {
    const disputeBtn = cloneDeep(disputeStatusButton);
    disputeStatus.status = !disputeStatus.status;
    setDisputeStatusBtnCheck(!disputeStatusBtnCheck);
    setDisputeClick(true);
    disputeBtn[index] = disputeStatus;
    setDisputeStatusButton([...disputeBtn]);
  };

  const currentPage = getItem("disputePage");
  const disputePageLength = getItem("disputePageLength");
  //for date dispute filter
  useEffect(() => {
    setPage(1);
    setItem("disputePage", 1);

    if (dateFilterCheck && startDate && endDate) {
      const formattedStartDate = moment(startDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      const formattedEndDate = moment(endDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      let datePayload = {
        ...disputesPayload,
        page: 1,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      };
      setDisputesPayload(datePayload);
    } else if (dateFilterCheck && !startDate && !endDate) {
      let datePayload = {
        ...disputesPayload,
        page: 1,
        fromDate: null,
        toDate: null,
      };
      setDisputesPayload(datePayload);
    }
  }, [dateFilterCheck, startDate, endDate]);
  useEffect(() => {
    if (disputeClick) {
      const statusArr = disputeStatusButton.filter(
        (statusData) => statusData.status
      );
      const statusFilterArr = statusArr.map((item) => {
        return item?.title.toUpperCase();
      });
      const payload = statusFilterArr.length
        ? {
            page: Number(currentPage),
            pageLength: Number(disputePageLength),
            statuses: statusFilterArr,
            upto3: false,
            upto7: false,
            above7: false,
            reasons: null,
            stage: null,
            stores: null,
          }
        : {
            page: currentPage,
            pageLength: disputePageLength,
          };
      setItem("filteringDispute", true);
      setItem("filterPayload", JSON.stringify(payload));
      statusFilterArr.length
        ? dispatch(postFilteredDisputesRequest(payload))
        : dispatch(getDisputesListRequest(payload));
    }
  }, [
    disputeStatusBtnCheck,
    currentPage,
    disputePageLength,
    disputeClick,
    disputeStatusButton,
    dispatch,
  ]);

  return (
    <div className={styles.disputesWrapper}>
      <Notifications.Activate />
      <div className={styles.nav}>
        <h3>Disputes</h3>
        <div className={styles.navPath}>
          <BreadcumbItem
            title={"Home"}
            onClick={() => handleBreadcrumb("/home")}
            className={styles.cursor}
          />
          <BreadcumbItem title={"Disputes"} className={styles.cursor} off />
        </div>
      </div>
      <div className={styles.note}>
        <p>Your disputes dashboard to keep you in track with disputes</p>
      </div>
      <div className={styles.status}>
        {disputeStatusButton?.map((statusBtn, index) => {
          return (
            <Button
              key={index}
              data-testid="dispute-status-button"
              className={cn(
                statusBtn.status && styles.allDisputes,
                styles.statusDiv
              )}
              onClick={() => handleDisputeStatus({ ...statusBtn }, index)}
            >
              <div
                className={cn(styles.open)}
                style={{
                  background: statusBtn.status ? "#ffffff" : statusBtn.color,
                }}
              ></div>{" "}
              {statusBtn.title.split("_").join(" ")}
            </Button>
          );
        })}
      </div>
      <div className={styles.search}>
        <div className={styles.searchTop}>
          <button
            data-testid="dispute-list-filter"
            className={styles.filterButton}
            onClick={() => handleFilter()}
          >
            <img src={svgIcons.Filters} alt="filters" className={styles.icon} />
            Filter
          </button>
          <div onClick={openDatePicker} className={styles.date}>
            <img
              src={svgIcons.Calender}
              alt="calender"
              className={styles.icon}
            />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selectsRange={true}
              placeholderText="Select date range..."
              startDate={startDate}
              ref={datePickerRef}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
                //to control the API call
                setDateFilterCheck(true);
              }}
              className={styles.datePicker}
              monthsShown={2}
              isClearable
            />
          </div>
          <div className={styles.searchDiv}>
            <Search
              onSearch={(query) => handleSearch(query)}
              onType={(query) => handleSearch(query)}
              type={""}
              placeholder={"Search here..."}
              className={styles.searchInput}
            />
          </div>
        </div>
        <div
          className={styles.filters}
          style={{ display: !filterOpen ? "none" : "" }}
        >
          <DisputeFilter setDateFilterCheck={setDateFilterCheck} />
        </div>
      </div>
      <div>
        <DisputeList />
      </div>
    </div>
  );
};

export default Disputes;

import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    brainTreeSettings: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    brainTreeList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
}

//brainTree-settings
const postBrainTreeSettingsRequest = (state: any, action: any) => {
    return update(state, {
        brainTreeSettings: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postBrainTreeSettingsSuccess = (state: any, action: any) => {
    return update(state, {
        brainTreeSettings: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const postBrainTreeSettingsError = (state: any, action: any) => {
    return update(state, {
        brainTreeSettings: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//brainTree-list
const getBrainTreeListRequest = (state: any, action: any) => {
    return update(state, {
        brainTreeList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getBrainTreeListSuccess = (state: any, action: any) => {
    return update(state, {
        brainTreeList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getBrainTreeListError = (state: any, action: any) => {
    return update(state, {
        brainTreeList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_BRAINTREE_SETTINGS_REQUEST]: postBrainTreeSettingsRequest,
        [constants.POST_BRAINTREE_SETTINGS_SUCCESS]: postBrainTreeSettingsSuccess,
        [constants.POST_BRAINTREE_SETTINGS_ERROR]: postBrainTreeSettingsError,

        [constants.GET_BRAINTREE_LIST_REQUEST]: getBrainTreeListRequest,
        [constants.GET_BRAINTREE_LIST_SUCCESS]: getBrainTreeListSuccess,
        [constants.GET_BRAINTREE_LIST_ERROR]: getBrainTreeListError,

    },
    initialState
);
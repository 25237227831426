import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class GetDisputeMessageDetails {
    /**
     * Function to invoke to get dispute message details
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_DISPUTE_MESSAGE_DETAILS(payload));

        return res;
    }
}
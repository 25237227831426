import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    connectPaypal: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    connectedPayPalList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    deletePayPal: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    }
}

//paypal-connect
const postConnectPaypalRequest = (state: any, action: any) => {
    return update(state, {
        connectPaypal: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postConnectPaypalSuccess = (state: any, action: any) => {
    return update(state, {
        connectPaypal: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const postConnectPaypalError = (state: any, action: any) => {
    return update(state, {
        connectPaypal: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//connected-paypal-list
const getConnectedPayPalListRequest = (state: any, action: any) => {
    return update(state, {
        connectedPayPalList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getConnectedPayPalListSuccess = (state: any, action: any) => {
    return update(state, {
        connectedPayPalList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getConnectedPayPalListError = (state: any, action: any) => {
    return update(state, {
        connectedPayPalList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//delete-paypal
const deletePayPalRequest = (state: any, action: any) => {
    return update(state, {
        deletePayPal: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const deletePayPalSuccess = (state: any, action: any) => {
    return update(state, {
        deletePayPal: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const deletePayPalError = (state: any, action: any) => {
    return update(state, {
        deletePayPal: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_CONNECT_PAYPAL_REQUEST]: postConnectPaypalRequest,
        [constants.POST_CONNECT_PAYPAL_SUCCESS]: postConnectPaypalSuccess,
        [constants.POST_CONNECT_PAYPAL_ERROR]: postConnectPaypalError,

        [constants.GET_CONNECTED_PAYPAL_LIST_REQUEST]: getConnectedPayPalListRequest,
        [constants.GET_CONNECTED_PAYPAL_LIST_SUCCESS]: getConnectedPayPalListSuccess,
        [constants.GET_CONNECTED_PAYPAL_LIST_ERROR]: getConnectedPayPalListError,

        [constants.DELETE_PAYPAL_REQUEST]: deletePayPalRequest,
        [constants.DELETE_PAYPAL_SUCCESS]: deletePayPalSuccess,
        [constants.DELETE_PAYPAL_ERROR]: deletePayPalError,
    },
    initialState
);
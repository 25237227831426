import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    disputesList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    processingDisputesData: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },

    awaitingResponseDisputesData: {
        data: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
}

//disputes-list and filtered-disputes-list
const getDisputesListRequest = (state: any, action: any) => {
    return update(state, {
        disputesList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputesListSuccess = (state: any, action: any) => {
    return update(state, {
        disputesList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputesListError = (state: any, action: any) => {
    return update(state, {
        disputesList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//processing-dispute
const getProcessingDisputesListRequest = (state: any, action: any) => {
    return update(state, {
        processingDisputesData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getProcessingDisputesListSuccess = (state: any, action: any) => {
    return update(state, {
        processingDisputesData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set:  {...action.payload}  }
        }
    });
};

const getProcessingDisputesListError = (state: any, action: any) => {
    return update(state, {
        processingDisputesData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//awaiting-response-dispute
const getAwaitingResponseDisputesListRequest = (state: any, action: any) => {
    return update(state, {
        awaitingResponseDisputesData: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getAwaitingResponseDisputesListSuccess = (state: any, action: any) => {
    return update(state, {
        awaitingResponseDisputesData: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set:  {...action.payload}  }
        }
    });
};

const getAwaitingResponseDisputesListError = (state: any, action: any) => {
    return update(state, {
        awaitingResponseDisputesData: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.GET_DISPUTES_LIST_REQUEST]: getDisputesListRequest,
        [constants.GET_DISPUTES_LIST_SUCCESS]: getDisputesListSuccess,
        [constants.GET_DISPUTES_LIST_ERROR]: getDisputesListError,

        [constants.POST_FILTERED_DISPUTES_REQUEST]: getDisputesListRequest,
        [constants.POST_FILTERED_DISPUTES_SUCCESS]: getDisputesListSuccess,
        [constants.POST_FILTERED_DISPUTES_ERROR]: getDisputesListError,

        [constants.GET_PROCESSING_DISPUTES_REQUEST]: getProcessingDisputesListRequest,
        [constants.GET_PROCESSING_DISPUTES_SUCCESS]: getProcessingDisputesListSuccess,
        [constants.GET_PROCESSING_DISPUTES_ERROR]: getProcessingDisputesListError,

        [constants.GET_AWAITING_RESPONSE_DISPUTES_REQUEST]: getAwaitingResponseDisputesListRequest,
        [constants.GET_AWAITING_RESPONSE_DISPUTES_SUCCESS]: getAwaitingResponseDisputesListSuccess,
        [constants.GET_AWAITING_RESPONSE_DISPUTES_ERROR]: getAwaitingResponseDisputesListError,
    },
    initialState
);
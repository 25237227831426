import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { svgIcons } from "../../../elements";
import { getDisputeCurrency } from "../../../utils/disputeUtils";
import Loading from "../../loading/loading";

import styles from "./style.module.scss";

const DisputeLost = () => {
  const { isLoading, isSuccess, data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );

  return (
    <div className={styles.disputeLostWrapper}>
      {isLoading ? (
        <Loading />
      ) : (
        isSuccess && (
          <div className={styles.disputeLost}>
            <div className={styles.underLost}>
              <img src={svgIcons.Undraw_Lost} alt="Undraw_Lost" />
            </div>
            <div className={styles.statusStatement}>
              <h2>Attention! You lost the dispute</h2>
              <h3>{`You lost ${
                getDisputeCurrency(data?.disputeAmountCurrencyCode)
                  ?.currencySign
              }${data.disputeAmountValue
                .toFixed(2)
                ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</h3>
              {data?.paymentProcessor &&
                data?.paymentProcessor?.toLowerCase() === "paypal" && (
                  <p>
                    {`You can contact ${data?.paymentProcessor} or us to re-open the dispute again. Click below!`}
                  </p>
                )}
            </div>
            {data?.paymentProcessor &&
              data?.paymentProcessor?.toLowerCase() === "paypal" && (
                <div className={styles.appeal}>
                </div>
              )}
          </div>
        )
      )}
    </div>
  );
};

export default DisputeLost;

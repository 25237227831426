import { useState } from "react";
import {
  // generateBrainTreeAuthorizeUrl,
  generatePayPalAuthorizeUrl,
  // generateStripeAuthorizeUrl
} from "utils/GenerateStoreUrls";
import Search from "../search/Search";
import { svgIcons } from "elements";

import styles from "./style.module.scss";

interface addAccountProps {
  name: string;
  setBrainTreeForm?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setisKlarnaForm?: React.Dispatch<React.SetStateAction<boolean>> | any;
}

const institutionsData = [
  {
    name: "PayPal",
    link: "https://www.paypal.com",
    logo: svgIcons.PayPalFill,
  },
  {
    name: "Klarna",
    link: "www.klarna.com",
    logo: svgIcons.klarna,
  },
  {
    name: "Stripe",
    link: "https://www.stripe.com",
    logo: svgIcons.Stripe,
    comingSoon: false,
  },
  {
    name: "BrainTree",
    link: "www.braintree.com",
    logo: svgIcons.BrainTree,
    comingSoon: false,
  },
];

export default function AddAccount({
  name,
  setBrainTreeForm,
  setisKlarnaForm,
}: addAccountProps) {
  const [institutions, setInstitutions] = useState(institutionsData);
  const onSearch = (value: string) => {
    setInstitutions(
      institutionsData.filter(({ name }) =>
        name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const onAddAccountClick = (store: string) => {
    let redirectUrl = "";
    switch (store) {
      case "PayPal":
        redirectUrl = generatePayPalAuthorizeUrl();
        break;
      case "Stripe":
        // redirectUrl = generateStripeAuthorizeUrl();
        break;
      case "BrainTree":
        // redirectUrl = generateBrainTreeAuthorizeUrl();
        setBrainTreeForm(true);
        setisKlarnaForm(false);
        break;
      case "Klarna":
        setisKlarnaForm(true);
        setBrainTreeForm(false);
        break;
    }
    store === "PayPal" && window.location.replace(redirectUrl);
  };
  return (
    <div className={styles.addAccountModal}>
      <h2 className={styles.addAccountHeading}>{name}</h2>
      <p className={styles.subHeading}>
        Choose your E-Commerce/ Store from below and link it to your Account.
      </p>
      <div className={styles.institutions}>
        <Search
          type="text"
          placeholder="Search available Platforms..."
          onSearch={onSearch}
          onType={onSearch}
        />
        <h2 className={styles.institutionsTitle}>Most Popular</h2>
        <div className={styles.institutionsList}>
          {institutions.map(({ name, link, logo, comingSoon }) => (
            <div
              className={styles.institutionWrapper}
              key={name}
              onClick={() => {
                onAddAccountClick(name);
              }}
            >
              <div className={styles.hoverInstitution}>
                <div className={styles.institution} data-testid={name}>
                  <img
                    className={styles.institutionLogo}
                    alt={`${name} logo`}
                    src={logo}
                  />
                  <div className={styles.lineGroup}>
                    <div className={styles.institutionInfo}>
                      <div className={styles.institutionNameHolder}>
                        <h3 className={styles.institutionName}>{name}</h3>
                        {comingSoon && (
                          <div className={styles.badgeHolder}>
                            <div className={styles.badge}>
                              <img src={svgIcons.Corner_Down_Right} alt="" />
                              <p>{comingSoon}</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <p className={styles.institutionLink}>{link}</p>
                    </div>
                  </div>
                </div>
                <img src={svgIcons.ChevronRight} alt="arrow" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

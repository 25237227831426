import React from "react";
import { useSelector } from "react-redux";
import LinearDeterminate from "../disputeSummary/linearProgress";
import { svgIcons } from "../../../elements";

import styles from './style.module.scss';

interface DisputeTrackiScoreProps {
    disputeState?: string
}

const DisputeTrackiScore = ({ disputeState }: DisputeTrackiScoreProps) => {
    const { data } = useSelector((state: any) => state?.disputeDetailsStore?.disputeDetails);

    return (
        <div className={styles.score}>
            <div className={styles.TrackiScore}>
                {/* <div className={styles.TrackiScoreText}>
                    <p>ChargePayScore®</p>
                    <img src={svgIcons.Alert_Circle_Black} alt='Alert' />
                </div>
                <p className={styles.TrackiScorePercentage}>
                    {data?.trackiScore ?? 0}%
                </p> */}
                <div className={styles.TrackiScoreText}>
                    <p>Dispute Infomation</p>
                </div>
            </div>

            {(
                disputeState === "UNDER_PAYPAL_REVIEW" ||
                disputeState === "REQUIRED_OTHER_PARTY_ACTION" ||
                disputeState === "response template" ||
                disputeState === "RESOLVED"
            ) &&
                <div className={styles.TrackiScoreTextSub}>
                    <p>Odds For Winning This Dispute</p>
                </div>
            }

            <div className={styles.linearDeterminateWrapper}>
                {/* <LinearDeterminate /> */}
            </div>
        </div>
    )
}

export default DisputeTrackiScore;
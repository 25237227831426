import { FC } from "react";
import styles from "./style.module.scss";
import { BlueEye } from 'elements/svgViewer';
import FormatCurrency from 'components/formatCurrency';

interface Props {
  disputeWidgetData: {
    title: string;
    blueEye?: boolean;
    totalDisputes?: number;
    icon?: any;
    disputedAmount?: number;
  };
}

const DisputesWidget: FC<Props> = ({ disputeWidgetData }) => {
  return (
    <div className={styles.Disputes}>
      <div className={styles.DisputesSub}>
        <div className={styles.Disputesheader}>
          <p className={styles.Disputesheading}>{disputeWidgetData?.title}</p>
          {disputeWidgetData?.blueEye && (
           <BlueEye/>
          )}
        </div>

        <div className={styles.DisputesFooter}>
          <div className={styles.DisputesLeft}>
            <p>
              {disputeWidgetData?.totalDisputes ? (
                Math.trunc(disputeWidgetData?.totalDisputes)
              ) : disputeWidgetData?.disputedAmount ? (
                <FormatCurrency
                  value={Number(disputeWidgetData?.disputedAmount)?.toFixed(2)}
                />
              ) : (
                "0"
              )}
            </p>
          </div>
          {disputeWidgetData?.icon && (
            <div className={styles.graph}>
              {disputeWidgetData.icon}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DisputesWidget;

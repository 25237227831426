import styles from "./style.module.scss";

interface noDataFoundProps {
    icon: string;
    isError?: boolean;
    errorStatement?: string;
    notFoundMessage: string;
}
const NoDataFound = ({ icon, isError, errorStatement, notFoundMessage}: noDataFoundProps) => {
    return (
        <div className={styles.NoData}>
            <img src={icon} alt="noDataFound" />
            {isError &&
                <h3>{errorStatement}</h3>
            }
            <h3>{notFoundMessage}</h3>
        </div>
    )
}

export default NoDataFound;
import { useState } from 'react';
import cloneDeep from "lodash/cloneDeep";
import { Stack, Button } from '@mui/material';
import { svgIcons } from "../../../elements";

import styles from './style.module.scss';

const responseDocuments = [
    {
        title: 'Please upload require documents as asked by PayPal below',
        icon: svgIcons.Alert_Circle_Black,
        file: null,
    },
];


const DisputeResponseTemplate = ({ setDisputeState }: any) => {
    const [respDoc, setRespDoc] = useState([...responseDocuments]);
    const handleSubmit = () => {
        setDisputeState("UNDER_PAYPAL_REVIEW")
    }

    const handleFileUpload = (document: any, doc: any, index: number) => {
        const documentArr = cloneDeep(respDoc);
        document.file = doc.target.files[0];
        documentArr[index] = document;
        setRespDoc(documentArr);
    }

    return (
        <div className={styles.disputeResponseTemplateWrapper}>
            <div>
                <h4>Your Response Template</h4>
                <div className={styles.orderDeliveredWrapper}>
                    <div className={styles.processingDocWrapper}>
                        <div>
                            <div className={styles.responseDocuments}>
                                {respDoc.map((resDocument, index) => {
                                    return (
                                        <div key={index} className={styles.responseDocument}>
                                            <div>
                                                <p>
                                                    {resDocument.title}
                                                    {resDocument?.icon && <img src={resDocument?.icon} alt='alert' />}
                                                </p>
                                            </div>
                                            <div className={styles.responseDocumentField}>
                                                <Stack alignItems="center" spacing={2}>
                                                    <Button variant="text" component="label" data-testid="response-template-button">
                                                        <img style={{ display: resDocument.file ? "none" : '' }} src={svgIcons.Upload_In_Blue} alt='upload' />
                                                        <p style={{ display: resDocument.file ? "none" : '' }}>Browse File or Drag & Drop</p>
                                                        <input
                                                            hidden={resDocument.file ? false : true}
                                                            data-testid="response-template-input"
                                                            type="file"
                                                            onChange={(doc) => handleFileUpload({ ...resDocument }, doc, index)}
                                                        />
                                                    </Button>
                                                </Stack>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={styles.orderDeliveredCustom}>
                        <div>
                            <div className={styles.customerResponseWrapper}>
                                <h5>Help</h5>
                                <div>
                                    <p>
                                        Even after submitting the response sometimes the chargeback
                                    </p>
                                    <br />
                                    <p>
                                        re-opens from Under Review State and may ask to submit more info.
                                    </p>
                                    <br />
                                    <p>
                                        In that case only submit the Shipping Label. In this case the Shipping Label needs to be submitted. If Shipping Label is not available then only submit the same tracking page screenshot.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.orderSubmit}>
                    <Button
                        data-testid="dispute-response-template-submit"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        <div className={styles.submit}>
                            <img src={svgIcons.CheckTwo} alt='check' />
                            <p>Submit Response</p>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DisputeResponseTemplate;
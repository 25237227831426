import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import cn from "classnames";
// import {
//   getDisputeResponseFindByDisputeIdRequest,
//   getDisputeTemplateFindByTypeRequest,
//   getManageDisputeCarrierOptionsRequest,
//   getManageDisputeEvidenceTypeOptionsRequest,
//   getManageDisputeHateoasLinkRequest,
// } from "redux/actions";
import Loading from "components/loading/loading";
import styles from "./style.module.scss";
// import { svgIcons } from "elements";
// import { useParams } from "react-router-dom";
import SupportingEvidence from "./supportingEvidence";

const ManageDispute = ({ clickedTab, setClickedTab, disputeDetails }: any) => {
  // const dispatch = useDispatch();

  // const { disputeId } = useParams();
  // const [sendMessageTab, setSendMessageTab] = useState(false);

  // const disputePaymentGateway = disputeDetails?.data?.paymentProcessor;
  return (
    <div className={styles.disputeWrapper}>
      <div className={styles.manageHead}>
        <h6>Supporting Evidence</h6>
      </div>
      <div className={styles.manageFormWrapper}>
        {disputeDetails?.isLoading ? (
          <Loading />
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className={styles.manageFormTabsWrapper}
            >
              {/* <div className={styles.integrationTabs}>
                <Button
                  className={
                    clickedTab === "supporting_evidence"
                      ? cn(styles.integrationTab, styles.activeTab)
                      : styles.integrationTab
                  }
                  onClick={() => setClickedTab("supporting_evidence")}
                >
                  <p>Supporting Evidence</p>
                </Button>
              </div> */}
            </Box>

            {clickedTab === "supporting_evidence" && (
              <div>
                <SupportingEvidence />
              </div>
            )}
          </Box>
        )}
      </div>
    </div>
  );
};

export default ManageDispute;

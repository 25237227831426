import DisputeAwaitingStage from '../disputeAwaitingStage';
import { svgIcons } from "../../../../elements";

import styles from './style.module.scss';

const disputeUnderReview = {
    statusAwaiting: 'Awaiting for response from PayPal',
    statusReceived: 'Response Received',
}

const UnderReview = ({ disputeState }: any) => {
    return (
        <>
            {
                (
                    disputeState !== "RESOLVED" &&
                    disputeState !== "UNDER_PAYPAL_REVIEW" &&
                    disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&

                    disputeState !== "response template" &&
                    disputeState !== "dispute lost"
                )
                    ? (
                        <DisputeAwaitingStage
                            stageIcon={svgIcons.UnderReview}
                            stage={'Stage 2'}
                            stageName={'Under Review'}
                        />
                    ) : (
                        <div className={styles.disputeUnderReviewWrapper}>
                            <h4>Under Review</h4>
                            <div className={styles.disputeUnderReviewData}>
                                <div>
                                    <h6>Status</h6>
                                    <p className={
                                        (
                                            disputeState === "UNDER_PAYPAL_REVIEW" ||
                                            disputeState === "REQUIRED_OTHER_PARTY_ACTION" ||
                                            disputeState === "response template"
                                        )
                                            ? styles.disputeAwaitingStatus
                                            : styles.disputeSubmitStatus
                                    }>
                                        {
                                            (
                                                disputeState === "UNDER_PAYPAL_REVIEW" ||
                                                disputeState === "REQUIRED_OTHER_PARTY_ACTION" ||
                                                disputeState === "response template"
                                            )
                                                ? disputeUnderReview.statusAwaiting
                                                : disputeUnderReview.statusReceived
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
            }
        </>

    )
}

export default UnderReview;
import { FC } from 'react';
import styles from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { svgIcons } from '../../elements';
import { setItem } from '../../utils/localStorage';

interface Props {
  handleTrial: () => void;
}

const selfService = [
  {
    lable: 'Unlimited stores',
    icon: svgIcons.CheckFour,
  },
  {
    lable: 'Unlimited paypal account',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Self service platform',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Priority 24/7 support',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Digital product support',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Courier mapping',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Subscription service support',
    icon: svgIcons.CheckFour
  },
  {
    lable: '20% for each recovered dispute (self service)',
    icon: svgIcons.CheckFour
  },
]

const fullyManaged = [
  {
    lable: 'Unlimited stores',
    icon: svgIcons.CheckFour,
  },
  {
    lable: 'Unlimited paypal account',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Dedicated account manager',
    icon: svgIcons.CheckFive,
    classType: styles.premium
  },
  {
    lable: 'Priority 24/7 support',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Digital product support',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Courier mapping',
    icon: svgIcons.CheckFour
  },
  {
    lable: 'Subscription service support',
    icon: svgIcons.CheckFour
  },
  {
    lable: '15% for each recovered dispute (fully managed)',
    icon: svgIcons.CheckFive,
    classType: styles.premium
  },
]

// todo-fix-icons
const Trial: FC<Props> = ({ handleTrial }) => {
  const navigate = useNavigate();

  const handleHomeNavigate = () => {
    setItem('isOnboard', true);
    navigate('/home');
  }

  return (
    <div className={styles.trialWrapper}>
      <div className={styles.cardsHeading}>
        <h3>Start your free trial</h3>
        <p>
          Simple, transparent pricing that grows with you. Try any plan free 30
          days.
        </p>
      </div>
      <div className={styles.cardsWrapper}>
        <div className={styles.trialCards}>
          <div className={styles.cardTop}>
            <h3>Self Service</h3>
            <p>Best for small business</p>
            <ul>
              {selfService.map((item) => {
                return (<li>
                  <img src={item.icon} alt='BlueCheck' />
                  <p>{item.lable}</p>
                </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.buttonWrapper}>
            <button onClick={handleHomeNavigate}>Start 3 Day Trial</button>
            <div>
              <p>
                then <span style={{ fontWeight: 'bold' }}>$49.99/m</span>
              </p>
              <p>Cancel Anytime</p>
            </div>
          </div>
        </div>
        <div className={styles.trialCards}>
          <div className={styles.cardTop}>
            <div className={styles.recommended}>
              <img src={svgIcons.Arrow_Up} alt='ArrorUp' />
              <p>Recommended</p>
            </div>
            <h3>Fully Managed</h3>
            <p>Best for big stores</p>
            <ul>
              {fullyManaged.map((item) => {
                return (
                  <li>
                    <img src={item.icon} alt='BlueCheck' />
                    <p className={item?.classType}>{item.lable}</p>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className={styles.buttonWrapper}>
            <button onClick={handleHomeNavigate}>Contact Us</button>
            <div>
              <p>
                then <span style={{ fontWeight: 'bold' }}>$99.99/m</span>
              </p>
              <p>Cancel Anytime</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trial;


import styles from './style.module.scss';

const TrialPage = () => {
  return (
    <div className={styles.trialWrapper}>
      <div className={styles.progressWrapper}>
        <div className={styles.progressChecks}>
          <div>App install</div>
        </div>
        <div className={styles.progressChecks}>
          <div className={styles.check}>Connect Account</div>
        </div>
        <div className={styles.progressChecks}>
          <div>Start Now</div>
        </div>
      </div>
      {/* <Trial /> */}
    </div>
  );
};

export default TrialPage;

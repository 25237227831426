import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";


const initialState = {
    userDetails: {
        data: {
            userId: '',
            email: '',
            username: '',
            phoneNumber: '',
            picture: '',
            name: '',
            nickname: '',
            familyName: '',
            birthDate: '',
            telephone: '',
            planId: 1,
        },
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
}

const postCreateUserRequest = (state: any, action: any) => {
    return update(state, {
        userDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postCreateUserSuccess = (state: any, action: any) => {
    return update(state, {
        userDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...state.userDetails, ...action.payload } }
        }
    });
};

const postCreateUserError = (state: any, action: any) => {
    return update(state, {
        userDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.POST_CREATE_USER_REQUEST]: postCreateUserRequest,
        [constants.POST_CREATE_USER_SUCCESS]: postCreateUserSuccess,
        [constants.POST_CREATE_USER_ERROR]: postCreateUserError,
    },
    initialState
);
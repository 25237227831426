import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    disputeDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    disputeTransactionDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeEvidenceDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeMessageDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeAdjudicationDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeMoneyMovementDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeSupportingInfoDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    manageDisputeFraudAnalysis:{
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    manageDisputeCustomerDetails:{
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    manageDisputeTrackingDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    manageDisputeFulfillmentDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    disputeOfferDetails: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeOrderSummary: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    addSupportingEvidence: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
}

//dispute-details
const getDisputeDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getDisputeDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-transaction-details
const getDisputeTransactionDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeTransactionDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeTransactionDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeTransactionDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeTransactionDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeTransactionDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-evidence-details
const getDisputeEvidenceDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeEvidenceDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeEvidenceDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeEvidenceDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeEvidenceDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeEvidenceDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-add-Supporting-Evidence-details
const postAddSupportingEvidenceRequest = (state: any, action: any) => {
    return update(state, {
        addSupportingEvidence: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postAddSupportingEvidenceSuccess = (state: any, action: any) => {
    return update(state, {
        addSupportingEvidence: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const postAddSupportingEvidenceError = (state: any, action: any) => {
    return update(state, {
        addSupportingEvidence: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//add-Supporting-Evidence
const getDisputeMessageDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeMessageDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeMessageDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeMessageDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeMessageDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeMessageDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-adjudication-details
const getDisputeAdjudicationDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeAdjudicationDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeAdjudicationDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeAdjudicationDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeAdjudicationDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeAdjudicationDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-money-movement-details
const getDisputeMoneyMovementDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeMoneyMovementDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeMoneyMovementDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeMoneyMovementDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeMoneyMovementDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeMoneyMovementDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-supporting-info-details
const getDisputeSupportingInfoDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeSupportingInfoDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeSupportingInfoDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeSupportingInfoDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeSupportingInfoDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeSupportingInfoDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-offer-details
const getDisputeOfferDetailsRequest = (state: any, action: any) => {
    return update(state, {
        disputeOfferDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeOfferDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        disputeOfferDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeOfferDetailsError = (state: any, action: any) => {
    return update(state, {
        disputeOfferDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-order-summary
const getDisputeOrderSummaryRequest = (state: any, action: any) => {
    return update(state, {
        disputeOrderSummary: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeOrderSummarySuccess = (state: any, action: any) => {
    return update(state, {
        disputeOrderSummary: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [ ...action.payload ] }
        }
    });
};

const getDisputeOrderSummaryError = (state: any, action: any) => {
    return update(state, {
        disputeOrderSummary: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-fraud analysis details
const getDisputeFraudAnalysisRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeFraudAnalysis: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeFraudAnalysisSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeFraudAnalysis: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeFraudAnalysisError = (state: any, action: any) => {
    return update(state, {
        manageDisputeFraudAnalysis: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
// dispute-customer-details
const getDisputeCustomerDetailsRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeCustomerDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeCustomerDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeCustomerDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeCustomerDetailsError = (state: any, action: any) => {
    return update(state, {
        manageDisputeCustomerDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-order-fulfillment details
const getDisputeOrderFulfillmentDetailsRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeFulfillmentDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeOrderFulfillmentDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeFulfillmentDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeOrderFulfillmentDetailsError = (state: any, action: any) => {
    return update(state, {
        manageDisputeFulfillmentDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-tracking-details
const getDisputeTrackingDetailsRequest = (state: any, action: any) => {
    return update(state, {
        manageDisputeTrackingDetails: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeTrackingDetailsSuccess = (state: any, action: any) => {
    return update(state, {
        manageDisputeTrackingDetails: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeTrackingDetailsError = (state: any, action: any) => {
    return update(state, {
        manageDisputeTrackingDetails: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


export default handleActions(
    {
        [constants.GET_DISPUTE_DETAILS_REQUEST]: getDisputeDetailsRequest,
        [constants.GET_DISPUTE_DETAILS_SUCCESS]: getDisputeDetailsSuccess,
        [constants.GET_DISPUTE_DETAILS_ERROR]: getDisputeDetailsError,

        [constants.GET_DISPUTE_TRANSACTION_DETAILS_REQUEST]: getDisputeTransactionDetailsRequest,
        [constants.GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS]: getDisputeTransactionDetailsSuccess,
        [constants.GET_DISPUTE_TRANSACTION_DETAILS_ERROR]: getDisputeTransactionDetailsError,

        [constants.GET_DISPUTE_EVIDENCE_DETAILS_REQUEST]: getDisputeEvidenceDetailsRequest,
        [constants.GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS]: getDisputeEvidenceDetailsSuccess,
        [constants.GET_DISPUTE_EVIDENCE_DETAILS_ERROR]: getDisputeEvidenceDetailsError,

        [constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST]: getDisputeFraudAnalysisRequest,
        [constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS]: getDisputeFraudAnalysisSuccess,
        [constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR]: getDisputeFraudAnalysisError,

        [constants.GET_DISPUTE_CUSTOMER_DETAILS_REQUEST]: getDisputeCustomerDetailsRequest,
        [constants.GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS]: getDisputeCustomerDetailsSuccess,
        [constants.GET_DISPUTE_CUSTOMER_DETAILS_ERROR]: getDisputeCustomerDetailsError,

        [constants.GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_REQUEST]:getDisputeOrderFulfillmentDetailsRequest,
        [constants.GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_SUCCESS]:getDisputeOrderFulfillmentDetailsSuccess,
        [constants.GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_ERROR]:getDisputeOrderFulfillmentDetailsError,

        [constants.GET_DISPUTE_TRACKING_DETAILS_REQUEST]: getDisputeTrackingDetailsRequest,
        [constants.GET_DISPUTE_TRACKING_DETAILS_SUCCESS]: getDisputeTrackingDetailsSuccess,
        [constants.GET_DISPUTE_TRACKING_DETAILS_ERROR]: getDisputeTrackingDetailsError,

        [constants.GET_DISPUTE_MESSAGE_DETAILS_REQUEST]: getDisputeMessageDetailsRequest,
        [constants.GET_DISPUTE_MESSAGE_DETAILS_SUCCESS]: getDisputeMessageDetailsSuccess,
        [constants.GET_DISPUTE_MESSAGE_DETAILS_ERROR]: getDisputeMessageDetailsError,

        [constants.GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST]: getDisputeAdjudicationDetailsRequest,
        [constants.GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS]: getDisputeAdjudicationDetailsSuccess,
        [constants.GET_DISPUTE_ADJUDICATION_DETAILS_ERROR]: getDisputeAdjudicationDetailsError,

        [constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST]: getDisputeMoneyMovementDetailsRequest,
        [constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS]: getDisputeMoneyMovementDetailsSuccess,
        [constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR]: getDisputeMoneyMovementDetailsError,

        [constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST]: getDisputeSupportingInfoDetailsRequest,
        [constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS]: getDisputeSupportingInfoDetailsSuccess,
        [constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR]: getDisputeSupportingInfoDetailsError,

        [constants.GET_DISPUTE_OFFER_DETAILS_REQUEST]: getDisputeOfferDetailsRequest,
        [constants.GET_DISPUTE_OFFER_DETAILS_SUCCESS]: getDisputeOfferDetailsSuccess,
        [constants.GET_DISPUTE_OFFER_DETAILS_ERROR]: getDisputeOfferDetailsError,

        [constants.GET_DISPUTE_ORDER_SUMMARY_REQUEST]: getDisputeOrderSummaryRequest,
        [constants.GET_DISPUTE_ORDER_SUMMARY_SUCCESS]: getDisputeOrderSummarySuccess,
        [constants.GET_DISPUTE_ORDER_SUMMARY_ERROR]: getDisputeOrderSummaryError,

        [constants.POST_ADD_SUPPORTING_EVIDENCE_REQUEST]: postAddSupportingEvidenceRequest,
        [constants.POST_ADD_SUPPORTING_EVIDENCE_SUCCESS]: postAddSupportingEvidenceSuccess,
        [constants.POST_ADD_SUPPORTING_EVIDENCE_ERROR]: postAddSupportingEvidenceError,
    },
    initialState
)
import { useParams } from 'react-router-dom';
import styles from "./style.module.scss";
import Loading from "components/loading/loading";
import { useCustomerInformation } from 'hooks';

const CustomerInformation = () => {
  const disputeId = useParams()
  const { data , isLoading} = useCustomerInformation(disputeId)

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data ? (
            <div className={styles.customerInformationWrapper}>
              <div className={styles.orderFulFillmentContainer}>
                <p className={styles.orderFulfillmentTitle}>Full Name</p>
                <p className={styles.orderFulfillmentDetails}>
                  {data?.customerFirstName} {data?.customerLastName}
                </p>
              </div>
              <div className={styles.orderFulFillmentContainer}>
                <p className={styles.orderFulfillmentTitle}>Shipping Address</p>
                <p className={styles.orderFulfillmentDetails}>
                  {data?.shippingAddress?.address1},
                  {data?.shippingAddress?.province},{data?.shippingAddress?.zip}
                </p>
              </div>
              <div className={styles.orderFulFillmentContainer}>
                <p className={styles.orderFulfillmentTitle}>Billing Address</p>
                <p className={styles.orderFulfillmentDetails}>
                  {data?.billingAddress?.address1},
                  {data?.billingAddress?.province},{data?.billingAddress?.zip}
                </p>
              </div>
              <div className={styles.orderFulFillmentContainer}>
                <p className={styles.orderFulfillmentTitle}>Customer Email</p>
                <p className={styles.orderFulfillmentDetails}>
                  {data?.customerEmail}
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.noCustomerInformation}>
              No customer information
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CustomerInformation;

import { svgIcons } from 'elements';
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { last } from 'lodash';
import { useSelector } from 'react-redux';
import Loading from 'components/loading/loading';

const OrderStatus = () => {
  interface RootState {
    disputeDetailsStore: {
      manageDisputeCustomerDetails: {
        data: any;
        isSuccess: boolean;
        isLoading: boolean;
        isError: boolean;
      };
    };
  }
  const { isSuccess, data, isLoading, isError } = useSelector(
    (state: RootState) =>
      state?.disputeDetailsStore?.manageDisputeCustomerDetails
  );
  return (
    <>
      {
        isLoading ? <Loading /> :
          <div>
            {data ? (
              <div className={styles.orderStatusWrapper}>
                <img src={svgIcons.OrderStatus} alt="order_fulfillment" />
                <Link
                  to={data?.orderStatusUrl}
                  className={styles.orderStatus}
                  target="_blank"
                >
                  {data?.orderStatusUrl}
                </Link>
              </div>
            ) : (
              <div
                style={{
                  fontSize: "12px",
                  color: "#98A2B3",
                  minHeight: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No order status
              </div>
            )}
          </div>
      }
    </>
  );
};

export default OrderStatus;

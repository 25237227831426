import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface ResProp {
  data: {
    url: string
  },
  status:number
}

export function* getShopifyHmacRequest(action: any) {
  try {
    const res: ResProp = yield Api.getShopifyHmac(action.payload);
    if(res.status === (500 || 401)){
      yield put(actions.getShopifyHmacError());
    }else{
      yield put(actions.getShopifyHmacSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getShopifyHmacError());
  }
}

export function* postShopifyCreateChargeRequest() {
  try {
    const res: ResProp = yield Api.postShopifyCreateCharge();
    yield put(actions.postShopifyCreateChargeSuccess(res.data));
  } catch (e) {
    yield put(actions.postShopifyCreateChargeError());
  }
}

export function* postShopifyCreateSubscriptionRequest() {
  try {
    const res: ResProp = yield Api.postShopifyCreateSubscription();
    yield put(actions.postShopifyCreateSubscriptionSuccess(res.data));
  } catch (e) {
    yield put(actions.postShopifyCreateSubscriptionError());
  }
}

export function* postShopifyActivateChargeRequest(action: any) {
  try {
    const res: ResProp = yield Api.postShopifyActivateCharge(action.payload);
    yield put(actions.postShopifyActivateChargeSuccess(res.data));
  } catch (e) {
    yield put(actions.postShopifyActivateChargeError());
  }
}

interface errorMessage {
  errors: any;
  name: string;
}
const ErrorMessage = ({ errors, name }: errorMessage) => {
  return (
    <>
      {errors[name]?.type === "required" && (
        <p
          style={{
            color: "#DC2626",
            fontSize: "13px",
            fontWeight: 400,
            marginTop: "0.5rem",
            display: "block",
          }}

        >
          {errors[name].message}
        </p>
      )}
    </>
  );
};

export default ErrorMessage;

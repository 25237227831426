import cn from 'classnames';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { _helper } from 'elements';
import styles from './style.module.scss';
import {
  Notifications,
  DisputesWidget,
  RecentOrderHeader,
  RecentDisputes,
  Loading,
  ActivateYourAccount,
  DisputesRisk,
} from 'components';
import { IconRender } from 'elements/svgViewer';
import ShowingStripeModal from 'components/ShowingStripeModal';
import { useDashboard } from 'hooks';

export default function Home() {
  const {
    disputeRisk,
    isLoading,
    addShopifyShopData,
    onDisputesRiskClose,
    onActivateBannerClose,
    openDisputesData,
    recentDisputesHeading,
    recoveredDisputesData,
    stripeData,
    userData,
    subscribed,
  } = useDashboard();
  const openDisputesObj = {
    title: 'Open Disputes',
    blueEye: true,
    icon: <IconRender iconName='layersThree' />,
    totalDisputes: openDisputesData?.data?.totalDisputes || 0,
  };
  const activeDisputesObj = {
    title: 'Total Amount Disputed(Open)',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: openDisputesData?.data?.totalChallengedAmount || 0,
  };

  const recoveredDisputesObj = {
    title: 'Recovered Disputes',
    blueEye: true,
    icon: <IconRender iconName='currencyDollar' />,
    totalDisputes: recoveredDisputesData?.data?.totalDisputes || 0,
  };

  const totalDisputesWonObj = {
    title: 'Total Amount Recovered(Won)',
    blueEye: true,
    icon: <IconRender iconName='trophy' />,
    disputedAmount: recoveredDisputesData?.data?.totalChallengedAmount || 0,
  };
  return (
    <div className={styles.HomeWrapper}>
      <ToastContainer />
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.HomeBody}>
          <Notifications.Activate />
          <div>
            <ShowingStripeModal />
            <h2>
              {_helper.greeting()},{' '}
              <span className={styles.userName}>
                {addShopifyShopData?.name
                  ? addShopifyShopData?.name
                  : userData?.name
                  ? userData?.name
                  : stripeData?.name
                  ? stripeData?.name
                  : ''}
              </span>
            </h2>
          </div>
          <div className={styles.cardsWrapper}>
            <div className={styles.main}>
              <div className={styles.disputesWrapper}>
                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={openDisputesObj} />
                </div>

                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={activeDisputesObj} />
                </div>

                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={recoveredDisputesObj} />
                </div>

                <div className={styles.card}>
                  <DisputesWidget disputeWidgetData={totalDisputesWonObj} />
                </div>
              </div>

              {/* {user && !user['https://trackipal.com/onboardstatus']
                  ? (
                    <div className={styles.recentDisputes} data-testid="SyncingData">
                      <RecentOrderHeader headingData={tableHeading[0]} />
                      <div className={styles.syncingWrapper}>
                        <div>
                          <div className={styles.syncingSkeleton}>
                            <div className={styles.syncingSkeletonLeft}>
                              <Skeleton height={'3rem'} width={'70%'} />
                              <Skeleton height={'3rem'} width={'40%'} />
                              <Skeleton height={'3rem'} width={'55%'} />
                            </div>

                            <div className={styles.syncingSkeletonRight}>
                              <Skeleton height={'3rem'} width={'50%'} />
                              <Skeleton height={'3rem'} width={'35%'} />
                              <Skeleton height={'3rem'} width={'65%'} />
                            </div>
                          </div>
                          <div className={styles.syncingData}>
                            <img src={svgIcons.Animation_640_1} alt='syncing' />
                            <h3>Syncing data...</h3>
                            <div className={styles.syncInfo}>
                              <p>Currently you have no transaction to display.</p>
                              <p>Please wait for a while for sync data</p>
                            </div>
                          </div>
                          <Skeleton height={'3rem'} width={'40%'} />
                        </div>
                      </div>
                    </div>
                  ) : ( */}
              <>
                <div
                  className={styles.recentDisputes}
                  data-testid='recentDisputes'
                >
                  <RecentOrderHeader headingData={recentDisputesHeading} />
                  <div className={cn(styles.card, styles.recentDisputesTable)}>
                    <RecentDisputes />
                  </div>
                </div>
              </>
              {/* )} */}
            </div>
          </div>

          {userData?.id &&
            !userData?.riskAccepted &&
            !userData?.chargeActive && (
              <ActivateYourAccount
                isOpen={!subscribed}
                onClose={onActivateBannerClose}
                skip={onActivateBannerClose}
              />
            )}

          <DisputesRisk isOpen={disputeRisk} onClose={onDisputesRiskClose} />
        </div>
      )}
    </div>
  );
}

import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import {configs} from '../../../configs/index';


export class PostBrainTreeSettings {
  /**
   * Function to invoke to BrainTree
   */
  async invoke(payload: any) {

    const res = await ApiService.getInstance().post(apiConstants.POST_BRAINTREE_SETTINGS, payload, { baseURL: configs.BASE_URL as string })

    return res;
  }

  async invokeBrainTreeList(payload: any) {

    const res = await ApiService.getInstance().get(apiConstants.GET_BRAINTREE_LIST(payload))

    return res;
  }

}
import { Register } from '../../components';
import reviews from '../../reviews.json';
import { svgIcons } from '../../elements';

import styles from './style.module.scss';

const RegisterPage = () => {
  const review = reviews[0];

  return (
    <div className={styles.registerWrapper}>
      <div className={styles.logo}>
        <img src={svgIcons.TPConnect} alt="tp" />
        <div className={styles.logoName}>
          <h3>Trackipal</h3>
          <p>Connect</p>
        </div>
      </div>
      <div className={styles.registerImage}>
        <div className={styles.registerCarousel}>
          <div id="control-buttons">
            <div></div>
            <div></div>
          </div>
          <div id="nav-controls" className="hidden">
            <div className='tns-outer' key={review.id}>
              <div>
                {review?.rating > 0 &&
                  <div className={styles.reviewRating}>
                    <span>
                      <img src={svgIcons.Star} alt="*" />
                      <img src={svgIcons.Star} alt="*" />
                      <img src={svgIcons.Star} alt="*" />
                      <img src={svgIcons.Star} alt="*" />
                      <img src={svgIcons.Star} alt="*" />
                    </span>
                  </div>
                }
              </div>
              <div>
                <h4 className={styles.reviewReview}>{review.review}</h4>
              </div>
              <div className={styles.reviewImage}>
                <img src={review.image} alt="ReviewAvatar" />
              </div>
              <div>
                <p className={styles.reviewName}>{review.name}</p>
                <p className={styles.reviewOcc}>{review.occupation}</p>
              </div>
            </div>
          </div>

        </div>

        <div className={styles.footer}>
          {/* <p> by &copy; Castynet Studios&#8482; 2077</p> */}
          <p> &copy; Trackipal Connect</p>
          <p>help@trackipal.com</p>
        </div>
      </div>
      <div className={styles.register}>
        <Register />
      </div>
    </div>
  );
};

export default RegisterPage;

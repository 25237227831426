import { Box } from "@mui/system";
import { ArrowRight } from "elements";

const BreadcumbItem = ({ onClick, className, title, off }: any) => {
  return (
    <Box
      display={`flex`}
      justifyContent={`space-between`}
      gap={1}
      alignItems={`center`}
    >
      {" "}
      <p className={className} onClick={onClick}>
        {title}
      </p>
      {off ?? <ArrowRight />}
    </Box>
  );
};

export default BreadcumbItem;

import { useNavigate } from "react-router-dom";
import { NoDataFound } from "../index";
import { svgIcons } from "../../elements";
import styles from "./style.module.scss";

const mockNotificationData: any[] = []

interface notificationProps {
  handleClose: () => void
}

const Notification = ({ handleClose }: notificationProps) => {
  const navigate = useNavigate();

  const handleSeeAll = () => {
    handleClose()
    navigate("/notification")
  }

  return (
    <div className={styles.notificationsWrapper}>
      <div className={styles.top}>
        <p>Notifications</p>
        <button onClick={handleSeeAll}>See All</button>
      </div>
      <div className={styles.options}>
        {
          mockNotificationData?.length > 0 ? (
            mockNotificationData?.map((notification) => {
              return (
                <div className={styles.option}>
                  <div className={styles.notifyIcon} style={{
                    background: `${notification?.iconBackgound}`
                  }}>
                    <img src={notification?.icon} alt="google" className={styles.google} />
                  </div>
                  <div>
                    <p>{notification?.title}</p>
                    <span>{notification?.description}</span>
                  </div>
                </div>
              )
            })
          ) : (
            <NoDataFound icon={svgIcons?.UnderReview} errorStatement="Oops! Something went wrong. Please try again." notFoundMessage="No notification" />
          )
        }
      </div>
    </div>
  );
};

export default Notification;

import { useRef } from 'react';

const useOpenDatePicker = () => {
    const datePickerRef:any = useRef(null);
    const openDatePicker = () => {
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true);
      }
    };
return {openDatePicker, datePickerRef}
}
export default useOpenDatePicker;
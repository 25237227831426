import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import { configs } from '../../../configs/index';


export class PostStripeLogin {
  /**
   * Function to invoke to stripe
   */
  async invoke(payload: any) {
    const res = await ApiService.getInstance(payload.token).get(apiConstants.POST_STRIPE_LOGIN(payload))
    return res;
  }

}
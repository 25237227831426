import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { postStripeUpdateBusinessProfileRequest } from "redux/actions";
import { getItem, removeItem } from "utils/localStorage";
import styles from "./style.module.scss";

/**
 * Renders a modal dialog that allows the user to update their Stripe business profile.
 * The modal is displayed when the `additionalMandatoryShopDataRequired` flag is set in the Stripe data,
 * and the `fromStripe` flag is set in local storage.
 * The modal allows the user to update the name, owner, URL, and email of their business profile.
 * When the user submits the form, the `postStripeUpdateBusinessProfileRequest` action is dispatched to update the profile.
 */
const ShowingStripeModal = () => {
  const {
    data: stripeData,
    isLoading: isStripeLoading,
    isError,
  } = useSelector((state: any) => state?.stripe?.stripeLogin);
  const dispatch = useDispatch();
  type ModalFormProps = {
    name: string;
    owner: string;
    url: string;
    email: string
  }
  /**
 * Defines the styles for a centered modal dialog box.
 * The modal is positioned absolutely, centered on the screen, with a width of 400 pixels,
 * a white background, no border, and rounded corners.
 */
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: "none",
    borderRadius: "10px",
    p: 4,
  };
  const { handleSubmit, control } = useForm<ModalFormProps>({
    defaultValues: {
      name: stripeData?.name,
      url: stripeData?.myShopifyDomain,
      owner: stripeData?.shopOwner,
      email: stripeData?.email,
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const fromStripe = getItem("fromStripe");
  useEffect(() => {
    if (stripeData?.additionalMandatoryShopDataRequired && fromStripe) {
      setOpenModal(true);
    }
  }, [stripeData?.additionalMandatoryShopDataRequired, fromStripe])
  const onSubmit = (data: ModalFormProps) => {
    setOpenModal(false);
    removeItem('fromStripe');
    dispatch(postStripeUpdateBusinessProfileRequest(data));
  }
  type ModalItemProps = {
    id: number,
    name: string,
    label: string,
  }
  const modalItemsForTheModal: ModalItemProps[] = [
    {
      id: 1,
      name: "name",
      label: "Business Name",
    },
    {
      id: 2,
      name: "owner",
      label: "Business Owner",
    },
    {
      id: 3,
      name: "url",
      label: "Business Site Url",
    },
    {
      id: 4,
      name: "email",
      label: "Contact Email",
    },

  ]

  return (
    <Modal
      open={openModal} onClose={() => {
        setOpenModal(false);
        removeItem('fromStripe');
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.modalContainer}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Update Profile
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            modalItemsForTheModal.map((item: ModalItemProps) => {
              return <Controller
                name={item.name as "name" | "owner" | "url" | "email"}
                key={item.id}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label={item.label}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size='small'
                  />
                )}
              />
            }

            )
          }
          <Button
            type="submit"
            variant="contained"
            className={styles.buttonStyle}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Modal>
  )
}
export default ShowingStripeModal;
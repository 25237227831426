import { ComponentType } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getItem, setItem } from "../../utils/localStorage";
import useStripeInstall from "hooks/stripeHooks/useStripeInstall";

interface Props {
  component?: ComponentType;
}

const ProtectedRoute = ({ component, ...args }: Props) => {
  const location = useLocation();
  const { resultString } = useStripeInstall()

  //for stripe login integration
  const fromStripe = new URLSearchParams(location.search).get("from");
  const accountId = new URLSearchParams(location.search).get("accountId");
  const signature = new URLSearchParams(location.search).get("signature");
  const userId = new URLSearchParams(location.search).get("userId");
  const accessToken = getItem("accessToken");
  const withAuthenticationCheck = (protectedComponent: any) => {
    if (accessToken) {
      return protectedComponent;
      //this condition checking the stripe checking
    } else if (fromStripe === "stripe" && accountId) {
      setItem("fromStripe", fromStripe);
      setItem("stripeAccountId", accountId);
      setItem("signature", signature);
      setItem("userId", userId);
      //navigate to Auth0 login screen
      return null;
    } else if (resultString.accountId && resultString.stripeUserId && resultString.userId && resultString.code) {
      return null;
    } else {
      const AuthComponent = withAuthenticationRequired(
        protectedComponent,
        args
      );
      return AuthComponent;
    }
  };
  const Component = withAuthenticationCheck(component);
  return Component ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;

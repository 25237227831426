import styles from "./style.module.scss";
import { isEmpty } from "lodash";
import Loading from "components/loading/loading";
import { useTrackingDetails } from "hooks";
import { useParams } from "react-router-dom";

const TrackingJourney = () => {
  const disputeId = useParams();
  const { formattedObj, checkHasData, isLoading } = useTrackingDetails(disputeId);
  return (
    <div className={styles.trackingJourneyWrapper}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {checkHasData ? (
            Object.entries(formattedObj).map((item, index) =>
              item[1] ? (
                <div key={index} className={styles.trackingJourneyContainer}>
                  {isEmpty(item[1]) ? (
                    ""
                  ) : (
                    <>
                      {item[0] === "Destination" ? (
                        <>
                          <p>{item[0]}</p>
                          <span>{item[1] as string}</span>

                        </>
                      ) : (
                        <>
                          <p>{item[0]}</p>
                          <span>{item[1] as string}</span>
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : null
            )
          ) : (
            <div className={styles.noTrackingJourney}>No tracking journey</div>
          )}
        </>
      )}
    </div>
  );
};

export default TrackingJourney;

import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";


export class GetListPspList {
  /**
   * Function to invoke integarted PSP
   */
  async invoke() {

    const res = await ApiService.getInstance().get(apiConstants.GET_LIST_PSP_LIST);
    
    return res;
  }

}
import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
export class ShopifyController {
    /**
     * Function to invoke to get shopify-controller
     */
    async invoke(payload: any) {

        const res = await ApiService.getInstance().get(apiConstants.GET_SHOPIFY_HMAC(payload?.shop));

        return res;
    }

    async invokeShopifyCreateCharge() {

        const res = await ApiService.getInstance().post(apiConstants.POST_SHOPIFY_CREATE_CHARGE);

        return res;
    }

    async invokeShopifyCreateSubscription() {

        const res = await ApiService.getInstance().post(apiConstants.POST_SHOPIFY_CREATE_SUBSCRIPTION);

        return res;
    }

    async invokeShopifyActivateCharge(payload: any) {

        const res = await ApiService.getInstance().post(apiConstants.POST_SHOPIFY_ACTIVATE_CHARGE(payload));

        return res;
    }
}
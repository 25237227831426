
export const configs = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
}

export const API_CONFIG = (token?: string) => {
  if (token) {
    return ({
      timeout: 10000,
      headers: {
        common: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    })
  } else {
    return (
      {
        timeout: 10000,
        headers: {
          common: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      }
    )
  }
}

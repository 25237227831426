import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { svgIcons } from '../../elements';
import { postShopifyCreateSubscriptionRequest } from '../../redux/actions';
import Loading from '../loading/loading';

import styles from './style.module.scss';

interface userDataProp {
  "id": number,
  "externalId": string,
  "appId": string,
  "domain": string,
  "name": string,
  "email": string,
  "phone": string,
  "shopType": string,
  "bigCommerceStoreHash": string,
  "bigCommerceStoreInternalHash": string,
  "accessToken": string,
  "refreshToken": string,
  "scopes": string,
  "onboarded": boolean,
  "riskAccepted": boolean,
  "chargeActive": boolean
}

const Upgrade = () => {
  return (
    <div className={styles.trial}>
      <p className={styles.days}>2 days left</p>
      <p className={styles.time}>
        You have only 2 days left for your trial period. Please upgrade account
        today!
      </p>
      <div className={styles.upgrade}>
        Upgrade plan <img src={svgIcons.Right} alt="right" />
      </div>
    </div>
  );
};

const Activate = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState<userDataProp>()
  const shopsData = useSelector((state: any) => state?.shop?.shopsData);
  const { isLoading, isSuccess, isError, data } = useSelector((state: any) => state?.shopifyController?.shopifyCreateSubscription)

  useEffect(() => {
    if (shopsData?.isSuccess) {
      setUserData(shopsData?.data)
    }
  }, [shopsData])

  useEffect(() => {
    if (isSuccess && data?.confirmationUrl) {
      window.location.replace(data?.confirmationUrl);
    } else if (isError) {
      toast.error('Oops! Subscription fail. Please try again', {
        theme: 'colored'
      });
    }
  }, [isSuccess, isError, data])

  const handleActivate = () => {
    dispatch(postShopifyCreateSubscriptionRequest())
  }

  return (
    <>
      {
        userData?.id && !userData?.chargeActive && 
        userData?.shopType === 'SHOPIFY' &&
        <div className={styles.activateTrial}>
          <ToastContainer />
          <img src={svgIcons.Warning} alt='warning' />
          <p className={styles.time}>
            Your disputes are not being handled! Activate your ChargePay account for FREE to automate them!
          </p>
          <Button className={styles.upgrade} onClick={handleActivate}>
            {isLoading ? <Loading /> : (
              <>
                Activate ChargePay <img src={svgIcons.Right} alt="right" />
              </>
            )}
          </Button>
        </div>
      }
    </>
  )
}

export const Notifications = { Upgrade, Activate };
